import { put, takeEvery } from "redux-saga/effects";
import { GET_SALES_ORDERS, UPLOAD_SALES_ORDERS_CSV_DATA } from "../../../types";
import { getAllSalesOrderAPI, uploadSalesOrderCSVDataAPI, getHeaderAPI } from "../../../../apis";
import { toast } from "react-toastify";
import {
  getAllSalesOrdersSlice,
  salesOrdersCSVUploadComplete,
  salesOrdersCSVUploadError
} from "../../../slices/transactional/salesOrders";

export function* getAllSalesOrdersSaga(action) {
  try {
    yield put(getAllSalesOrdersSlice({
      data: {},
      loader: true
    }))
    if(!sessionStorage.getItem('salesOrderStaticHeader')){
      const response = yield getHeaderAPI("salesorders");
    yield sessionStorage.setItem('salesOrderStaticHeader', JSON.stringify(response.data.responseData));
    }
    const salesOrderHeader = yield getHeaderAPI("salesorders");
    yield localStorage.setItem('salesOrderHeader', JSON.stringify(salesOrderHeader.data.responseData));
    const res = yield getAllSalesOrderAPI(action.payload);
    yield put(getAllSalesOrdersSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllSalesOrdersSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadSalesOrderCSVDataAPI(action.payload);
    yield put(salesOrdersCSVUploadComplete(res?.data.responseData));
  } catch (error) {
    yield put(salesOrdersCSVUploadError(error.response?.data?.responseData));
  }
}
export function* watchSalesOrdersSaga() {
  yield takeEvery(GET_SALES_ORDERS, getAllSalesOrdersSaga);
  yield takeEvery(UPLOAD_SALES_ORDERS_CSV_DATA, uploadCSVSaga);
}
