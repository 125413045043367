import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  personnel: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
  csvUploadResponseCode: null,
  addEditTableColumnPersonnel:null,
  personnelAddError:null,
  personnelEditError:null
};

export const PersonnelSlice = createSlice({
  name: "personnel",
  initialState: initialState,
  reducers: {
    getAllPersonnelSlice: (state, action) => {
      state.personnel = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    getEditAllPersonnelSlice: (state, action) => {
      
      state.addEditTableColumnPersonnel = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    postAddAllPersonnelSlice: (state, action) => {
      state.addEditTableColumnPersonnel = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    personnelAddError: (state, action) => {
      state.personnelAddError = action.payload;
      state.loader = false
      return state;
    },
    personnelEditError: (state, action) => {
      state.personnelEditError = action.payload;
      state.loader = false
      return state;
    },
    personnelCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    personnelCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    personnelCSVUploadError: (state, action) => {
      state.loader = false
      state.csvUploadError = action.payload;
      return state;
    },
  },
});

export const {
  getAllPersonnelSlice,
  personnelCSVUploadInitiate,
  personnelCSVUploadComplete,
  personnelEditError,
  personnelAddError,
  personnelCSVUploadError,
  getEditAllPersonnelSlice,
  postAddAllPersonnelSlice
} = PersonnelSlice.actions;

export default PersonnelSlice.reducer;
