import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_EDIT_PROMO_MASTER, 
  GET_ALL_PROMO_MASTER,
  POST_ALL_PROMO_MASTER,UPLOAD_PROMO_MASTER_CSV_DATA } from "../../../types";
import { 
  getAllPromoMasterAPI,
  uploadPromoMasterCSVDataAPI,
  getHeaderAPI ,
  postAddPromoMasterHeaderAPI,
  getEditPromoMasterHeaderAPI
} from "../../../../apis";
import { toast } from "react-toastify";

import { getAllPromoMasterSlice,
  promoMasterCSVUploadComplete,
  postAddAllPromoMasterSlice,
  getEditAllPromoMasterSlice,
  promoMasterCSVUploadError, 
  promoMasterAddError,
  promoMasterEditError} from "../../../slices/entities/promo";





export function* postAllPromoMasterSaga(action) {
  try {
    yield put(postAddAllPromoMasterSlice({
      data: {},
      loader: true
    }))
    const res = yield postAddPromoMasterHeaderAPI(action.payload);
    yield put(postAddAllPromoMasterSlice());
    yield put(postAddAllPromoMasterSlice({
      data: res?.data ?? {},
      loader: false
    }))
    
  } catch (error) {
    yield put(postAddAllPromoMasterSlice({
      data: {},
      loader: false
    }))
    yield put(promoMasterAddError(error.response?.data?.responseData?.message));

    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}
export function* getAllEditPromoMasterSaga(action) {
  try {
    yield put(getEditAllPromoMasterSlice({
      data: {},
      loader: true
    }))
    const res = yield getEditPromoMasterHeaderAPI(action.payload);
    yield put(getEditAllPromoMasterSlice());
    yield put(getEditAllPromoMasterSlice({
      data: res?.data?? {},
      loader: false
    }))
    
  } catch (error) {
    yield put(getEditAllPromoMasterSlice({
      data: {},
      loader: false
    }))
    yield put(promoMasterEditError(error.response?.data?.responseData?.message));

    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}
export function* getAllPromoMasterSaga(action) {

 
  try {
    yield put(getAllPromoMasterSlice({
      data: {},
      loader: true
    }))
    if(!sessionStorage.getItem('promoMasterStaticHeader')){
      const promoMasterStaticHeader = yield getHeaderAPI("promomaster");
    yield sessionStorage.setItem('promoMasterStaticHeader', JSON.stringify(promoMasterStaticHeader.data.responseData));
    }
    const res = yield getAllPromoMasterAPI(action.payload);
    yield put(getAllPromoMasterSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllPromoMasterSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadPromoMasterCSVDataAPI(action.payload);
    yield put(promoMasterCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        promoMasterCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchPromoMasterSaga() {
  yield takeEvery(GET_ALL_PROMO_MASTER, getAllPromoMasterSaga);
  yield takeEvery(UPLOAD_PROMO_MASTER_CSV_DATA,uploadCSVSaga);
  yield takeEvery(GET_ALL_EDIT_PROMO_MASTER, getAllEditPromoMasterSaga);
  yield takeEvery(POST_ALL_PROMO_MASTER, postAllPromoMasterSaga);

}
