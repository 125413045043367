import React, { useEffect, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import Product from "../pages/entities/product";
import ErrorPage from "../components/errorPage";
import Personnel from "../pages/entities/personnel";
import Mapping from "../pages/entities/mapping";
import Location from "../pages/entities/location";
import Currency from "../pages/entities/currency";
import Network from "../pages/entities/network";
import Feature from "../pages/transaction/feature/";
import ActualSalesHistory from "../pages/transaction/actualSalesHistory/";
import Forecast from "../pages/transaction/forecast/";
import Sync_Status from "../pages/Sync_Status";
import PoEvents from "../pages/transaction/poEvents";
import PurchaseOrder from "../pages/transaction/purchaseOrder";

import GoodsInTransit from "../pages/transaction/goodsintransit/index";

import GoodsInHand from "../pages/transaction/goodsinhand";
import GoodsInPNBS from "../pages/transaction/goodsinpnbs";
import ProductionWorkOrder from "../pages/transaction/productionWorkOrder";
import BillOfMaterials from "../pages/transaction/billOfMaterials";
import STO from "../pages/transaction/stocktransferorder";
import RMNodeMapping from "../pages/transaction/rmNodeMapping";
import Shipment from "../pages/transaction/shipment";
import InventoryEvents from "../pages/transaction/inventoryEvents";
import RMPO from "../pages/transaction/rmpo";
import RMNetwork from "../pages/entities/RMNetwork";

import STOEvents from "../pages/transaction/stoEvents";
import Budget from "../pages/entities/budget";
import RowMaterial from "../pages/entities/rawmaterial"
import MarketingEvents from "../pages/transaction/marketingevents"
import PromotionType from "../pages/transaction/promotiontype";
import SplitMaster from "../pages/entities/splitmaster";
import SalesOrders from "../pages/transaction/salesOrders"
import Promo from "../pages/transaction/promo";
import PromoMaster from "../pages/entities/Promo";
import RouteMaster from "../pages/entities/RouteMaster";


const Routing = () => {
  let navigate = useNavigate();

  useEffect(() => {
    if (isEmpty(localStorage.getItem("user"))) {
      navigate("/error");
    }
  }, [localStorage.getItem("user")]);

  return (
    <>
      <Routes>
        <Route path="/product" element={<Product />} />
        <Route path="/personnel" element={<Personnel />} />
        <Route path="/mapping" element={<Mapping />} />
        <Route path="/network" element={<Network />} />
        <Route path="/location" element={<Location />} />
        <Route path="/currency" element={<Currency />} />
        <Route path="/budget" element={<Budget />} />
        <Route path="/splitmaster" element={<SplitMaster />} />
        <Route path="/route-master" element={<RouteMaster />} />

        

        <Route path="/actual-sales-history" element={<ActualSalesHistory />} />
        <Route path="/feature" element={<Feature />} />
        <Route path="/forecast-summary" element={<Forecast />} />
        <Route path="/goods-in-transit" element={<GoodsInTransit />} />
        <Route path="/goods-in-hand" element={<GoodsInHand />} />
        <Route path="/goods-in-pbns" element={<GoodsInPNBS />} />
        <Route
          path="/production-work-order"
          element={<ProductionWorkOrder />}
        />

        <Route path="/dtrm-events" element={<PoEvents />} />
        <Route path="/purchase-order" element={<PurchaseOrder />} />
        <Route path="/bill-of-materials" element={<BillOfMaterials />} />
        <Route path="/rm-node-mapping" element={<RMNodeMapping />}  />
        <Route path="/shipment" element={<Shipment />}  />]
        <Route path="/promo-transaction" element={<Promo />}  />
        <Route path="/promo-master" element={<PromoMaster />}  />


        <Route path="/inventory-events" element={<InventoryEvents/>}  />
        <Route path="/rmpo" element={<RMPO/>}  />
        <Route path="/sto-events" element={<STOEvents />} />
        <Route path="/sales-orders" element={<SalesOrders />} />



        <Route path="/transactionlog" element={<Sync_Status />} />

        <Route path="*" element={<ErrorPage />} />
        <Route path="/stock-transfer-order" element={<STO />} />
        <Route path="/raw-material" element={<RowMaterial />}/>
        <Route path="/marketing-events" element={<MarketingEvents />} />
        <Route path="/promotion-type" element={<PromotionType />} />
        <Route path="/rm-network" element={<RMNetwork />} />
        

      </Routes>
    </>
  );
};

export default Routing;
