import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_FORECAST, UPLOAD_FORECAST_CSV_DATA, GET_ALL_SNOPS } from "../../../types";
import { getAllForecastSummaryAPI, uploadForecastSummaryCSVDataAPI, getAllSnopListApi, getHeaderAPI } from "../../../../apis";
import { toast } from "react-toastify";
import {
  getAllForecastSlice,
  ForecastCSVUploadComplete,
  ForecastCSVUploadError,
  getSnopListingSlice
} from "../../../slices/transactional/forecast";

export function* getAllForecastSaga(action) {
  try {
    yield put(getAllForecastSlice({
      data: {},
      loader: true
    }));
    if(!sessionStorage.getItem('forecastStaticHeader')){
      const response = yield getHeaderAPI("forecast");
    yield sessionStorage.setItem('forecastStaticHeader', JSON.stringify(response.data.responseData));
    }
    const res = yield getAllForecastSummaryAPI(action.payload);
    yield put(getAllForecastSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }));
  } catch (error) {
    yield put(getAllForecastSlice({
      data: {},
      loader: false
    }));
    toast.error(error?.response?.data?.detail ?? error?.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadForecastSummaryCSVDataAPI(action.payload);
    yield put(ForecastCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    toast.error(error?.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
    yield put(ForecastCSVUploadError(error.response?.data ?? error.response?.data));
  }
}

export function* getAllSnopListingSaga() {
  try {
    yield put(getSnopListingSlice({
      data: [],
      loader: true
    }))
    const res = yield getAllSnopListApi()
    yield put(getSnopListingSlice({
      data: res?.data?.responseData?.data ?? [],
      loader: false
    }))
  } catch (error) {
    yield put(getSnopListingSlice({
      data: [],
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.responseCode,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* watchForecastSaga() {
  yield takeEvery(GET_ALL_FORECAST, getAllForecastSaga);
  yield takeEvery(UPLOAD_FORECAST_CSV_DATA, uploadCSVSaga);
  yield takeEvery(GET_ALL_SNOPS, getAllSnopListingSaga)
}
