import { combineReducers } from "redux";
import ProductReducer from "../slices/entities/product";
import PersonnelReducer from "../slices/entities/personnel";
import MappingReducer from "../slices/entities/mapping"
import NetworkReducer from '../slices/entities/network'
import LocationReducer from "../slices/entities/location"
import CurrencyReducer from "../slices/entities/currency";
import FeatureReducer from '../slices/transactional/feature'
import SalesHistoryReducer from '../slices/transactional/actualSalesHistory'
import ForecastSlice from '../slices/transactional/forecast'
import SyncstatusReducer from "../slices/syncstatus";
import GoodsInHandReducer from "../slices/transactional/goodsinhand";
import GoodsInPNBSReducer from "../slices/transactional/goodsinpnbs";
import GoodsInTransitReducer from "../slices/transactional/goodsintransit";
import ProductionWorkOrderReducer from '../slices/transactional/productionWorkOrder'
import BillOfMaterialsReducer from "../slices/transactional/billOfMaterials";
import poEventsReducer from "../slices/transactional/poEvents";
import PurchaseOrderReducer from "../slices/transactional/purchaseOrder";
import StoReducer from "../slices/transactional/stocktransferorder";
import RMNodeMappingReducer from "../slices/transactional/rmNodeMapping"
import ShipmentReducer from "../slices/transactional/shipment"
import InventoryEventsReducer from "../slices/transactional/inventoryEvents"
import RMPOReducer from "../slices/transactional/rmpo"
import STOEventsReducer from "../slices/transactional/stoEvents"
import  BudgetReducer  from "../slices/entities/budget";
import RowMaterialReducer from "../slices/entities/rawmaterial"
import MarketingEventsReducer from "../slices/transactional/marketingevents"
import PromotionTypeReducer from "../slices/transactional/promotiontype"
import SplitMasterReducer from "../slices/entities/splitmaster"
import SalesOrdersReducer from "../slices/transactional/salesOrders"
import PromoReducer from "../slices/transactional/Promo";
import PromoMasterReducer from "../slices/entities/promo";
import RouteMasterReducer from "../slices/entities/route-master";
import SNPPersonnelSliceReducer from "../slices/transactional/snp-personnel";
import RMNetworkReducer from "../slices/entities/RMNetwork"



import commonReducer from "./common"

export const rootReducer = combineReducers({
  ProductReducer,
  PersonnelReducer,
  MappingReducer,
  NetworkReducer,
  RMNetworkReducer,
  LocationReducer,
  FeatureReducer,
  SalesHistoryReducer,
  ForecastSlice,
  CurrencyReducer,
  SyncstatusReducer ,
  GoodsInTransitReducer,
  GoodsInHandReducer,
  ProductionWorkOrderReducer  ,
  BillOfMaterialsReducer,
  GoodsInPNBSReducer,
  poEventsReducer,
  PurchaseOrderReducer,
  StoReducer,
  RMNodeMappingReducer,
  ShipmentReducer,
  InventoryEventsReducer,
  RMPOReducer,
  STOEventsReducer,
  BudgetReducer,
  RowMaterialReducer,
  MarketingEventsReducer,
  PromotionTypeReducer,
  SplitMasterReducer,
  SalesOrdersReducer,
  commonReducer,
  PromoReducer,
  PromoMasterReducer,
  RouteMasterReducer,
  SNPPersonnelSliceReducer
  
  
});
