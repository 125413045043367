import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_PURCHASE_ORDER, UPLOAD_PURCHASE_ORDER_CSV_DATA } from "../../../types";
import { getAllpurchaseorderEventsAPI, getHeaderAPI, uploadpurchaseorderEventsCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";
import { getAllpurchaseorderEventsSlice,
    purchaseorderEventsCSVUploadComplete,
    purchaseorderEventsCSVUploadError } from "../../../slices/transactional/purchaseOrder";

export function* getAllpurchaseorderEventsSaga(action) {
  try {
    yield put(getAllpurchaseorderEventsSlice({
      data: {},
      loader: true
    }))
    if(!sessionStorage.getItem('purchaseOrderStaticHeader')){
      const response = yield getHeaderAPI("purchaseorder");
    yield sessionStorage.setItem('purchaseOrderStaticHeader', JSON.stringify(response.data.responseData));
    }
    const res = yield getAllpurchaseorderEventsAPI(action.payload);
    yield put(getAllpurchaseorderEventsSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllpurchaseorderEventsSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadpurchaseorderEventsCSVDataAPI(action.payload);
    yield put(purchaseorderEventsCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        purchaseorderEventsCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchpurchaseorderEventsSaga() {
  yield takeEvery(GET_ALL_PURCHASE_ORDER, getAllpurchaseorderEventsSaga);
  yield takeEvery(UPLOAD_PURCHASE_ORDER_CSV_DATA,uploadCSVSaga);
}
