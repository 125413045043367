import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_STOCK_TRANSFER_ORDER, UPLOAD_STOCK_TRANSFER_ORDER_CSV_DATA } from "../../../types";
import { getAllStockTransferOrderAPI, getHeaderAPI, uploadStockTransferOrderCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";
import { getAllstoSlice,stoCSVUploadComplete,stoCSVUploadError } from "../../../slices/transactional/stocktransferorder";

export function* getAllStockTransferOrderSaga(action) {
  try {
    yield put(getAllstoSlice({
      data: {},
      loader: true
    }))
    if(!sessionStorage.getItem('stockTransferOrderStaticHeader')){
      const response = yield getHeaderAPI("stocktransferorder");
    yield sessionStorage.setItem('stockTransferOrderStaticHeader', JSON.stringify(response.data.responseData));
    }
    const res = yield getAllStockTransferOrderAPI(action.payload);
    yield put(getAllstoSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllstoSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadStockTransferOrderCSVDataAPI(action.payload);
    yield put(stoCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        stoCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchStockTransferOrderSaga() {
  yield takeEvery(GET_ALL_STOCK_TRANSFER_ORDER, getAllStockTransferOrderSaga);
  yield takeEvery(UPLOAD_STOCK_TRANSFER_ORDER_CSV_DATA,uploadCSVSaga);
}
