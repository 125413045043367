import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  routeMasterList: {},
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

export const RouteMasterSlice = createSlice({
  name: "routeMaster",
  initialState: initialState,
  reducers: {
    getAllRouteMasterSlice: (state, action) => {
      state.routeMasterList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    routeMasterCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    routeMasterCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    routeMasterCSVUploadError: (state, action) => {
      state.loader = false
      state.csvUploadError = action.payload;
      return state;
    }
  },
});

export const {
  getAllRouteMasterSlice,
  routeMasterCSVUploadComplete,
  routeMasterCSVUploadError,
  routeMasterCSVUploadInitiate
} = RouteMasterSlice.actions;

export default RouteMasterSlice.reducer;
