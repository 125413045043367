import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../../components/tableGrid/pagination/";
import {useDispatch} from "react-redux"
import { get } from "lodash";


import { setTableWidth } from "../../../../../utils/setTableWidth";
import {entitiesName} from "../../../../../utils/Constants"
import { SET_SELECTED_ROW_DATA } from "../../../../../store/types";


const PersonnelGrid = (props) => {
  const dispatch=useDispatch()

  const { t } = useTranslation();
  const locationConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .locationHierarchy
    : {};
  const productConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .productHierarchy
    : {};
  const demandPlannerConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .demandPlannerHierarchy
    : {};
  const salesmanConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .salesmanHierarchy
    : {};

    const marketingHierarchyConfiguration = JSON.parse(
      localStorage.getItem("configuration")
    )
      ? JSON.parse(localStorage.getItem("configuration")).entities
          .marketingHierarchy
      : {};
  const forecastConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).forecast
    : {};

    const user = JSON.parse(localStorage.getItem("user"));

  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const [selectAll, setSelectAll] = useState(false);
  const {
    personnelData,
    refresh,
    pageSize,
    totalElement,
    setPageIndex,
    pageIndex,
    setSortValue,
    isLoading,
    componentName,
    perosnnelGrid

  } = props;

  const initialDataState = {
    take: pageSize,
    skip: 0,
    total: totalElement,
  };
  const [dataState, setDataState] = useState(initialDataState);
  const [sortKey, setSortKey] = useState("");
  const [sortDir, setSortDir] = useState(false);
  const grid = React.useRef(null);
  if(entitiesName.includes(componentName)){
    localStorage.setItem(`${componentName}SortValue`,sortKey)
    for(let i=0;i<entitiesName.length;i++){
      if(entitiesName[i]!=componentName){
        localStorage.removeItem(`${entitiesName[i]}SortValue`);
      }
    }
  }
  useEffect(() => {
    setDataState({ ...dataState, take: pageSize, total: totalElement });
  }, [pageSize, totalElement]);
  useEffect(() => {
    setSortKey("");
  }, [refresh]);

  const noOfPages = Math.ceil(totalElement / pageSize);

  const start = (pageIndex - 1) * pageSize;
  const end = start + pageSize;

  const endCount = end > totalElement ? `- ${totalElement}` : `- ${end}`;
  const removeColumn = [
    "personnel_id",
    "created_at",
    "created_by",
    "modified_at",
    "modified_by",
    "channel",
    "sku"
  ];

  const personnelDataRows =
    personnelData &&
    personnelData.map((item) => {
      const items = { ...item };
      items.channel_name =
        Object.keys(item?.channel ?? {}).length > 0
          ? item?.channel?.channel_name
          : "";
      //     ? item?.channel?.channel_description
      items.node_code = item?.node?.node_code ?? ''
      items.node = item?.node?.node ?? ''
      items.sku_code = item?.sku?.sku_code ?? ''
      items.sku_name = item?.sku?.sku_name ?? ''
      return items;
    });

  React.useEffect(() => {
    if (perosnnelGrid.length > 0) {
      grid.current = document.querySelector(".k-grid");
    }
  }, [perosnnelGrid]);

  const _renderSortIcons = (obj) => {
    return (
      <>
        {sortKey === obj ? (
          sortDir || obj !== sortKey ? (
            <i className="fa fa-arrow-down" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-arrow-up" aria-hidden="true"></i>
          )
        ) : (
          <>
            {" "}
            <i className="fa fa-sort" aria-hidden="true"></i>
          </>
        )}{" "}
      </>
    );
  };

  const handleHeaderCheckboxChange = () => {
    const updatedDataRows = personnelDataRows.map((item) => ({
      ...item,
      selected: !selectAll,
    }));

    setSelectAll(!selectAll);
    if (selectAll === false) {
      personnelDataRows.map((item) => {
        item.selected = false;
      });
    }
    setDataState({
      ...dataState,
      data: process(updatedDataRows, dataState),
    });

    const newSelectedRowIds = updatedDataRows
      .filter((item) => item.selected)
      .map((item) => ({
        id: item.item.id,
        tenantId: tenantId,
        buId: buId,
      }));

    // setSelectedRowIds(newSelectedRowIds);
    dispatch({ type: SET_SELECTED_ROW_DATA, payload: newSelectedRowIds });
  };
  const handleRowCheckboxChange = (dataItem) => {
    console.log("handleRowCheckboxChange1,locationDataRows",dataItem,personnelDataRows)
    const updatedDataRows = [...personnelDataRows];
    console.log("locarion")
    const clickedIndex = updatedDataRows.findIndex((item) => item.personnel_id === dataItem.personnel_id);

    if (clickedIndex !== -1) {
      updatedDataRows[clickedIndex].selected =!dataItem.selected
        // !updatedDataRows[clickedIndex].selected;
    }

    setDataState({
      ...dataState,
      data: process(updatedDataRows, dataState),
    });

    const newSelectedRowIds = updatedDataRows
      .filter((item) => item.selected)
      .map((item) => ({
        id: dataItem.personnel_id,
        tenantId: tenantId,
        buId: buId,
      }));
      console.log("handleRowCheckboxChange1",newSelectedRowIds)

    dispatch({ type: SET_SELECTED_ROW_DATA, payload: newSelectedRowIds });
  };


  return (
    <div className="tablegrid">
      {!isEmpty(personnelDataRows) ? (
        <>
          <Grid
            pageable={false}
            sortable={false}
            resizable={true}
            data={
              !isEmpty(personnelDataRows) ? process(personnelDataRows, dataState) : null
            }
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.dataState);
            }}
            className={"table-style"}
          >

{componentName === "personal" && (
              <Column
                title="New Column"
                field="newColumn"
                width="45px"
                key="new-column"
                headerCell={({}) => {
                  return (
                    <div>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleHeaderCheckboxChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  );
                }}
                cell={({ dataItem }) => {
                  return (
                    <td>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={dataItem.selected}
                          onChange={() => handleRowCheckboxChange(dataItem)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  );
                }}
              />
            )}
            {perosnnelGrid && perosnnelGrid?.map((item, idx) => {
              return (
                
                <Column
                  title={
                    item.feName                  }
                  field={item.beName}
                  key={idx}
                  width={setTableWidth(120, grid, perosnnelGrid)}
                  headerClassName={`column-sorting-icon ${item.beName == localStorage.getItem(`${componentName}SortValue`)?"sort_column":""}`}

                  headerCell={(props) => {
                    return (
                      <span
                        onClick={() => {
                          setSortDir(!sortDir || item.beName !== sortKey);
                          setSortKey(item.beName);
                          setSortValue(
                            `${item.beName} ${
                              !sortDir || item.beName !== sortKey ? "ASC" : "DESC"
                            }`
                          );
                        }}
                        className="th-flex"
                      >
                        {props.title} {_renderSortIcons(item.beName)}
                      </span>
                    );
                  }}
                />
              );
            })}
          </Grid>
          <Pagination
            endCount={endCount}
            page={parseInt(pageIndex)}
            onChange={(e, value) => setPageIndex(value)}
            count={noOfPages}
            showFirstButton={true}
            showLastButton={true}
            totalElement={totalElement}
            start={start}
          />
        </>
      ) : (
        <>
          <div className="text-center">No records found</div>
        </>
      )}
    </div>
  );
};

export default PersonnelGrid;
