import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  promoMasterList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
  addEditTableColumnPromoMaster:null,
  promoMasterAddError:null,
  promoMasterEditError:null

};

const PromoMasterSlice = createSlice({
  name: "promomaster",
  initialState: initialState,
  reducers: {
    getAllPromoMasterSlice: (state, action) => {
      state.promoMasterList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    getEditAllPromoMasterSlice: (state, action) => {
      state.addEditTableColumnPromoMaster = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    promoMasterAddError: (state, action) => {
      state.promoMasterAddError = action.payload;
      state.loader = false
      return state;
    },
    promoMasterEditError: (state, action) => {
      state.promoMasterEditError = action.payload;
      state.loader = false
      return state;
    },
    postAddAllPromoMasterSlice: (state, action) => {
      state.addEditTableColumnPromoMaster = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    promoMasterCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    promoMasterCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    promoMasterCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
 getAllPromoMasterSlice,
 promoMasterCSVUploadComplete,
 promoMasterCSVUploadError,
 promoMasterCSVUploadInitiate,
 promoMasterEditError,
 promoMasterAddError,
 getEditAllPromoMasterSlice,
 postAddAllPromoMasterSlice
 
} = PromoMasterSlice.actions;

export default PromoMasterSlice.reducer;
