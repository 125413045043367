import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_ROUTE_MASTER, UPLOAD_ROUTE_MASTER_CSV_DATA } from "../../../types";
import { getAllRouteMasterAPI, getHeaderAPI, uploadRouteMasterCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";
import {
  
    getAllRouteMasterSlice,
    routeMasterCSVUploadComplete,
    routeMasterCSVUploadError,
} from "../../../slices/entities/route-master";
function* getAllRouteMasterSaga(action) {
  try {
    yield put(getAllRouteMasterSlice({
      data: {},
      loader: true
    }));
    if(!sessionStorage.getItem('routeStaticHeader')){
      const response = yield getHeaderAPI("routemaster");
    yield sessionStorage.setItem('routeStaticHeader', JSON.stringify(response.data.responseData));
    }
    const res = yield getAllRouteMasterAPI(action.payload);
    yield put(getAllRouteMasterSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }));
  } catch (error) {
    yield put(getAllRouteMasterSlice({
      data: {},
      loader: false
    }));
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {

  try {
    const res = yield uploadRouteMasterCSVDataAPI(action.payload);
    
    yield put(routeMasterCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(routeMasterCSVUploadError(error.response?.data?.responseData));
  }
}
export function* watchRouteMasteraga() {
  yield takeEvery(GET_ALL_ROUTE_MASTER, getAllRouteMasterSaga);
  yield takeEvery(UPLOAD_ROUTE_MASTER_CSV_DATA, uploadCSVSaga);
}
