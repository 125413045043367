import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_RMNETWORK, UPLOAD_RMNETWORK_CSV_DATA } from "../../../types";
import { getAllRMNetworksAPI, uploadRMNetworkCSVDataAPI, getHeaderAPI } from "../../../../apis";
import { toast } from "react-toastify";
import {
  getAllRMNetworkSlice,
  RMNetworkCSVUploadComplete,
  RMNetworkCSVUploadError,
} from "../../../slices/entities/RMNetwork";

function* getAllRMNetworkSaga(action) {
  try {
    yield put(getAllRMNetworkSlice({
      data: {},
      loader: true
    }));
    if(!sessionStorage.getItem('RMNetworkStaticHeader')){
      const response = yield getHeaderAPI("rmnetwork");
    yield sessionStorage.setItem('RMNetworkStaticHeader', JSON.stringify(response.data.responseData));
    }
    const res = yield getAllRMNetworksAPI(action.payload);
    yield put(getAllRMNetworkSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }));
  } catch (error) {
    yield put(getAllRMNetworkSlice({
      data: {},
      loader: false
    }));
    toast.error((error && error?.response?.data?.httpStatus) ?? error.message,
    {
      autoClose: false,
      closeOnClick: false,
      draggable: false,
    });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadRMNetworkCSVDataAPI(action.payload);
    yield put(RMNetworkCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(RMNetworkCSVUploadError(error.response?.data?.responseData));
  }
}
export function* watchRMNetworkSaga() {
  yield takeEvery(GET_ALL_RMNETWORK, getAllRMNetworkSaga);
  yield takeEvery(UPLOAD_RMNETWORK_CSV_DATA, uploadCSVSaga);
}
