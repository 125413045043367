import React, { useState, useEffect } from "react";
import "../../../../style/sass/main.scss";
import TableGrid from "../../../../components/tableGrid";
import TableToolbar from "../../../../components/tableGrid/toolbar";
import "@progress/kendo-theme-default/dist/all.css";
import { get } from "lodash";
import { filesize } from "filesize";

import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_BUDGET,
  UPLOAD_BUDGET_CSV_DATA,
} from "../../../../store/types";

import { useTranslation } from "react-i18next";
import { SHOW_ROWS } from "../../../../constant/constant";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Breadcrumb from "../../../../components/breadcrumb";
import UploadDialog from "../../../../components/uploadDialog";
import { downloadCsvApi } from "../../../../apis";
import { csvFileReader } from "../../../../utils/csvFileReader";
import { entitiesName, permission } from "../../../../utils/Constants";
import { useNumericFormat } from "react-number-format";
import moment from "moment";
import ProcessingLoader from "../../../../components/processingLoader/";
import { use } from "i18next";

const List = (props) => {
  const { t } = useTranslation();
  const EDM_API_ENDPOINT = process.env.REACT_APP_EDM_API_ENDPOINT;
  const SCAI_API_BASEURL = process.env.REACT_APP_SCAI_API_BASEURL_BUDGET;
  const localeConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).locale
    : {};
  const locationConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .locationHierarchy
    : {};
  const productConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .productHierarchy
    : {};
  const user = JSON.parse(localStorage.getItem("user"));
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);
  const addEditPermission = permissions.includes(
    permission.salesHistoryAddEdit
  );

  const dispatch = useDispatch();
  const [rowLength, setRowLength] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [errorCSV, setErrorCSV] = useState("");
  const [csvDownloadTableDataRows, setCSVDownloadTableDataRows] = useState("");
  const [budgetStaticHeader, setBudgetStaticHeader] = useState(null);

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };
  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const error = await csvFileReader(file);
      if (!error) {
        setMyFiles([file]);
        setErrorCSV("");
      } else {
        setErrorCSV(error);
        setMyFiles([]);
      }
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const files = myFiles.map((file) => (
    <li key={file.path} className="csv-upload-success-li">
      {file.path} - {file.size} bytes
    </li>
  ));
  useEffect(() => {
    if (visible === false) {
      setMyFiles([]);
      setErrorCSV("");
    }
  }, [visible]);

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `${t("Show")} ${item} ${t("Rows")}`,
    value: item,
  }));

  const body = {
    pageIndex: pageIndex,
    pageSize: rowLength,
    sortFieldStringWithASCOrDESC: sortValue,
    searchValue: searchField,
    tenantId: tenantId,
  };

  useEffect(() => {
    if (!refresh) {
      if (csvDownloadTableDataRows === "") {
        dispatch({ type: GET_ALL_BUDGET, payload: body });
      } else {
        body.advanceSearchValue = csvDownloadTableDataRows;
        body.buttonStatus = true;
        dispatch({ type: GET_ALL_BUDGET, payload: { ...body } });
      }
    }
  }, [dispatch, pageIndex, sortValue, rowLength]);

  const handleSearchSubmit = () => {
    setPageIndex(1);
    dispatch({
      type: GET_ALL_BUDGET,
      payload: { ...body, pageIndex: 1 },
    });
  };
  const handleAdvanceSearch = (rows) => {
    if (
      rows.length === 1 &&
      rows[0].columnName === "Select Value" &&
      rows[0].operator === "Select Type"
    ) {
    } else {
      let result = "";
      rows.forEach((item, index) => {
        const keyIndex = index + 1;
        result += `value${keyIndex}=${
          budgetStaticHeader.filter(
            (data) => data.feName === item.columnName
          )[0].dbName
        },${item.operator.toLowerCase().replace(/\s+/g, "_")},${item.value}`;
        if (index !== rows.length - 1) {
          result += "&";
        }
      });
      setCSVDownloadTableDataRows(result);
      body.advanceSearchValue = result;
      body.buttonStatus = true;
      setPageIndex(1);
      dispatch({ type: GET_ALL_BUDGET, payload: { ...body, pageIndex: 1 } });
    }
  };

  const clearAdvanceSearch = () => {
    body.buttonStatus = false;
    setPageIndex(1);
    dispatch({ type: GET_ALL_BUDGET, payload: { ...body, pageIndex: 1 } });
  };
  const refreshHandler = () => {
    setRefresh(true);
    setPageIndex(1);
    setSearchField("");
    setSortValue("");
    setRowLength(10);
    const refreshBody = {
      ...body,
      searchValue: "",
      pageIndex: 1,
      pageSize: 10,
      sortFieldStringWithASCOrDESC: "",
    };
    setTimeout(() => {
      dispatch({ type: GET_ALL_BUDGET, payload: refreshBody });
      setRefresh(false);
    }, 300);
  };

  const {
    budgetList,
    budgetAddError,
    csvUploadResponseData,
    csvUploadError,
    csvUpload,
    loader,
  } = useSelector((state) => state.BudgetReducer);
  const budgetConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration"))?.entities?.budget
    : {};
  // const [gridColumnData,setGr]
  useEffect(() => {
    const budgetStaticHeaderData = sessionStorage.getItem("budgetStaticHeader");
    if (budgetStaticHeaderData && budgetStaticHeaderData !== "undefined") {
      const parsedBudgetStaticHeader = JSON.parse(budgetStaticHeaderData);
      console.log("parsedBudgetStaticHeader", parsedBudgetStaticHeader);
      setBudgetStaticHeader(parsedBudgetStaticHeader);
    }
  }, [budgetList]);


  console.log("mappingStaticHeaderrrrr",budgetStaticHeader)
  const budgetGridColumn=budgetStaticHeader && budgetStaticHeader.length && budgetStaticHeader.filter(col=>col.scope==="D" || col.scope==="DU" || col.scope==="DG" || col.scope==="DUG")
  console.log("mappingGridColumnnnn",budgetGridColumn)
  const budgetAddEdit=budgetStaticHeader && budgetStaticHeader.length && budgetStaticHeader.filter(col=>col.scope==="U" || col.scope==="DU" || col.scope==="UG" || col.scope==="DUG")



  const locationCustomHeaderConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration"))?.entities
        ?.locationCustomHeader
    : {};
  const productCustomHeaderConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration"))?.entities
        ?.productCustomHeader
    : {};

  const locationLevelConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration"))?.entities?.budget
        ?.locationLevel
    : {};
  const productLevelConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration"))?.entities?.budget
        ?.productLevel
    : {};

  let BudgetEntities = get(budgetList, "entities", []);

  BudgetEntities =
    BudgetEntities &&
    BudgetEntities.map((item) => {
      const items = { ...item };

      if (
        typeof locationLevelConfiguration === "number" &&
        Number(locationLevelConfiguration) > 0
      ) {
      } else if (
        typeof locationLevelConfiguration === "number" &&
        Number(locationLevelConfiguration) === 0
      ) {
        items.node_code = item?.node?.node_code;
        delete items[`node_hierarchy`];
      }
      if (
        typeof productLevelConfiguration == "number" &&
        Number(productLevelConfiguration) > 0
      ) {
      } else if (
        typeof productLevelConfiguration == "number" &&
        Number(productLevelConfiguration) === 0
      ) {
        items.sku_code = item?.sku?.sku_code;
        delete items[`sku_hierarchy`];
      }

      items.node = item?.node?.node;
      if (budgetConfiguration && budgetConfiguration?.isChannelRequired) {
        items.channel_name = item?.channel?.channel_name;
      }
      if (
        Object.keys(items)?.includes("node_custom_columns") &&
        budgetConfiguration &&
        budgetConfiguration?.locationCustomHeader?.length
      ) {
        for (
          let index = 0;
          index < budgetConfiguration?.locationCustomHeader.length;
          index++
        ) {
          items[
            locationCustomHeaderConfiguration[
              budgetConfiguration?.locationCustomHeader[index]
            ]
          ] = item["node_custom_columns"][`header${index + 1}`];
        }
        delete items[`node_custom_columns`];
      }

      if (
        Object.keys(items)?.includes("product_custom_columns") &&
        budgetConfiguration &&
        budgetConfiguration?.productCustomHeader?.length
      ) {
        for (
          let index = 0;
          index < budgetConfiguration?.productCustomHeader?.length;
          index++
        ) {
          items[
            productCustomHeaderConfiguration[
              budgetConfiguration?.productCustomHeader[index]
            ]
          ] = item["product_custom_columns"][`header${index + 1}`];
        }
        delete items[`product_custom_columns`];
      }
      return items;
    });
  const totalElement = get(budgetList, "totalElements", "");
  const pageSize = get(budgetList, "pageSize", "");
  const { format } = useNumericFormat({
    decimalSeparator: localeConfiguration["decimalSeparator"],
    thousandsGroupStyle: localeConfiguration["thousandsGroupStyle"],
    thousandSeparator: localeConfiguration["thousandSeparator"],
    decimalScale: 2,
  });

  const BudgetDetails = BudgetEntities;

 

  const customColumnHeaders = () => {
    if (BudgetDetails && BudgetDetails.length > 0) {
      return [
        {
          replaceWith: "sku_code",
          newLabel: productConfiguration
            ? productConfiguration["base"] + " " + t("labelCode")
            : t("sku_code"),
        },
        {
          replaceWith: "sku_name",
          newLabel: productConfiguration
            ? productConfiguration["base"]
            : t("sku_name"),
        },
        {
          replaceWith: "node_code",
          newLabel: locationConfiguration
            ? locationConfiguration["base"] + " " + t("labelCode")
            : t("node_code"),
        },
        {
          replaceWith: "node",
          newLabel: locationConfiguration
            ? locationConfiguration["base"]
            : t("sku_code"),
        },
      ];
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (csvUploadError) {
      setVisible(false);
      toast.error(
        t(
          `${
            csvUploadError?.responseMessage ??
            csvUploadError.message ??
            csvUploadError
          }`
        ),
        {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
        }
      );
    }
  }, [csvUploadError]);
  useEffect(() => {
    if (csvUpload) {
      setVisible(false);
      if (csvUploadResponseData.responseCode === "FAILED_HEADER_VALIDATION") {
        toast.error(`${csvUploadResponseData.responseMessage}`, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
        });
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_ROW_FAILURE"
      ) {
        toast.warning(
          `${t(`${csvUploadResponseData.responseCode + "\n"}`)}`,

          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
        toast.warning(
          <a href={`${csvUploadResponseData.errorFileName}`} target="_blank">
            {" "}
            Download Error File{" "}
          </a>,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_OUT_ROW_FAILURE"
      ) {
        toast.success("Data Uploaded Successfully !",
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else {
        toast.success(t(`${csvUploadResponseData.responseCode}`), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
        });
      }
      refreshHandler();
    }
  }, [csvUpload]);
  const toggleDialog = () => {
    dispatch({
      type: "budget/budgetCSVUploadInitiate",
      payload: { csvUpload: false, loader: false },
    });
    setVisible(!visible);
  };
  const handleCSVUpload = () => {
    const fileSizeInMB = myFiles[0].size / (1024 * 1024);
    if (fileSizeInMB > 40) {
      return toast.warning("File size should be less than or equal to 40 MB", {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    }
    if (myFiles[0].type != "text/csv") {
      setVisible(!visible);

      return toast.error("Only CSV file is supported", {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    }
    const uploadBody = {
      csvFile: myFiles[0],
      tenantId: tenantId,
    };
    dispatch({
      type: UPLOAD_BUDGET_CSV_DATA,
      payload: uploadBody,
    });
    dispatch({
      type: "budget/budgetCSVUploadInitiate",
      payload: { csvUpload: false, loader: true },
    });
  };


  return (
    <>
      {loader && <ProcessingLoader />}
      <div>
        <section className="client-listing">
          <h3 className="section-heading">{t("Budget")}</h3>

          <Breadcrumb list={[t("Entities Data"), t("Budget")]} />
          <div className="client-listing-content">
            <div className="client-listing-toolbar">
              <TableToolbar
                showMoreOptions={showMoreOptions}
                setRowLength={setRowLength}
                setPageIndex={setPageIndex}
                searchField={searchField}
                handleChange={handleChange}
                toggleDialog={toggleDialog}
                handleSearchSubmit={handleSearchSubmit}
                refreshHandler={refreshHandler}
                setSearchField={setSearchField}
                downloadUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/csv?tenant_id=${tenantId}&sortFieldStringWithASCOrDESC=${sortValue}&bu_id=${buId}&file_type=csv&searchValue=${searchField}&${csvDownloadTableDataRows}`}
                dataList={BudgetDetails}
                downloadCsvApi={downloadCsvApi}
                downloadName={"budget.csv"}
                componentNameAddEdit={"Budget"}
                rowLength={rowLength}
                csvUploadPermission={addEditPermission}
                showAdvanceSearch={"budget"}
                handleAdvanceSearch={handleAdvanceSearch}
                clearAdvanceSearch={clearAdvanceSearch}
                advanceSearchTableColumn={budgetStaticHeader}
                storedFeatureHeader={budgetAddEdit}
                dataRows={BudgetDetails}
              />
            </div>
            <div className="client-listing-grid">
              {" "}
              {
                BudgetDetails && BudgetDetails.length &&
                budgetGridColumn && budgetGridColumn.length ?
                 <TableGrid
                 dataRows={BudgetDetails && BudgetDetails.length && budgetGridColumn && budgetGridColumn.length? BudgetDetails:[]}
                 dataColumn={
                   // updateflag
                   //   ? (Array.isArray(dataColumn) ? dataColumn.filter((element) => !newallCheckboxId.includes(element)) : [])
                   //   : (Array.isArray(dataColumn) ? dataColumn : [])
                   BudgetDetails && BudgetDetails.length && budgetGridColumn && budgetGridColumn.length? budgetGridColumn:[]
                 }
                 refresh={refresh}
                 pageSize={pageSize}
                 totalElement={totalElement}
                 pageIndex={pageIndex}
                 setPageIndex={setPageIndex}
                 setSortValue={setSortValue}
                 width={"auto"}
                 isLoading={loader}
                 replaceColumnLevels={customColumnHeaders()}
                 componentName={"budget"}
               />:<div className="text-center">No records found</div>

              }
         
            </div>
          </div>
        </section>

        {visible && (
          <UploadDialog
            templateUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/template?tenant_id=${tenantId}&bu_id=${buId}`}
            toggleDialog={toggleDialog}
            handleCSVUpload={handleCSVUpload}
            myFiles={myFiles}
            listing={files}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            downloadCsvApi={downloadCsvApi}
            errorCSV={errorCSV}
            templateName={"budget-template.csv"}
            dateFormat={localeConfiguration["dateFormat"]}
          />
        )}
      </div>
    </>
  );
};

export default List;
