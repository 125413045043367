import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import {useDispatch} from "react-redux"
import { SET_SELECTED_ROW_DATA } from "../../../../../store/types";
import { get } from "lodash";
import Pagination from "../../../../../components/tableGrid/pagination/";
import {entitiesName} from "../../../../../utils/Constants"
const LocationGrid = (props) => {
  const { t } = useTranslation();
  const [hoveredData, setHoveredData] = useState(null);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const dispatch=useDispatch()

  const formatLnameValue = (lname) => {
    try {
      if (lname === null) {
        return "NAN";
      }
      const parsedJson = JSON.parse(lname);
      return "{...}";
    } catch (error) {
      return lname;
    }
  };

  const handleCellMouseEnter = (event, lname) => {
    let styleCss = {
      position: "absolute",
      top: "258px",
      left: "377px",
      background: "white",
      color: "black",
      padding: "10px",
      borderRadius: "3px",
      width: "50%",
      display: "flex",
      border: "1px solid #ded4d4",
      flexDirection: "column",
    };
    setTooltipStyle(styleCss);
    setHoveredData(lname);
  };

  const handleCellMouseLeave = () => {
    setHoveredData(null);
  };
  const closePopup = () => {
    setHoveredData(null);
  };

  const handleTooltipMouseUp = (event) => {
    event.stopPropagation();
  };
  const testFun = () => {};

  const locationConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .locationHierarchy
    : {};
    const locationCustomHeader = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration"))?.entities
        ?.locationCustomHeader
    : {};
  console.log("locationCustomHeader", locationCustomHeader);
  const localeConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).locale
    : {};
  const {
    locationData,
    refresh,
    pageSize,
    totalElement,
    setPageIndex,
    pageIndex,
    setSortValue,
    isLoading,
    componentName,
    locationGrid

  } = props;
  const initialDataState = {
    skip: 0,
  };
  const [dataState, setDataState] = useState(initialDataState);

  const [sortDir, setSortDir] = useState(false);

  const [sortKey, setSortKey] = useState("");
  if(entitiesName.includes(componentName)){
    localStorage.setItem(`${componentName}SortValue`,sortKey)
    for(let i=0;i<entitiesName.length;i++){
      if(entitiesName[i]!=componentName){
        localStorage.removeItem(`${entitiesName[i]}SortValue`);
      }
    }
  }

  useEffect(() => {
    setDataState({ ...dataState });
  }, [pageSize, totalElement]);
  useEffect(() => {
    setSortKey("");
  }, [refresh]);

  const noOfPages = Math.ceil(totalElement / pageSize);
  const start = (pageIndex - 1) * pageSize;
  const end = start + pageSize;
  const endCount = end > totalElement ? `- ${totalElement}` : `- ${end}`;
console.log("locationDataupper",locationData)
  const locationDataRows =
    locationData &&
    locationData.map((item) => {
      const items = { ...item };
      items.node_type_code =
        Object.keys(item?.node_type ?? {}).length > 0
          ? item?.node_type?.node_type_code
          : "";

          // if (
          //   locationCustomHeader &&
          //   Number(locationCustomHeader?.locationCustomHeaderCount) &&
          //   Number(locationCustomHeader?.locationCustomHeaderCount) > 0
          // ) {
          //   console.log("iaminsideif");
          //   for (
          //     let index = 1;
          //     index <= locationCustomHeader?.locationCustomHeaderCount;
          //     index++
          //   ) {
          //    console.log("headerrrrrr",index)
          //     items[locationCustomHeader[`header${index}`]] = item[`header${index}`]
          //     delete items[`header${index}`]
          //   }
          // }
      items.node_type_title =
        Object.keys(item?.node_type ?? {}).length > 0
          ? item?.node_type?.node_type_title
          : "";
          items.latitude = item.coordinates ? item.coordinates.x : 0;
          items.longitude = item.coordinates ? item.coordinates.y : 0;
      return items;
    });
  const _renderSortIcons = (obj) => {
    return (
      <>
        {sortKey === obj ? (
          sortDir || obj !== sortKey ? (
            <i className="fa fa-arrow-down" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-arrow-up" aria-hidden="true"></i>
          )
        ) : (
          <>
            {" "}
            <i className="fa fa-sort" aria-hidden="true"></i>
          </>
        )}{" "}
      </>
    );
  };

  const handleHeaderCheckboxChange = () => {
    const updatedDataRows = locationDataRows.map((item) => ({
      ...item,
      selected: !selectAll,
    }));

    setSelectAll(!selectAll);
    if (selectAll === false) {
      locationDataRows.map((item) => {
        item.selected = false;
      });
    }
    setDataState({
      ...dataState,
      data: process(updatedDataRows, dataState),
    });

    const newSelectedRowIds = updatedDataRows
      .filter((item) => item.selected)
      .map((item) => ({
        id: item.item.id,
        tenantId: tenantId,
        buId: buId,
      }));

    // setSelectedRowIds(newSelectedRowIds);
    dispatch({ type: SET_SELECTED_ROW_DATA, payload: newSelectedRowIds });
  };
  const user = JSON.parse(localStorage.getItem("user"));

  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const [selectAll, setSelectAll] = useState(false);
  const grid = React.useRef(null);

  React.useEffect(() => {
    if (locationGrid.length > 0) {
      grid.current = document.querySelector(".k-grid");
    }
  }, [locationGrid]);

  useEffect(() => {
    setDataState({ ...initialDataState });
    if (selectAll == true) {
      setSelectAll(!selectAll);
    }
    dispatch({ type: SET_SELECTED_ROW_DATA, payload: [] });
  }, [pageSize, totalElement]);
  const handleRowCheckboxChange = (dataItem) => {
    console.log("handleRowCheckboxChange1,locationDataRows",dataItem,locationDataRows)
    const updatedDataRows = [...locationDataRows];
    console.log("locarion")
    const clickedIndex = updatedDataRows.findIndex((item) => item.node_mapping_id === dataItem.node_mapping_id);

    if (clickedIndex !== -1) {
      updatedDataRows[clickedIndex].selected =!dataItem.selected
        // !updatedDataRows[clickedIndex].selected;
    }

    setDataState({
      ...dataState,
      data: process(updatedDataRows, dataState),
    });

    const newSelectedRowIds = updatedDataRows
      .filter((item) => item.selected)
      .map((item) => ({
        id: dataItem.node_mapping_id,
        tenantId: tenantId,
        buId: buId,
      }));
      console.log("handleRowCheckboxChange1",newSelectedRowIds)

    dispatch({ type: SET_SELECTED_ROW_DATA, payload: newSelectedRowIds });
  };
  console.log("locationDataRows,locationGrid",locationDataRows,locationGrid)
  // const locationCustomHeader = JSON.parse(localStorage.getItem("configuration"))
  // ? JSON.parse(localStorage.getItem("configuration"))?.entities
  //     ?.locationCustomHeader
  // : {};
  return (
    <>
      <div className="tablegrid">
        {!isEmpty(locationDataRows) ? (
          <>
            <Grid
              pageable={false}
              sortable={false}
              resizable={true}
              fixedScroll={true}
              
              data={
                !isEmpty(locationDataRows)
                  ? process(locationDataRows, dataState)
                  : null
              }
              {...dataState}
              onDataStateChange={(e) => {
                setDataState(e.dataState);
              }}
              className={"table-style"}
            >
              {componentName === "location" && (
              <Column
                title="New Column"
                field="newColumn"
                width="45px"
                key="new-column"
                headerCell={({}) => {
                  return (
                    <div>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleHeaderCheckboxChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  );
                }}
                cell={({ dataItem }) => {
                  return (
                    <td>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={dataItem.selected}
                          onChange={() => handleRowCheckboxChange(dataItem)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  );
                }}
              />
            )}
              {locationGrid.map((item, idx) => {
                if (
                  item.beName.toUpperCase() !== "MANUFACTURING_CAPACITY" &&
                  item.beName.toUpperCase() !== "CAPACITY_WAREHOUSE_UNIT" &&
                  item.beName.toUpperCase() !==
                    "MANUFACTURING_OPERATING_HOURS_SHIFT" &&
                  item.beName.toUpperCase() !== "MANUFACTURING_SHIFTS_PER_DAY" &&
                  item.beName.toUpperCase() !== "WAREHOUSE_SHIFT_PER_DAY" &&
                  item.beName.toUpperCase() !== "WAREHOUSE_OPERATING_HOURS_SHIFT"
                ) {
                  if (
                    item.beName.toUpperCase() === "STATUS" ||
                    item.beName.toUpperCase() === "NODE_LIFECYCLE_STATUS"
                  ) {
                    return (
                      <Column
                        title={
                          item.beName.includes("locationHierarchyLevel") &&
                          locationConfiguration
                            ? locationConfiguration[item.beName]
                            : item.beName.includes("header") &&
                            locationCustomHeader
                              ? locationCustomHeader[item.beName]
                              : `${item.feName}`
                        }
                        field={item.beName}
                        width="130px"
                        key={idx}
                        cell={(props) => {
                          return (
                            <td>
                              <span>
                                {props.dataItem[item.beName]
                                  ? t("active")
                                  : t("inactive")}
                              </span>
                            </td>
                          );
                        }}
                        headerClassName={`column-sorting-icon ${item.beName == localStorage.getItem(`${componentName}SortValue`)?"sort_column":""}`}

                        headerCell={(props) => {
                          return (
                            <span
                              onClick={() => {
                                setSortDir(!sortDir || item.beName !== sortKey);
                                setSortKey(item.beName);
                                setSortValue(
                                  `${item} ${
                                    !sortDir || item.beName !== sortKey
                                      ? "ASC"
                                      : "DESC"
                                  }`
                                );
                              }}
                              className="th-flex"
                            >
                              {props.title} {_renderSortIcons(item.beName)}
                            </span>
                          );
                        }}
                      />
                    );
                  }
                  else if (
                    item.beName.toUpperCase() === "WAREHOUSE_LOADING_SHIFT" ||
                    item.beName.toUpperCase() === "WAREHOUSE_UNLOADING_SHIFT" ||
                    item.beName.toUpperCase() === "OPERATING_HOURS_SHIFT" ||
                    item.beName.toUpperCase() === "WORKING_DAYS" ||
                    item.beName.toUpperCase() === "PLANNING_DAYS" ||
                    item.beName.toUpperCase() === "HOLIDAYS" 

                  ) {
                    return (
                      <Column
                        title={`${item.feName}`}
                        field={item.beName}
                        width="130px"
                        key={idx}
                        cell={(props) => (
                          <td
                            onClick={(event) => {
                              handleCellMouseEnter(event, props.dataItem[item.beName]);
                            }}
                          >
                            {formatLnameValue(props.dataItem[item.beName])}
                          </td>
                        )}
                        headerClassName={`column-sorting-icon ${item.beName == localStorage.getItem(`${componentName}SortValue`)?"sort_column":""}`}

                        headerCell={(props) => {
                          return (
                            <span
                              onClick={() => {
                                setSortDir(!sortDir || item.beName !== sortKey);
                                setSortKey(item.beName);
                                setSortValue(
                                  `${item.beName} ${
                                    !sortDir || item.beName !== sortKey
                                      ? "ASC"
                                      : "DESC"
                                  }`
                                );
                              }}
                              className="th-flex"
                            >
                              {props.title} {_renderSortIcons(item.beName)}
                            </span>
                          );
                        }}
                      />
                    );
                  }
                  else {
                    if (
                      item.beName.toUpperCase() === "NODE_LIFECYCLE_START_DATE" ||
                      item.beName.toUpperCase() === "NODE_LIFECYCLE_SUNSET_DATE" ||
                      item.beName.toUpperCase() === "NODE_LIFECYCLE_END_DATE"
                    ) {
                      return (
                        <Column
                          title={t(`${item.feName}`)}
                          field={item.beName}
                          width="130px"
                          key={idx}
                          cell={(props) => {
                            return (
                              <td>
                                <span>
                                  {props.dataItem[item.beName]
                                    ? moment(props.dataItem[item.beName]).format(
                                        localeConfiguration[
                                          "dateFormat"
                                        ]?.toUpperCase()
                                      )
                                    : ""}
                                </span>
                              </td>
                            );
                          }}
                          headerClassName={`column-sorting-icon ${item.beName == localStorage.getItem(`${componentName}SortValue`)?"sort_column":""}`}

                          headerCell={(props) => {
                            return (
                              <span
                                onClick={() => {
                                  setSortDir(!sortDir || item.beName !== sortKey);
                                  setSortKey(item.beName);
                                  setSortValue(
                                    `${item.beName} ${
                                      !sortDir || item.beName !== sortKey
                                        ? "ASC"
                                        : "DESC"
                                    }`
                                  );
                                }}
                                className="th-flex"
                              >
                                {props.title} {_renderSortIcons(item.beName)}
                              </span>
                            );
                          }}
                        />
                      );
                    } else {
                      return (
                        <Column
                          title={
                            // item.beName.includes("locationHierarchyLevel") &&
                            // locationConfiguration
                            //   ? locationConfiguration[item.beName]
                            //   : item.beName.toUpperCase() == "NODE_CODE" &&
                            //     locationConfiguration
                            //   ? locationConfiguration["base"] +
                            //     " " +
                            //     t("labelCode")
                            //   : item.beName.toUpperCase() == "NODE" &&
                            //     locationConfiguration
                            //   ? locationConfiguration["base"]
                            //   : item.beName.includes("header") &&
                            // locationCustomHeader
                            //   ? locationCustomHeader[item.beName]
                            //   : `${item.feName}`
                          item.feName

                          }
                          field={item.beName}
                          width="130px"
                          key={idx}
                          headerClassName={`column-sorting-icon ${item.beName == localStorage.getItem(`${componentName}SortValue`)?"sort_column":""}`}

                          headerCell={(props) => {
                            return (
                              <span
                                onClick={() => {
                                  setSortDir(!sortDir || item.beName !== sortKey);
                                  setSortKey(item.beName);
                                  setSortValue(
                                    `${item.beName} ${
                                      !sortDir || item.beName !== sortKey
                                        ? "ASC"
                                        : "DESC"
                                    }`
                                  );
                                }}
                                className="th-flex"
                              >
                                {props.title} {_renderSortIcons(item.beName)}
                              </span>
                            );
                          }}
                        />
                      );
                    }
                  }
                } else {
                  return (
                    <Column
                      title={
                        item.beName.includes("locationHierarchyLevel") &&
                        locationConfiguration
                          ? locationConfiguration[item.beName]
                          :`${item.feName}`
                      }
                      field={item.beName}
                      width="130px"
                      cell={(props) => {
                        return (
                          <td>
                            {" "}
                            <NumericFormat
                              value={props.dataItem[item.beName]}
                              decimalSeparator={
                                localeConfiguration["decimalSeparator"]
                              }
                              thousandsGroupStyle={
                                localeConfiguration["thousandsGroupStyle"]
                              }
                              thousandSeparator={
                                localeConfiguration["thousandSeparator"]
                              }
                              displayType="text"
                              renderText={(value) => <>{value}</>}
                            />
                          </td>
                        );
                      }}
                      headerClassName={`column-sorting-icon ${item.beName == localStorage.getItem(`${componentName}SortValue`)?"sort_column":""}`}

                      headerCell={(props) => {
                        return (
                          <span
                            onClick={() => {
                              setSortDir(!sortDir || item.beName !== sortKey);
                              setSortKey(item.beName);
                              setSortValue(
                                `${item.beName} ${
                                  !sortDir || item.beName !== sortKey ? "ASC" : "DESC"
                                }`
                              );
                            }}
                            className="th-flex"
                          >
                            {props.title} {_renderSortIcons(item.beName)}
                          </span>
                        );
                      }}
                    />
                  );
                }
              })}
            </Grid>

            <Pagination
              endCount={endCount}
              page={parseInt(pageIndex)}
              onChange={(e, value) => setPageIndex(value)}
              count={noOfPages}
              showFirstButton={true}
              showLastButton={true}
              totalElement={totalElement}
              start={start}
            />
          </>
        ) : (
          <>
            <div className="text-center">No records found</div>
          </>
        )}
      </div>
      {hoveredData && (
        <div>
          <div
            style={tooltipStyle}
          >
            <span
              style={{
                width: "22px",
                cursor: "pointer",
                border: "1px solid #ded4d4",
                display: "flex",
                justifyContent: "center",
                marginLeft:"98%"
              }}
              onClick={closePopup}
            >
              X
            </span>
            <span>{hoveredData} </span>
          </div>
        </div>
      )}
    </>
  );
};

export default LocationGrid;
