import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import Pagination from "../../../../../components/tableGrid/pagination/";
import { red } from "@mui/material/colors";
import { entitiesName } from "../../../../../utils/Constants";
import { SET_SELECTED_ROW_DATA } from "../../../../../store/types";
import { get } from "lodash";

const ProductGrid = (props) => {
  const { t } = useTranslation();
  const productConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .productHierarchy
    : {};
    const productCustomHeader = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration"))?.entities
        ?.productCustomHeader
    : {};
  const localeConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).locale
    : {};
  const {
    productData,
    refresh,
    pageSize,
    totalElement,
    setPageIndex,
    pageIndex,
    setSortValue,
    isLoading,
    componentName,
    productGrid,
  } = props;

  const dispatch = useDispatch();
  const initialDataState = {
    skip: 0,
  };
  const [dataState, setDataState] = useState(initialDataState);
  const [sortKey, setSortKey] = useState("");
  const [sortDir, setSortDir] = useState(false);
  if (entitiesName.includes(componentName)) {
    localStorage.setItem(`${componentName}SortValue`, sortKey);
    for (let i = 0; i < entitiesName.length; i++) {
      if (entitiesName[i] != componentName) {
        localStorage.removeItem(`${entitiesName[i]}SortValue`);
      }
    }
  }

  const [hoveredData, setHoveredData] = useState(null);
  const [tooltipStyle, setTooltipStyle] = useState({});

  const formatLnameValue = (lname) => {
    try {
      if (lname === null) {
        return "NAN";
      }
      const parsedJson = JSON.parse(lname);
      return "{...}";
    } catch (error) {
      return lname;
    }
  };
  const user = JSON.parse(localStorage.getItem("user"));

  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const handleCellMouseEnter = (event, lname) => {
    let styleCss = {
      position: "absolute",
      top: "258px",
      left: "377px",
      background: "white",
      color: "black",
      padding: "10px",
      borderRadius: "3px",
      width: "50%",
      display: "flex",
      border: "1px solid #ded4d4",
      flexDirection: "column",
    };
    setTooltipStyle(styleCss);
    setHoveredData(lname);
  };

  const handleCellMouseLeave = () => {
    setHoveredData(null);
  };
  const closePopup = () => {
    setHoveredData(null);
  };

  const handleTooltipMouseUp = (event) => {
    event.stopPropagation();
  };
  const testFun = () => {};
  const noOfPages = Math.ceil(totalElement / pageSize);
  const start = (pageIndex - 1) * pageSize;
  const end = start + pageSize;
  const endCount = end > totalElement ? `- ${totalElement}` : `- ${end}`;
  useEffect(() => {
    setDataState({ ...dataState });
  }, [pageSize, totalElement]);
  useEffect(() => {
    setSortKey("");
  }, [refresh]);

  const productDataRows =
    productData &&
    productData.map((item) => {
      const items = { ...item };
      items.channel_name =
        Object.keys(item?.channel ?? {}).length > 0
          ? item?.channel?.channel_name
          : "";
          // items.selected=false
      items.channel_description =
        Object.keys(item?.channel ?? {}).length > 0
          ? item?.channel?.channel_description
          : "";
      items.like_sku_name =
        Object.keys(item?.like_sku ?? {}).length > 0
          ? item?.like_sku?.like_sku_name != null
            ? item?.like_sku?.like_sku_name
            : ""
          : "";
          // if (
          //   productCustomHeader &&
          //   Number(productCustomHeader?.productCustomHeaderCount) &&
          //   Number(productCustomHeader?.productCustomHeaderCount) > 0
          // ) {
          //   for (
          //     let index = 1;
          //     index <= productCustomHeader?.productCustomHeaderCount;
          //     index++
          //   ) {
          //     items[productCustomHeader[`header${index}`]] = item[`header${index}`]
          //     delete items[`header${index}`]
          //   }
          // }
      items.like_sku_code =
        Object.keys(item?.like_sku ?? {}).length > 0
          ? item?.like_sku?.like_sku_code != null
            ? item?.like_sku?.like_sku_code
            : ""
          : "";
      return items;
    });

  const _renderSortIcons = (obj) => {
    return (
      <>
        {sortKey === obj ? (
          sortDir || obj !== sortKey ? (
            <i className="fa fa-arrow-down" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-arrow-up" aria-hidden="true"></i>
          )
        ) : (
          <>
            {" "}
            <i className="fa fa-sort" aria-hidden="true"></i>
          </>
        )}{" "}
      </>
    );
  };

  const handleHeaderCheckboxChange = () => {
    const updatedDataRows = productDataRows.map((item) => ({
      ...item,
      selected: !selectAll,
    }));

    setSelectAll(!selectAll);
    if (selectAll === false) {
      productDataRows.map((item) => {
        item.selected = false;
      });
    }
    setDataState({
      ...dataState,
      data: process(updatedDataRows, dataState),
    });
    
    
    const newSelectedRowIds = updatedDataRows
      .filter((item) => item.selected)
      .map((item) => ({
        id: item.mapping_id,
        tenantId: tenantId,
        buId: buId,
      }));

    // setSelectedRowIds(newSelectedRowIds);
    dispatch({ type: SET_SELECTED_ROW_DATA, payload: newSelectedRowIds });
  };

  const [selectAll, setSelectAll] = useState(false);
  const grid = React.useRef(null);

  React.useEffect(() => {
    if (productGrid.length > 0) {
      grid.current = document.querySelector(".k-grid");
    }
  }, [productGrid]);

  useEffect(() => {
    setDataState({ ...initialDataState });
    if (selectAll == true) {
      setSelectAll(!selectAll);
    }
    dispatch({ type: SET_SELECTED_ROW_DATA, payload: [] });
  }, [pageSize, totalElement]);
  const handleRowCheckboxChange = (dataItem) => {
    
    let updatedDataRows = [...productDataRows];

    const clickedIndex = updatedDataRows.findIndex((item) => item.mapping_id === dataItem.mapping_id);
  
    if (clickedIndex !== -1) {
      updatedDataRows[clickedIndex].selected =!dataItem.selected
        // !updatedDataRows[clickedIndex].selected;
    }



    setDataState({
      ...dataState,
      data: process(updatedDataRows, dataState),
    });


    const newSelectedRowIds = updatedDataRows
      .filter((item) => item.selected)
      .map((item) => ({
        id: dataItem.mapping_id,
        tenantId: tenantId,
        buId: buId,
      }));
    dispatch({ type: SET_SELECTED_ROW_DATA, payload: newSelectedRowIds });
  };

  console.log("productDataRowssss", productDataRows, productGrid)
  return (
    <>
      <div className="tablegrid">
        {productDataRows && productDataRows.length && productGrid && productGrid.length? (
          <>
            <Grid
              pageable={false}
              sortable={false}
              resizable={true}
              fixedScroll={true}
              data={
                !isEmpty(productDataRows)
                  ? process(productDataRows, dataState)
                  : null
              }
              {...dataState}
              onDataStateChange={(e) => {
                setDataState(e.dataState);
              }}
              className={"table-style"}
            >
              {/* {componentName === "product" && ( */}
              {
                productGrid && productGrid.length && <Column
                title="New Column"
                field="newColumn"
                width="45px"
                key="new-column"
                headerCell={({}) => {
                  return (
                    <div>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleHeaderCheckboxChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  );
                }}
                cell={({ dataItem }) => {
                  return (
                    <td>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={dataItem.selected}
                          onChange={() => handleRowCheckboxChange(dataItem)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  );
                }}
              />
              }
                
              )
              {productGrid &&
                productGrid?.map((item, idx) => {
                  if (
                    item.beName.toUpperCase() !== "UNIT_PRICE" &&
                    item.beName.toUpperCase() !== "WEIGHT" &&
                    item.beName.toUpperCase() !== "VOLUME" &&
                    item.beName.toUpperCase() !== "GROSS_MARGIN_UNIT_PCT" &&
                    item.beName.toUpperCase() !== "EBITDTA_UNIT_PCT"
                  ) {
                    if (
                      item.beName.toUpperCase() === "LIFECYCLE_START_DATE" ||
                      item.beName.toUpperCase() === "LIFECYCLE_SUNSET_DATE" ||
                      item.beName.toUpperCase() === "LIFECYCLE_END_DATE"
                    ) {
                      return (
                        <Column
                          key={idx}
                          title={t(`${item.feName}`)}
                          field={item.beName}
                          width="130px"
                          cell={(props) => {
                            return (
                              <td>
                                <span>
                                  {props.dataItem[item.beName]
                                    ? moment(props.dataItem[item.beName]).format(
                                        localeConfiguration[
                                          "dateFormat"
                                        ]?.toUpperCase()
                                      )
                                    : ""}
                                </span>
                              </td>
                            );
                          }}
                          headerClassName={`column-sorting-icon ${
                            item.beName ==
                            localStorage.getItem(`${componentName}SortValue`)
                              ? "sort_column"
                              : ""
                          }`}
                          headerCell={(props) => {
                            return (
                              <span
                                onClick={() => {
                                  setSortDir(!sortDir || item.beName !== sortKey);
                                  setSortKey(item.beName);
                                  setSortValue(
                                    `${item.beName} ${
                                      !sortDir || item.beName !== sortKey
                                        ? "ASC"
                                        : "DESC"
                                    }`
                                  );
                                }}
                                className="th-flex"
                              >
                                {props.title} {_renderSortIcons(item.beName)}
                              </span>
                            );
                          }}
                        />
                      );
                    } else if (item.beName.toUpperCase() === "ADDITIONAL_INFO") {
                      return (
                        <Column
                          title={`${item.feName}`}
                          field={item.beName}
                          width="130px"
                          key={idx}
                          cell={(props) => (
                            <td
                              onClick={(event) => {
                                handleCellMouseEnter(
                                  event,
                                  props.dataItem[item.beName]
                                );
                              }}
                            >
                              {formatLnameValue(props.dataItem[item.beName])}
                            </td>
                          )}
                          headerClassName={`column-sorting-icon ${
                            item.beName ==
                            localStorage.getItem(`${componentName}SortValue`)
                              ? "sort_column"
                              : ""
                          }`}
                          headerCell={(props) => {
                            return (
                              <span
                                onClick={() => {
                                  setSortDir(!sortDir || item.beName !== sortKey);
                                  setSortKey(item.beName);
                                  setSortValue(
                                    `${item.beName} ${
                                      !sortDir || item.beName !== sortKey
                                        ? "ASC"
                                        : "DESC"
                                    }`
                                  );
                                }}
                                className="th-flex"
                              >
                                {props.title} {_renderSortIcons(item.beName)}
                              </span>
                            );
                          }}
                        />
                      );
                    } else if (
                      item.beName.toUpperCase() === "PRODUCT_LIFECYCLE_STATUS"
                    ) {
                      return (
                        <Column
                          title={item.feName}
                          field={item.beName}
                          width="130px"
                          key={idx}
                          cell={(props) => {
                            return (
                              <td>
                                <span>
                                  {props.dataItem[item.beName]
                                    ? t("active")
                                    : t("inactive")}
                                </span>
                              </td>
                            );
                          }}
                          headerClassName={`column-sorting-icon ${
                            item.beName ==
                            localStorage.getItem(`${componentName}SortValue`)
                              ? "sort_column"
                              : ""
                          }`}
                          headerCell={(props) => {
                            return (
                              <span
                                onClick={() => {
                                  setSortDir(!sortDir || item.beName !== sortKey);
                                  setSortKey(item.beName);
                                  setSortValue(
                                    `${item.beName} ${
                                      !sortDir || item.beName !== sortKey
                                        ? "ASC"
                                        : "DESC"
                                    }`
                                  );
                                }}
                                className="th-flex"
                              >
                                {props.title} {_renderSortIcons(item.beName)}
                              </span>
                            );
                          }}
                        />
                      );
                    } else {
                      return (
                        <Column
                          key={idx}
                          title={
                            // item.beName.includes("descriptionproductHierarchyLevel") &&
                            // productConfiguration
                            //   ? productConfiguration[
                            //       item.beName.replace(/description/g, "")
                            //     ] +
                            //     " " +
                            //     t("labelDescription")
                            //   : item.beName.includes("productHierarchyLevel") &&
                            //     productConfiguration
                            //   ? productConfiguration[item.beName]
                            //   : item.beName.includes("like_sku_code") &&
                            //     productConfiguration
                            //   ? t("labelLike") +
                            //     " " +
                            //     productConfiguration["base"] +
                            //     " " +
                            //     t("labelCode")
                            //   : item.beName.includes("like_sku_name") &&
                            //     productConfiguration
                            //   ? t("labelLike") +
                            //     " " +
                            //     productConfiguration["base"] +
                            //     " " +
                            //     t("labelName")
                            //   : item.beName.includes("sku_code") &&
                            //     productConfiguration
                            //   ? productConfiguration["base"]
                            //   // +
                            //     // " " +
                            //     // t("labelCode")
                            //   : item.beName.includes("sku_name") &&
                            //     productConfiguration
                            //   ? productConfiguration["base"] +
                            //     " " +
                            //     t("labelName")
                            //   : item.beName.includes("sku_description") &&
                            //     productConfiguration
                            //   ? productConfiguration["base"] +
                            //     " " +
                            //     t("labelDescription")
                            //   : item.feName
                            item.feName
                          }
                          field={item.beName}
                          width="130px"
                          headerClassName={`column-sorting-icon ${
                            item.beName ==
                            localStorage.getItem(`${componentName}SortValue`)
                              ? "sort_column"
                              : ""
                          }`}
                          headerCell={(props) => {
                            return (
                              <span
                                onClick={() => {
                                  setSortDir(!sortDir || item.beName !== sortKey);
                                  setSortKey(item.beName);
                                  setSortValue(
                                    `${item.beName} ${
                                      !sortDir || item.beName !== sortKey
                                        ? "ASC"
                                        : "DESC"
                                    }`
                                  );
                                }}
                                className="th-flex"
                              >
                                {props.title} {_renderSortIcons(item.beName)}
                              </span>
                            );
                          }}
                        />
                      );
                    }
                  } else {
                    return (
                      <Column
                        key={idx}
                        title={item.feName}
                        field={item.beName}
                        width="130px"
                        cell={(props) => {
                          return (
                            <td>
                              {" "}
                              <NumericFormat
                                value={props.dataItem[item.beName]}
                                decimalSeparator={
                                  localeConfiguration["decimalSeparator"]
                                }
                                thousandsGroupStyle={
                                  localeConfiguration["thousandsGroupStyle"]
                                }
                                thousandSeparator={
                                  localeConfiguration["thousandSeparator"]
                                }
                                displayType="text"
                                decimalScale={2}
                                renderText={(value) => <>{value}</>}
                              />
                            </td>
                          );
                        }}
                        headerClassName={`column-sorting-icon ${
                          item.beName ==
                          localStorage.getItem(`${componentName}SortValue`)
                            ? "sort_column"
                            : ""
                        }`}
                        headerCell={(props) => {
                          return (
                            <span
                              onClick={() => {
                                setSortDir(!sortDir || item.beName !== sortKey);
                                setSortKey(item.beName);
                                setSortValue(
                                  `${item.beName} ${
                                    !sortDir || item.beName !== sortKey
                                      ? "ASC"
                                      : "DESC"
                                  }`
                                );
                              }}
                              className="th-flex"
                            >
                              {props.title} {_renderSortIcons(item.beName)}
                            </span>
                          );
                        }}
                      />
                    );
                  }
                })}
            </Grid>
            <Pagination
              endCount={endCount}
              page={parseInt(pageIndex)}
              onChange={(e, value) => setPageIndex(value)}
              count={noOfPages}
              showFirstButton={true}
              showLastButton={true}
              totalElement={totalElement}
              start={start}
            />
          </>
        ) : (
          <>
            <div className="text-center">No records found</div>
          </>
        )}
      </div>
      {hoveredData && (
        <div>
          <div style={tooltipStyle}>
            <span
              style={{
                width: "22px",
                cursor: "pointer",
                border: "1px solid #ded4d4",
                display: "flex",
                justifyContent: "center",
                marginLeft: "98%",
              }}
              onClick={closePopup}
            >
              X
            </span>
            <span>{hoveredData} </span>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(ProductGrid);
