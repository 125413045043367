import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

import { forEach, isEmpty } from "lodash";
import Loader from "../../components/loader";
import { useTranslation } from "react-i18next";
import Pagination from "./pagination/";
import { setTableWidth } from "../../utils/setTableWidth";
import ProcessingLoader from "../processingLoader";
import { entitiesName } from "../../utils/Constants";
import { get } from "lodash";
// import { Toolbar } from "@mui/material";
// import TableToolbar from "./toolbar";
import { useDispatch, useSelector } from "react-redux";
import { SET_SELECTED_ROW_DATA } from "../../store/types";
const COLUMN_MIN = 4;
const ADJUST_PADDING = 4;
const TableGrid = (props) => {
  const { t } = useTranslation();
  const {
    dataRows,
    dataColumn,
    refresh,
    pageSize,
    totalElement,
    setPageIndex,
    pageIndex,
    setSortValue,
    skip,
    width,
    replaceColumnLevels,
    isLoading,
    componentName,
  } = props;

  const initialDataState = {
    skip: skip ?? 0,
  };
  const [dataState, setDataState] = useState(initialDataState);
  const [sortKey, setSortKey] = useState("");
  const [sortDir, setSortDir] = useState(false);
  const grid = React.useRef(null);
  if (entitiesName.includes(componentName)) {
    localStorage.setItem(`${componentName}SortValue`, sortKey);
    for (let i = 0; i < entitiesName.length; i++) {
      if (entitiesName[i] != componentName) {
        localStorage.removeItem(`${entitiesName[i]}SortValue`);
      }
    }
  }

  const user = JSON.parse(localStorage.getItem("user"));
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");

  const [selectAll, setSelectAll] = useState(false);

  React.useEffect(() => {
    if ( dataColumn && dataColumn.length > 0) {
      grid.current = document.querySelector(".k-grid");
    }
  }, [dataColumn]);

  useEffect(() => {
    setDataState({ ...initialDataState });
    if (selectAll == true) {
      setSelectAll(!selectAll);
    }
    dispatch({ type: SET_SELECTED_ROW_DATA, payload: [] });
  }, [pageSize, totalElement]);


  const noOfPages = Math.ceil(totalElement / parseInt(pageSize));
  const start = (parseInt(pageIndex) - 1) * parseInt(pageSize);
  const end = start + parseInt(pageSize);
  const endCount = end > totalElement ? `- ${totalElement}` : `- ${end}`;

  useEffect(() => {
    setSortKey("");
  }, [refresh]);

  const getTitle = (val) => {
    if (replaceColumnLevels && replaceColumnLevels.length > 0) {
      const findIn =
        replaceColumnLevels &&
        replaceColumnLevels?.findIndex((v) => v?.replaceWith === val);
      if (
        findIn >= 0 &&
        Object.keys(replaceColumnLevels[findIn] || {})?.length > 0
      ) {
        return replaceColumnLevels[findIn]?.newLabel || val;
      }
    } else {
      const words = val.split("_");
      return words
        .map((word) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(" ");
    }
    if(val === "channel") return "Channel Name";
    return val;
  };

  const handleHeaderCheckboxChange = () => {
    const updatedDataRows = dataRows.map((item) => ({
      ...item,
      selected: !selectAll,
    }));

    setSelectAll(!selectAll);
    if (selectAll === false) {
      dataRows.map((item) => {
        item.selected = false;
      });
    }
    setDataState({
      ...dataState,
      data: process(updatedDataRows, dataState),
    });

    const newSelectedRowIds = updatedDataRows
      .filter((item) => item.selected)
      .map((item) => ({
        id: item.id,
        tenantId: tenantId,
        buId: buId,
      }));

    // setSelectedRowIds(newSelectedRowIds);
    dispatch({ type: SET_SELECTED_ROW_DATA, payload: newSelectedRowIds });
  };
  const { selectedRowIds } = useSelector((store) => store.commonReducer);
  const dispatch = useDispatch();

  const handleRowCheckboxChange = (dataItem) => {
    
    const updatedDataRows = [...dataRows];
    // const clickedIndex = updatedDataRows.findIndex((item) => item === dataItem);
    const clickedIndex = updatedDataRows.findIndex((item) => item.id === dataItem.id);

    // const clickedIndex=dataRows.findIndex(obj => obj.id === dataItem.id);
    if (clickedIndex !== -1) {
      updatedDataRows[clickedIndex].selected =!dataItem.selected
        // !updatedDataRows[clickedIndex].selected;
    }


    setDataState({
      ...dataState,
      data: process(updatedDataRows, dataState),
    });

    const newSelectedRowIds = updatedDataRows
      .filter((item) => item.selected)
      .map((item) => ({
        id: item.id,
        tenantId: tenantId,
        buId: buId,
      }));
    dispatch({ type: SET_SELECTED_ROW_DATA, payload: newSelectedRowIds });
  };

  const _renderSortIcons = (obj) => {
    return (
      <>
        {sortKey === obj ? (
          sortDir || obj !== sortKey ? (
            <i className="fa fa-arrow-down" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-arrow-up" aria-hidden="true"></i>
          )
        ) : (
          <>
            {" "}
            <i className="fa fa-sort" aria-hidden="true"></i>
          </>
        )}{" "}
      </>
    );
  };
  const promoMasterConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities.promotions
        ?.promoHierarchy
    : {};
  return (
    <div className="tablegrid">
      {/* {dataColumn && dataColumn.length > 0 && !isEmpty(dataRows) ? ( */}
      {dataColumn && dataColumn.length && dataRows && dataRows.length ? (


        <>
          <Grid
            pageable={false}
            sortable={false}
            resizable={true}
            fixedScroll={true}
            data={!isEmpty(dataRows) ? process(dataRows, dataState) : null}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.dataState);
            }}
            className={"table-style"}
          >
            {/* {(componentName === "feature" || componentName ==="budget") && ( */}
            {(componentName === "feature" || componentName === "promo" || componentName === "mapping" || componentName ==="budget" || componentName ==="promomaster") && (

              <Column
                title="New Column"
                field="newColumn"
                width="45px"
                key="new-column"
                headerCell={({}) => {
                  return (
                    <div>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleHeaderCheckboxChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  );
                }}
                cell={({ dataItem }) => {
                  return (
                    <td>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={dataItem.selected}
                          onChange={() => handleRowCheckboxChange(dataItem)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  );
                }}
              />
            )}
            {dataColumn && dataColumn.length && dataRows && dataRows.length &&
              dataColumn?.map((item, idx) => {
                if (dataColumn.length > 6) {
                  if(item.beName.toUpperCase() === "MAPPING_LIFE_CYCLE_STATUS_PP" || item.beName.toUpperCase() === "MAPPING_LIFE_CYCLE_STATUS_RP" || item.beName.toUpperCase() === "MAPPING_LIFE_CYCLE_STATUS_RMP"){
                    return(
                      <Column
                        title={ item.beName.includes("promoHierarchyLevel") &&
                        promoMasterConfiguration
                      // ? promoMasterConfiguration[item.beName]: t(`${getTitle(item.beName)}`)}
                      ? promoMasterConfiguration[item.beName]: item.feName}

                        field={item.beName}
                        width={setTableWidth(120, grid, dataColumn)}
                        key={idx}
                        headerClassName={`column-sorting-icon ${
                          item.beName ==
                          localStorage.getItem(`${componentName}SortValue`)
                            ? "sort_column"
                            : ""
                        }`}
                        cell={(props) => {
                          return (
                            <td>
                              <span>
                                {props.dataItem[item.beName]
                                  ? t("active")
                                  : props.dataItem[item.beName] === null ? '' : t("inactive")}
                              </span>
                            </td>
                          );
                        }}
                        headerCell={(props) => {
                          return (
                            <span
                              onClick={() => {
                                setSortDir(!sortDir || item.beName !== sortKey);
                                setSortKey(item.beName);
                                setSortValue(
                                  `${item.beName} ${
                                    !sortDir || item.beName !== sortKey ? "ASC" : "DESC"
                                  }`
                                );
                              }}
                              className="th-flex"
                            >
                              {props.title} {_renderSortIcons(item.beName)}
                            </span>
                          );
                        }}
                      />
                    )
                  } else{
                    return (
                      <Column
                        title={ item.beName.includes("promoHierarchyLevel") &&
                        promoMasterConfiguration
                      ? promoMasterConfiguration[item.beName]: item.feName}
                        field={item.beName}
                        width={setTableWidth(120, grid, dataColumn)}
                        key={idx}
                        headerClassName={`column-sorting-icon ${
                          item.beName ==
                          localStorage.getItem(`${componentName}SortValue`)
                            ? "sort_column"
                            : ""
                        }`}
                        headerCell={(props) => {
                          return (
                            <span
                              onClick={() => {
                                setSortDir(!sortDir || item.beName !== sortKey);
                                setSortKey(item.beName);
                                setSortValue(
                                  `${item.beName} ${
                                    !sortDir || item.beName !== sortKey ? "ASC" : "DESC"
                                  }`
                                );
                              }}
                              className="th-flex"
                            >
                              {props.title} {_renderSortIcons(item.beName)}
                            </span>
                          );
                        }}
                      />
                    );
                  }
                } else {
                  return (
                    <Column
                      // title={t(`${item}`)}
                      title={ item.beName.includes("promoHierarchyLevel") &&
                      promoMasterConfiguration
                    ? promoMasterConfiguration[item.beName]: item.feName}
                      field={item.beName}
                      width="auto"
                      key={idx}
                      headerClassName={`column-sorting-icon ${
                        item.beName ==
                        localStorage.getItem(`${componentName}SortValue`)
                          ? "sort_column"
                          : ""
                      }`}
                      headerCell={(props) => {
                        return (
                          <span
                            onClick={() => {
                              setSortDir(!sortDir || item.beName !== sortKey);
                              setSortKey(item.beName);
                              setSortValue(
                                `${item.beName} ${
                                  !sortDir || item.beName !== sortKey ? "ASC" : "DESC"
                                }`
                              );
                            }}
                            className="th-flex"
                          >
                            {props.title} {_renderSortIcons(item.beName)}
                          </span>
                        );
                      }}
                    />
                  );
                }
              })}
          </Grid>
          {
            dataColumn && dataColumn.length && dataRows && dataRows.length &&
            <Pagination
            endCount={endCount}
            page={parseInt(pageIndex)}
            onChange={(e, value) => setPageIndex(value)}
            count={noOfPages}
            showFirstButton={true}
            showLastButton={true}
            totalElement={totalElement}
            start={start}
          />
          }
          
        </>
      ) : (
        <>
          <div className="text-center">No records found</div>
        </>
      )}
    </div>
  );
};

export default TableGrid
