import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_SNP_PERSONNEL,UPLOAD_SNP_PERSONNEL_CSV_DATA } from "../../../types";
import { getAllSNPPersonnelAPI,getHeaderAPI,uploadSNPPersonnelCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";

import { getAllSNPPersonnelSlice,snpPersonnelCSVUploadComplete,snpPersonnelCSVUploadError } from "../../../slices/transactional/snp-personnel";

export function* getAllSNPPersonnelSaga(action) {

 
  try {
    yield put(getAllSNPPersonnelSlice({
      data: {},
      loader: true
    }))
    if(!sessionStorage.getItem('snpPersonnelStaticHeader')){
      const response = yield getHeaderAPI("personnelmaster");
    yield sessionStorage.setItem('snpPersonnelStaticHeader', JSON.stringify(response.data.responseData));
    }
    const res = yield getAllSNPPersonnelAPI(action.payload);
    yield put(getAllSNPPersonnelSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllSNPPersonnelSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadSNPPersonnelCSVDataAPI(action.payload);
    yield put(snpPersonnelCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        snpPersonnelCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchSNPPersonnelSaga() {
  yield takeEvery(GET_ALL_SNP_PERSONNEL, getAllSNPPersonnelSaga);
  yield takeEvery(UPLOAD_SNP_PERSONNEL_CSV_DATA,uploadCSVSaga);
}
