import axios from "axios";
import { get } from "lodash";
import * as XLSX from "xlsx";
import { ConvertExcelAll } from "../helperFunction";
import { ENTITY_NAME } from "../constant/constant";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { store } from "../store";

const EDM_API_ENDPOINT = process.env.REACT_APP_EDM_API_ENDPOINT;
const SNOP_API_ENDPOINT = process.env.REACT_APP_SNOP_API_ENDPOINT;
const SCAI_API_BASEURL_PRODUCT = process.env.REACT_APP_SCAI_API_BASEURL_PRODUCT;
const SCAI_API_BASEURL_PERSONNEL =
  process.env.REACT_APP_SCAI_API_BASEURL_PERSONNEL;
  const SCAI_API_BASEURL_PROMO_MASTER =process.env.REACT_APP_SCAI_API_BASEURL_PROMO_MASTER
const SCAI_API_BASEURL_LOCATION =
  process.env.REACT_APP_SCAI_API_BASEURL_LOCATION;
const SCAI_API_BASEURL_CURRENCY =
  process.env.REACT_APP_SCAI_API_BASEURL_CURRENCY;
const SCAI_API_BASEURL_NETWORK = process.env.REACT_APP_SCAI_API_BASEURL_NETWORK;
const SCAI_API_BASEURL_PROMO=process.env.REACT_APP_SCAI_API_BASEURL_PROMO
const SCAI_API_BASEURL_MAPPING = process.env.REACT_APP_SCAI_API_BASEURL_MAPPING;
const SCAI_API_BASEURL_SALES_HISTORY =
  process.env.REACT_APP_SCAI_API_BASEURL_SALES_HISTORY;
const SCAI_API_BASEURL_SALES_ORDERS =
  process.env.REACT_APP_SCAI_API_BASEURL_SALES_ORDERS;


  const SCAI_API_BASEURL_RM_NETWORK =
  process.env.REACT_APP_SCAI_API_BASEURL_RM_NETWORK;
const SCAI_API_BASEURL_FEATURES =
  process.env.REACT_APP_SCAI_API_BASEURL_FEATURES;
const SCAI_API_BASEURL_FORECAST =
  process.env.REACT_APP_SCAI_API_BASEURL_FORECAST;
const SCAI_API_BASEURL_GOODSINTRANSIT =
  process.env.REACT_APP_SCAI_API_BASEURL_GOODSINTRANSIT;
const SCAI_API_BASEURL_GOODSINHAND =
  process.env.REACT_APP_SCAI_API_BASEURL_GOODSINHAND;
const SCAI_API_BASEURL_GOODSINPNBS =
  process.env.REACT_APP_SCAI_API_BASEURL_GOODSINPNBS;
const SCAI_API_BASEURL_PRODUCTION_WORK_ORDER =
  process.env.REACT_APP_SCAI_API_BASEURL_PRODUCTION_WORK_ORDER;
const SCAI_API_BASEURL_POEVENTS =
  process.env.REACT_APP_SCAI_API_BASEURL_POEVENTS;
const SCAI_API_BASEURL_PURCHASEORDER =
  process.env.REACT_APP_SCAI_API_BASEURL_PURCHASEORDER;
const SCAI_API_BASEURL_BILL_OF_MATERIALS =
  process.env.REACT_APP_SCAI_API_BASEURL_BILL_OF_MATERIALS;
const SCAI_API_BASEURL_STOCKTRANSFERORDER =
  process.env.REACT_APP_SCAI_API_BASEURL_STOCKTRANSFERORDER;
const SCAI_API_BASEURL_RM_NODE_MAPPING =
  process.env.REACT_APP_SCAI_API_BASEURL_RM_NODE_MAPPING;
const SCAI_API_BASEURL_SHIPMENT =
  process.env.REACT_APP_SCAI_API_BASEURL_SHIPMENT;
const SCAI_API_BASEURL_INVENTORY_EVENTS =
  process.env.REACT_APP_SCAI_API_BASEURL_INVENTORY_EVENTS;
const SCAI_API_BASEURL_RMPO = process.env.REACT_APP_SCAI_API_BASEURL_RMPO;
const SCAI_API_BASEURL_STO_EVENTS =
  process.env.REACT_APP_SCAI_API_BASEURL_STO_EVENTS;
const SCAI_API_BASEURL_STO_BUDGET =
  process.env.REACT_APP_SCAI_API_BASEURL_BUDGET;
const SCAI_API_BASEURL_ROW_MATERIAL =
  process.env.REACT_APP_SCAI_API_BASEURL_ROW_MATERIAL;
const SCAI_API_BASEURL_MARKETING_EVENTS =
  process.env.REACT_APP_SCAI_API_BASEURL_MARKETING_EVENTS;
const SCAI_API_BASEURL_PROMOTION_TYPE =
  process.env.REACT_APP_SCAI_API_BASEURL_PROMOTION_TYPE;
  const SCAI_API_BASEURL_SPLIT_MASTER =
  process.env.REACT_APP_SCAI_API_BASEURL_SPLIT_MASTER;

  const SCAI_API_BASEURL_ROUTE_MASTER = process.env.REACT_APP_SCAI_API_BASEURL_ROUTE_MASTER

  const SCAI_API_BASEURL_SNP_PERSONNEL = process.env.REACT_APP_SCAI_API_BASEURL_SNP_PERSONNEL


const user = JSON.parse(localStorage.getItem("user"));
const tenantId = get(user, "tenant_id", "");
let buId;
const selected_business_unit = JSON.parse(localStorage.getItem("selected_business_unit"));
if(selected_business_unit){
  buId = get(selected_business_unit, "business_unit_id", "");
} else{
  buId = get(user, "business_unit_id", "");
}

const token = JSON.parse(localStorage.getItem("token"));

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

// forecast sync api
export const forecastSyncAPI= async (snopId,url) => {
  return axios.post(
    `${url}?tenantId=${tenantId}&buId=${buId}&snopId=${snopId}`,
    config
  );
}
export const forecastReverseSyncAPI= async (snopId,url,snopStartDate, snopEndDate) => {
  return axios.post(
    `${url}?tenantId=${tenantId}&buId=${buId}&snopId=${snopId}&snopStartDate=${snopStartDate}&snopEndDate=${snopEndDate}`,
    null,
   
    config
  );
}

//Promo Master Add Edit API

export const getEditPromoMasterHeaderAPI = async (body) => {
  return axios.put(
    `${EDM_API_ENDPOINT}/entities-transactions/promomaster/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}
export const postAddPromoMasterHeaderAPI = async (body) => {
  return axios.post(
    `${EDM_API_ENDPOINT}/entities-transactions/promomaster/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}

//Promo Transaction Add Edit API

export const getEditPromoTransactionHeaderAPI = async (body) => {
  return axios.put(
    `${EDM_API_ENDPOINT}/entities-transactions/promo/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}
export const postAddPromoTransactionHeaderAPI = async (body) => {
  return axios.post(
    `${EDM_API_ENDPOINT}/entities-transactions/promo/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}


//Mapping  Add Edit API

export const getEditMappingAPI = async (body) => {
  return axios.put(
    `${EDM_API_ENDPOINT}/entities-transactions/mapping/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}
export const postAddMappingAPI = async (body) => {
  return axios.post(
    `${EDM_API_ENDPOINT}/entities-transactions/mapping/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}

// RM Network API
export const getAllRMNetworksAPI = async (body) => {
  let { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
   

  const encodedSearchValue = encodeURIComponent(searchValue);

  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_RM_NETWORK}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_RM_NETWORK}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadRMNetworkCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_RM_NETWORK}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

//Personnel  Add Edit API

export const getEditPersonnelAPI = async (body) => {
  return axios.put(
    `${EDM_API_ENDPOINT}/entities-transactions/personnel/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}
export const postAddPersonnelAPI = async (body) => {
  return axios.post(
    `${EDM_API_ENDPOINT}/entities-transactions/personnel/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}
//Product Api's

export const getEditProductHeaderAPI = async (body) => {
  return axios.put(
    `${EDM_API_ENDPOINT}/entities-transactions/product/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}
export const postAddProductHeaderAPI = async (body) => {
  return axios.post(
    `${EDM_API_ENDPOINT}/entities-transactions/product/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}
export const getAllPromoNamesAPI = async () => {
  return axios.get(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PROMO_MASTER}/getPromoNames?tenant_id=${tenantId}&bu_id=${buId}`,
    config
  );
}
export const getAllLikeSkuAPI = async (body) => {
  let urlParams = [];

  // Iterate over the properties of the body object
  for (const key in body) {
    if (body.hasOwnProperty(key)) {
      // Encode the key and value and push it to the urlParams array
      urlParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`);
    }
  }
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PRODUCT}/getLikeSkus?tenant_id=${tenantId}&bu_id=${buId}&${urlParams.join('&')}`,
      config
    );
  
};
export const getLikeSkuSearchAdvanceFilterAPI = async (body) => {
  let urlParams = [];

  // Iterate over the properties of the body object
  for (const key in body) {
    if (body.hasOwnProperty(key)) {
      // Encode the key and value and push it to the urlParams array
      urlParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`);
    }
  }
  // Constructing the URL dynamically using template literals
  const url = `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PRODUCT}/getLikeSkus?tenant_id=${tenantId}&bu_id=${buId}&${urlParams.join('&')}&enable_pattern_match=true`;

  return axios.get(url, config);
  
};
export const getAllProductsAPI = async (body) => {
  let {
    pageIndex,
    pageSize,
    sortFieldStringWithASCOrDESC,
    searchValue,
    advanceSearchValue,
    buttonStatus,
  } = body;
  if (sortFieldStringWithASCOrDESC === "weight_uom ASC") {
    sortFieldStringWithASCOrDESC = "weight_unit_of_measure ASC";
  }
  if (sortFieldStringWithASCOrDESC === "weight_uom DESC") {
    sortFieldStringWithASCOrDESC = "weight_unit_of_measure DESC";
  }
  if (sortFieldStringWithASCOrDESC === "volume_uom ASC") {
    sortFieldStringWithASCOrDESC = " volume_unit_of_measure ASC";
  }
  if (sortFieldStringWithASCOrDESC === "volume_uom DESC") {
    sortFieldStringWithASCOrDESC = " volume_unit_of_measure DESC";
  }
  if (sortFieldStringWithASCOrDESC === "unit_price - sales_price ASC") {
    sortFieldStringWithASCOrDESC = "unit_price_sp ASC";
  }
  if (sortFieldStringWithASCOrDESC === "unit_price - sales_price DESC") {
    sortFieldStringWithASCOrDESC = "unit_price_sp DESC";
  }
  if (sortFieldStringWithASCOrDESC === "unit_price - net_sales_rate ASC") {
    sortFieldStringWithASCOrDESC = "unit_price_nsr ASC";
  }
  if (sortFieldStringWithASCOrDESC === "unit_price - net_sales_rate DESC") {
    sortFieldStringWithASCOrDESC = "unit_price_nsr DESC";
  }
  if (sortFieldStringWithASCOrDESC === "unit_price - cost_of_goods_sold ASC") {
    sortFieldStringWithASCOrDESC = "unit_price_cogs ASC";
  }
  if (sortFieldStringWithASCOrDESC === "unit_price - cost_of_goods_sold DESC") {
    sortFieldStringWithASCOrDESC = "unit_price_cogs DESC";
  }
  if (sortFieldStringWithASCOrDESC === "max_shelf_life(in days) ASC") {
    sortFieldStringWithASCOrDESC = "max_shelf_life ASC";
  }
  if (sortFieldStringWithASCOrDESC === "max_shelf_life(in days) DESC") {
    sortFieldStringWithASCOrDESC = "max_shelf_life DESC";
  }
  if (sortFieldStringWithASCOrDESC === "min_shelf_life(in days) ASC") {
    sortFieldStringWithASCOrDESC = "min_shelf_life ASC";
  }
  if (sortFieldStringWithASCOrDESC === "min_shelf_life(in days) DESC") {
    sortFieldStringWithASCOrDESC = "min_shelf_life DESC";
  }
  if (sortFieldStringWithASCOrDESC === "productHierarchyLevel5 ASC") {
    sortFieldStringWithASCOrDESC = "productHierarchyLevel2 ASC";
  }
  if (sortFieldStringWithASCOrDESC === "productHierarchyLevel5 DESC") {
    sortFieldStringWithASCOrDESC = "productHierarchyLevel2 DESC";
  }
  if (
    sortFieldStringWithASCOrDESC === "descriptionproductHierarchyLevel5 ASC"
  ) {
    sortFieldStringWithASCOrDESC = "descriptionproductHierarchyLevel2 ASC";
  }
  if (
    sortFieldStringWithASCOrDESC === "descriptionproductHierarchyLevel5 DESC"
  ) {
    sortFieldStringWithASCOrDESC = "descriptionproductHierarchyLevel2 DESC";
  }
  if (sortFieldStringWithASCOrDESC === "productHierarchyLevel7 ASC") {
    sortFieldStringWithASCOrDESC = "productHierarchyLevel3 ASC";
  }
  if (sortFieldStringWithASCOrDESC === "productHierarchyLevel7 DESC") {
    sortFieldStringWithASCOrDESC = "productHierarchyLevel3 DESC";
  }
  if (sortFieldStringWithASCOrDESC === "productHierarchyLevel6 ASC") {
    sortFieldStringWithASCOrDESC = "productHierarchyLevel3 ASC";
  }
  if (sortFieldStringWithASCOrDESC === "productHierarchyLevel6 DESC") {
    sortFieldStringWithASCOrDESC = "productHierarchyLevel3 DESC";
  }
  if (
    sortFieldStringWithASCOrDESC === "descriptionproductHierarchyLevel6 ASC"
  ) {
    sortFieldStringWithASCOrDESC = "descriptionproductHierarchyLevel3 ASC";
  }
  if (
    sortFieldStringWithASCOrDESC === "descriptionproductHierarchyLevel6 DESC"
  ) {
    sortFieldStringWithASCOrDESC = "descriptionproductHierarchyLevel3 DESC";
  }
  if (
    sortFieldStringWithASCOrDESC === "descriptionproductHierarchyLevel7 ASC"
  ) {
    sortFieldStringWithASCOrDESC = "descriptionproductHierarchyLevel3 ASC";
  }
  if (
    sortFieldStringWithASCOrDESC === "descriptionproductHierarchyLevel7 DESC"
  ) {
    sortFieldStringWithASCOrDESC = "descriptionproductHierarchyLevel3 DESC";
  }
  const encodedSearchValue = encodeURIComponent(searchValue);
  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PRODUCT}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PRODUCT}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadCSVDataAPI = async (body) => {
  const { csvFile } = body;

  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PRODUCT}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

//Personnel Api's
export const getAllPersonnelAPI = async (body) => {
  const {
    pageIndex,
    pageSize,
    sortFieldStringWithASCOrDESC,
    searchValue,
    tenantId,
    advanceSearchValue,
    buttonStatus
  } = body;

  const encodedSearchValue = encodeURIComponent(searchValue);

  let newbody = JSON.stringify(body.advanceSearchValue);
  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PERSONNEL}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PERSONNEL}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadCSVDataPersonnelAPI = async (body) => {
  const { csvFile, tenantId } = body;
  const uploadData = new FormData();

  uploadData.append("csvFile", csvFile);

  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PERSONNEL}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

//Currency Api's
export const getAllCurrencyAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,advanceSearchValue,
    buttonStatus } =
    body;
  const encodedSearchValue = encodeURIComponent(searchValue);

  let newbody = JSON.stringify(body.advanceSearchValue);
  if (buttonStatus) {
    return axios.post(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_CURRENCY}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      newbody,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_CURRENCY}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadCSVDataCurrencyAPI = async (body) => {
  const { csvFile, tenantId } = body;
  const uploadData = new FormData();

  uploadData.append("csvFile", csvFile);

  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_CURRENCY}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// location Api
export const getEditLocationHeaderAPI = async (body) => {
  return axios.put(
    `${EDM_API_ENDPOINT}/entities-transactions/location/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}
export const postAddLocationHeaderAPI = async (body) => {
  return axios.post(
    `${EDM_API_ENDPOINT}/entities-transactions/location/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}
export const getAllLocationAPI = async (body) => {
  let { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } = body;
  if (sortFieldStringWithASCOrDESC === "warehouse_area_capacity_UoM ASC") {
    sortFieldStringWithASCOrDESC = "storage_capacity_area_uom ASC";
  }
  if (sortFieldStringWithASCOrDESC === "warehouse_area_capacity_UoM DESC") {
    sortFieldStringWithASCOrDESC = "storage_capacity_area_uom DESC";
  }
  if (sortFieldStringWithASCOrDESC === "warehouse_area_capacity ASC") {
    sortFieldStringWithASCOrDESC = "storage_capacity_area ASC";
  }
  if (sortFieldStringWithASCOrDESC === "warehouse_area_capacity DESC") {
    sortFieldStringWithASCOrDESC = "storage_capacity_area DESC";
  }
  if (sortFieldStringWithASCOrDESC === "warehouse_capacity_UoM ASC") {
    sortFieldStringWithASCOrDESC = "capacity_warehouse_UOM ASC";
  }
  if (sortFieldStringWithASCOrDESC === "warehouse_capacity_UoM DESC") {
    sortFieldStringWithASCOrDESC = "capacity_warehouse_UOM DESC";
  }
  if (sortFieldStringWithASCOrDESC === "warehouse_volume_capacity ASC") {
    sortFieldStringWithASCOrDESC = "warehouse_volume_capacity_value ASC";
  }
  if (sortFieldStringWithASCOrDESC === "warehouse_volume_capacity DESC") {
    sortFieldStringWithASCOrDESC = "warehouse_volume_capacity_value DESC";
  }
  if (sortFieldStringWithASCOrDESC === "warehousing_capacity ASC") {
    sortFieldStringWithASCOrDESC = "capacity_warehouse_unit ASC";
  }
  if (sortFieldStringWithASCOrDESC === "warehousing_capacity DESC") {
    sortFieldStringWithASCOrDESC = "capacity_warehouse_unit DESC";
  }
  if (sortFieldStringWithASCOrDESC === "node_activity ASC") {
    sortFieldStringWithASCOrDESC = "activity ASC";
  }
  if (sortFieldStringWithASCOrDESC === "node_activity DESC") {
    sortFieldStringWithASCOrDESC = "activity DESC";
  }
  if (sortFieldStringWithASCOrDESC === "address_code ASC") {
    sortFieldStringWithASCOrDESC = "address_id ASC";
  }
  if (sortFieldStringWithASCOrDESC === "address_code DESC") {
    sortFieldStringWithASCOrDESC = "address_id DESC";
  }
  const encodedSearchValue = encodeURIComponent(searchValue);

  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_LOCATION}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_LOCATION}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadLocationCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_LOCATION}?tenant_id=${tenantId}&bu_id=${buId}`,
    
    uploadData,
    config,
    uploadConfig
  );
};


// promo master transaction api
export const getAllPromoMasterAPI = async (body) => {
  let { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
 
 

  const encodedSearchValue = encodeURIComponent(searchValue);

  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PROMO_MASTER}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PROMO_MASTER}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadPromoMasterCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PROMO_MASTER}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};
// promo transaction api
export const getAllPromoAPI = async (body) => {
  let { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
 
 

  const encodedSearchValue = encodeURIComponent(searchValue);

  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PROMO}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PROMO}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadPromoCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PROMO}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};
// Network API
export const getAllNetworksAPI = async (body) => {
  let { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
    if (sortFieldStringWithASCOrDESC === "shipping_type ASC") {
      sortFieldStringWithASCOrDESC = "is_normal ASC";
    }
    if (sortFieldStringWithASCOrDESC === "shipping_type DESC") {
      sortFieldStringWithASCOrDESC = "is_normal DESC";
    }
    if (sortFieldStringWithASCOrDESC === "shipper_code ASC") {
      sortFieldStringWithASCOrDESC = "carrier ASC";
    }
    if (sortFieldStringWithASCOrDESC === "shipper_code DESC") {
      sortFieldStringWithASCOrDESC = "carrier DESC";
    }
    if (sortFieldStringWithASCOrDESC === "transporter_code ASC") {
      sortFieldStringWithASCOrDESC = "transporter ASC";
    }
    if (sortFieldStringWithASCOrDESC === "transporter_code DESC") {
      sortFieldStringWithASCOrDESC = "transporter DESC";
    }
    if (sortFieldStringWithASCOrDESC === "order_cycle_time_UOM ASC") {
      sortFieldStringWithASCOrDESC = "cycle_time_UOM ASC";
    }
    if (sortFieldStringWithASCOrDESC === "order_cycle_time_UOM DESC") {
      sortFieldStringWithASCOrDESC = "cycle_time_UOM DESC";
    }
    if (sortFieldStringWithASCOrDESC === "order_cycle_time ASC") {
      sortFieldStringWithASCOrDESC = "cycle_time_unit ASC";
    }
    if (sortFieldStringWithASCOrDESC === "order_cycle_time DESC") {
      sortFieldStringWithASCOrDESC = "cycle_time_unit DESC";
    }
    if (sortFieldStringWithASCOrDESC === "variable_cost ASC") {
      sortFieldStringWithASCOrDESC = "variable_cost_unit ASC";
    }
    if (sortFieldStringWithASCOrDESC === "variable_cost DESC") {
      sortFieldStringWithASCOrDESC = "variable_cost_unit DESC";
    }
    if (sortFieldStringWithASCOrDESC === "production_priority ASC") {
      sortFieldStringWithASCOrDESC = "priority ASC";
    }
    if (sortFieldStringWithASCOrDESC === "production_priority DESC") {
      sortFieldStringWithASCOrDESC = "priority DESC";
    }
    if (sortFieldStringWithASCOrDESC === "item_code ASC") {
      sortFieldStringWithASCOrDESC = "sku_code ASC";
    }
    if (sortFieldStringWithASCOrDESC === "item_code DESC") {
      sortFieldStringWithASCOrDESC = "sku_code DESC";
    }

  const encodedSearchValue = encodeURIComponent(searchValue);

  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_NETWORK}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_NETWORK}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadNetworkCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_NETWORK}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// Mapping API
export const getAllMappingsAPI = async (body) => {

     let { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } = body;
  if (sortFieldStringWithASCOrDESC === "manufacturing_cycle_time_UOM ASC") {
    sortFieldStringWithASCOrDESC = "cycle_time_manufacturing_UOM ASC";
  }

  if (sortFieldStringWithASCOrDESC === "manufacturing_cycle_time_UOM DESC") {
    sortFieldStringWithASCOrDESC = "cycle_time_manufacturing_UOM DESC";
  }

  if (sortFieldStringWithASCOrDESC === "manufacturing_cycle_time_per_unit ASC") {
    sortFieldStringWithASCOrDESC = "cycle_time_manufacturing_unit ASC";
  }

  if (sortFieldStringWithASCOrDESC === "manufacturing_cycle_time_per_unit DESC") {
    sortFieldStringWithASCOrDESC = "cycle_time_manufacturing_unit DESC";
  }

  if (sortFieldStringWithASCOrDESC === "manufacturing_cost_UOM ASC") {
    sortFieldStringWithASCOrDESC = "cost_manufacturing_UOM ASC";
  }

  if (sortFieldStringWithASCOrDESC === "manufacturing_cost_UOM DESC") {
    sortFieldStringWithASCOrDESC = "cost_manufacturing_UOM DESC";
  }

  if (sortFieldStringWithASCOrDESC === "manufacturing_cost_per_unit ASC") {
    sortFieldStringWithASCOrDESC = "cost_manufacturing_unit ASC";
  }

  if (sortFieldStringWithASCOrDESC === "manufacturing_cost_per_unit DESC") {
    sortFieldStringWithASCOrDESC = "cost_manufacturing_unit DESC";
  }

  if (sortFieldStringWithASCOrDESC === "warehousing_cycle_time_UOM ASC") {
    sortFieldStringWithASCOrDESC = "cycle_time_warehouse_UOM ASC";
  }

  if (sortFieldStringWithASCOrDESC === "warehousing_cycle_time_UOM DESC") {
    sortFieldStringWithASCOrDESC = "cycle_time_warehouse_UOM DESC";
  }

  if (sortFieldStringWithASCOrDESC === "warehousing_cycle_time_per_unit ASC") {
    sortFieldStringWithASCOrDESC = "cycle_time_warehouse_unit ASC";
  }

  if (sortFieldStringWithASCOrDESC === "warehousing_cycle_time_per_unit DESC") {
    sortFieldStringWithASCOrDESC = "cycle_time_warehouse_unit DESC";
  }

  if (sortFieldStringWithASCOrDESC === "warehousing_cost_UOM ASC") {
    sortFieldStringWithASCOrDESC = "cost_warehouse_UOM ASC";
  }

  if (sortFieldStringWithASCOrDESC === "warehousing_cost_UOM DESC") {
    sortFieldStringWithASCOrDESC = "cost_warehouse_UOM DESC";
  }

  if (sortFieldStringWithASCOrDESC === "warehousing_cost_per_unit ASC") {
    sortFieldStringWithASCOrDESC = "cost_warehouse_unit ASC";
  }

  if (sortFieldStringWithASCOrDESC === "warehousing_cost_per_unit DESC") {
    sortFieldStringWithASCOrDESC = "cost_warehouse_unit DESC";
  }

  if (sortFieldStringWithASCOrDESC === "days_of_supply ASC") {
    sortFieldStringWithASCOrDESC = "dos ASC";
  }

  if (sortFieldStringWithASCOrDESC === "days_of_supply DESC") {
    sortFieldStringWithASCOrDESC = "dos DESC";
  }

  if (sortFieldStringWithASCOrDESC === "stock_in_transit_quantity ASC") {
    sortFieldStringWithASCOrDESC = "stock_in_transit ASC";
  }

  if (sortFieldStringWithASCOrDESC === "stock_in_transit_quantity DESC") {
    sortFieldStringWithASCOrDESC = "stock_in_transit DESC";
  }

  if (sortFieldStringWithASCOrDESC === "stock_in_pbns_quantity ASC") {
    sortFieldStringWithASCOrDESC = "picked_but_not_shipped ASC";
  }

  if (sortFieldStringWithASCOrDESC === "stock_in_pbns_quantity DESC") {
    sortFieldStringWithASCOrDESC = "picked_but_not_shipped DESC";
  }

  if (sortFieldStringWithASCOrDESC === "purchase_priority ASC") {
    sortFieldStringWithASCOrDESC = "priority ASC";
  }

  if (sortFieldStringWithASCOrDESC === "purchase_priority DESC") {
    sortFieldStringWithASCOrDESC = "priority DESC";
  }

  if (sortFieldStringWithASCOrDESC === "warehouse_volume_capacity_UOM ASC") {
    sortFieldStringWithASCOrDESC = "storage_capacity_volume_UOM ASC";
  }

  if (sortFieldStringWithASCOrDESC === "warehouse_volume_capacity_UOM DESC") {
    sortFieldStringWithASCOrDESC = "storage_capacity_volume_UOM DESC";
  }

  if (sortFieldStringWithASCOrDESC === "warehouse_area_capacity_UOM ASC") {
    sortFieldStringWithASCOrDESC = "storage_capacity_area_UOM ASC";
  }

  if (sortFieldStringWithASCOrDESC === "warehouse_area_capacity_UOM DESC") {
    sortFieldStringWithASCOrDESC = "storage_capacity_area_UOM DESC";
  }

  if (sortFieldStringWithASCOrDESC === "warehouse_volume_capacity ASC") {
    sortFieldStringWithASCOrDESC = "storage_capacity_volume ASC";
  }

  if (sortFieldStringWithASCOrDESC === "warehouse_volume_capacity DESC") {
    sortFieldStringWithASCOrDESC = "storage_capacity_volume DESC";
  }
  
  if (sortFieldStringWithASCOrDESC === "warehouse_area_capacity ASC") {
    sortFieldStringWithASCOrDESC = "storage_capacity_area ASC";
  }
  
  if (sortFieldStringWithASCOrDESC === "warehouse_area_capacity DESC") {
    sortFieldStringWithASCOrDESC = "storage_capacity_area DESC";
  }
  
  if (sortFieldStringWithASCOrDESC === "manufacturing_shift_days ASC") {
    sortFieldStringWithASCOrDESC = "manufacturing_shift_day ASC";
  }
  
  if (sortFieldStringWithASCOrDESC === "manufacturing_shift_days DESC") {
    sortFieldStringWithASCOrDESC = "manufacturing_shift_day DESC";
  }
  
  if (sortFieldStringWithASCOrDESC === "max_stock_level_quantity ASC") {
    sortFieldStringWithASCOrDESC = "msl_max ASC";
  }
  
  if (sortFieldStringWithASCOrDESC === "max_stock_level_quantity DESC") {
    sortFieldStringWithASCOrDESC = "msl_max DESC";
  }
  
  if (sortFieldStringWithASCOrDESC === "min_stock_level_quantity ASC") {
    sortFieldStringWithASCOrDESC = "msl_min ASC";
  }
  
  if (sortFieldStringWithASCOrDESC === "min_stock_level_quantity DESC") {
    sortFieldStringWithASCOrDESC = "msl_min DESC";
  }
  
  if (sortFieldStringWithASCOrDESC === "class/segment_I ASC") {
    sortFieldStringWithASCOrDESC = "segment1 ASC";
  }
  
  if (sortFieldStringWithASCOrDESC === "class/segment_I DESC") {
    sortFieldStringWithASCOrDESC = "segment1 DESC";
  }
  
  if (sortFieldStringWithASCOrDESC === "class/segment_II ASC") {
    sortFieldStringWithASCOrDESC = "segment2 ASC";
  }
  
  if (sortFieldStringWithASCOrDESC === "class/segment_II DESC") {
    sortFieldStringWithASCOrDESC = "segment2 DESC";
  }
  
  if (sortFieldStringWithASCOrDESC === "class/segment_III ASC") {
    sortFieldStringWithASCOrDESC = "segment3 ASC";
  }
  
  if (sortFieldStringWithASCOrDESC === "class/segment_III DESC") {
    sortFieldStringWithASCOrDESC = "segment3 DESC";
  }
  
  if (sortFieldStringWithASCOrDESC === "unit_price_COGS ASC") {
    sortFieldStringWithASCOrDESC = "cogs_unit_price ASC";
  }
  
  if (sortFieldStringWithASCOrDESC === "unit_price_COGS DESC") {
    sortFieldStringWithASCOrDESC = "cogs_unit_price DESC";
  }
  
  if (sortFieldStringWithASCOrDESC === "unit_price_SP/NSR ASC") {
    sortFieldStringWithASCOrDESC = "nsr_unit_price ASC";
  }
  
  if (sortFieldStringWithASCOrDESC === "unit_price_SP/NSR DESC") {
    sortFieldStringWithASCOrDESC = "nsr_unit_price DESC";
  }
  
  if (sortFieldStringWithASCOrDESC === "unit_price_other ASC") {
    sortFieldStringWithASCOrDESC = "other_unit_price ASC";
  }
  
  if (sortFieldStringWithASCOrDESC === "unit_price_other DESC") {
    sortFieldStringWithASCOrDESC = "other_unit_price DESC";
  }
  
  if (sortFieldStringWithASCOrDESC === "unit_price_manufacturing_cost ASC") {
    sortFieldStringWithASCOrDESC = "manufacturing_cost_unit_price ASC";
  }
  
  if (sortFieldStringWithASCOrDESC === "unit_price_manufacturing_cost DESC") {
    sortFieldStringWithASCOrDESC = "manufacturing_cost_unit_price DESC";
  }
  
  if (sortFieldStringWithASCOrDESC === "unit_manufacturing_lead_time ASC") {
    sortFieldStringWithASCOrDESC = "manufacturing_lead_time_unit ASC";
  }
  
  if (sortFieldStringWithASCOrDESC === "unit_manufacturing_lead_time DESC") {
    sortFieldStringWithASCOrDESC = "manufacturing_lead_time_unit DESC";
  }
  
  if (sortFieldStringWithASCOrDESC === "unit_price_-_purchase_price ASC") {
    sortFieldStringWithASCOrDESC = "unit_price_purchase_price ASC";
  }
  
  if (sortFieldStringWithASCOrDESC === "unit_price_-_purchase_price DESC") {
    sortFieldStringWithASCOrDESC = "unit_price_purchase_price DESC";
  }
  const encodedSearchValue = encodeURIComponent(searchValue);

  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_MAPPING}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_MAPPING}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadMappingCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_MAPPING}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// Split Master
export const getAllSplitMasterAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
  const encodedSearchValue = encodeURIComponent(searchValue);

  let newbody = JSON.stringify(body.advanceSearchValue);
  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SPLIT_MASTER}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SPLIT_MASTER}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadSplitMasterCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SPLIT_MASTER}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// Route Master API

export const getAllRouteMasterAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
  const encodedSearchValue = encodeURIComponent(searchValue);

  let newbody = JSON.stringify(body.advanceSearchValue);
  if (buttonStatus) {
    return axios.post(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_ROUTE_MASTER}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      newbody,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_ROUTE_MASTER}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadRouteMasterCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_ROUTE_MASTER}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};



// SNP_PERSONNEL API

export const getAllSNPPersonnelAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
  const encodedSearchValue = encodeURIComponent(searchValue);

  let newbody = JSON.stringify(body.advanceSearchValue);
  if (buttonStatus) {
    return axios.post(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SNP_PERSONNEL}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      newbody,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SNP_PERSONNEL}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadSNPPersonnelCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SNP_PERSONNEL}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// Actual Sales History API
export const getAllActualSalesHistoryAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
  const encodedSearchValue = encodeURIComponent(searchValue);

  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SALES_HISTORY}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SALES_HISTORY}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadActualSalesHistoryCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SALES_HISTORY}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// SalesOrders API
export const getAllSalesOrderAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
  const encodedSearchValue = encodeURIComponent(searchValue);

  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SALES_ORDERS}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SALES_ORDERS}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadSalesOrderCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SALES_ORDERS}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// goods in transit api
export const getAllGoodsIntransitAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
  const encodedSearchValue = encodeURIComponent(searchValue);

  let newbody = JSON.stringify(body.advanceSearchValue);
  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_GOODSINTRANSIT}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_GOODSINTRANSIT}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadGoodsIntransitCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_GOODSINTRANSIT}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// goods in hand api
export const getAllGoodsInHandAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
  const encodedSearchValue = encodeURIComponent(searchValue);

  let newbody = JSON.stringify(body.advanceSearchValue);
  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_GOODSINHAND}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_GOODSINHAND}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadGoodsInHandCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_GOODSINHAND}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// goods in pnbs api
export const getAllGoodsInPNBSAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
  const encodedSearchValue = encodeURIComponent(searchValue);

  let newbody = JSON.stringify(body.advanceSearchValue);
  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_GOODSINPNBS}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
    
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_GOODSINPNBS}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadGoodsInPNBSCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_GOODSINPNBS}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

//STO API
export const getAllStockTransferOrderAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
    const encodedSearchValue = encodeURIComponent(searchValue);

    let newbody = JSON.stringify(body.advanceSearchValue);
    if (advanceSearchValue && advanceSearchValue.length) {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_STOCKTRANSFERORDER}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
        
        config
      );
    } else {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_STOCKTRANSFERORDER}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        config
      );
    }
};
export const uploadStockTransferOrderCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_STOCKTRANSFERORDER}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// MARKETING EVENTS API
export const getAllMarketingEventsAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
    const encodedSearchValue = encodeURIComponent(searchValue);
    let newbody = JSON.stringify(body.advanceSearchValue);
    if (buttonStatus) {
      return axios.post(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_MARKETING_EVENTS}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        newbody,
        config
      );
    } else {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_MARKETING_EVENTS}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        config
      );
    }
};
export const uploadMarketingEventsCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_MARKETING_EVENTS}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// PROMOTION TYPE API
export const getAllPromotionTypeAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
    const encodedSearchValue = encodeURIComponent(searchValue);
    let newbody = JSON.stringify(body.advanceSearchValue);
    if (buttonStatus) {
      return axios.post(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PROMOTION_TYPE}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        newbody,
        config
      );
    } else {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PROMOTION_TYPE}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        config
      );
    }
};
export const uploadPromotionTypeCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PROMOTION_TYPE}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// ROW MATERIAL API
export const getAllRowMaterialAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
  const encodedSearchValue = encodeURIComponent(searchValue);

  let newbody = JSON.stringify(body.advanceSearchValue);
  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_ROW_MATERIAL}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_ROW_MATERIAL}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadRowMaterialCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_ROW_MATERIAL}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// PO Events
export const getAllpoEventsAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
  const encodedSearchValue = encodeURIComponent(searchValue);
  let newbody = JSON.stringify(body.advanceSearchValue);
  if (buttonStatus) {
    return axios.post(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_POEVENTS}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      newbody,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_POEVENTS}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadpoEventsCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_POEVENTS}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// purchase order
export const getAllpurchaseorderEventsAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
  const encodedSearchValue = encodeURIComponent(searchValue);

  let newbody = JSON.stringify(body.advanceSearchValue);
  if (advanceSearchValue && advanceSearchValue.length) {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PURCHASEORDER}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
      
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PURCHASEORDER}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};

export const uploadpurchaseorderEventsCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PURCHASEORDER}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// production work order api

export const getAllProductionWorkOrderAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
  const encodedSearchValue = encodeURIComponent(searchValue);

    if (advanceSearchValue && advanceSearchValue.length) {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PRODUCTION_WORK_ORDER}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
        config
      );
    } else {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PRODUCTION_WORK_ORDER}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        config
      );
    }
};
export const uploadProductionWorkOrderCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PRODUCTION_WORK_ORDER}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// bills of materials api
export const getAllBillOfMaterialsAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
  const encodedSearchValue = encodeURIComponent(searchValue);

  let newbody = JSON.stringify(body.advanceSearchValue);
  if (buttonStatus) {
    return axios.post(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_BILL_OF_MATERIALS}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      newbody,
      config
    );
  } else {
    return axios.get(
      `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_BILL_OF_MATERIALS}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
      config
    );
  }
};
export const uploadBillOfMaterialsCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_BILL_OF_MATERIALS}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// RM Node Mapping
export const getAllRMNodeMappingAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
    const encodedSearchValue = encodeURIComponent(searchValue);
    let newbody = JSON.stringify(body.advanceSearchValue);
    if (advanceSearchValue && advanceSearchValue.length) {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_RM_NODE_MAPPING}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
        
        config
      );
    } else {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_RM_NODE_MAPPING}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        config
      );
    }
};
export const uploadRMNodeMappingCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_RM_NODE_MAPPING}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// Shipment
export const getAllShipmentAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
    const encodedSearchValue = encodeURIComponent(searchValue);
    let newbody = JSON.stringify(body.advanceSearchValue);
    if (buttonStatus) {
      return axios.post(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SHIPMENT}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        newbody,
        config
      );
    } else {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SHIPMENT}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        config
      );
    }
};
export const uploadShipmentCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_SHIPMENT}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// Inventory Events
export const getAllInventoryEventsAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
    const encodedSearchValue = encodeURIComponent(searchValue);
    let newbody = JSON.stringify(body.advanceSearchValue);
    if (buttonStatus) {
      return axios.post(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_INVENTORY_EVENTS}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        newbody,
        config
      );
    } else {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_INVENTORY_EVENTS}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        config
      );
    }
};
export const uploadInventoryEventsCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_INVENTORY_EVENTS}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// sto events
export const getAllSTOEventsAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
    const encodedSearchValue = encodeURIComponent(searchValue);
    let newbody = JSON.stringify(body.advanceSearchValue);
    if (buttonStatus) {
      return axios.post(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_STO_EVENTS}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        newbody,
        config
      );
    } else {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_STO_EVENTS}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        config
      );
    }
};
export const uploadSTOEventsCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_STO_EVENTS}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// budget



export const getEditBudgetHeaderAPI = async (body) => {
  return axios.put(
    `${EDM_API_ENDPOINT}/entities-transactions/budget/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}
export const postAddBudgetHeaderAPI = async (body) => {
  return axios.post(
    `${EDM_API_ENDPOINT}/entities-transactions/budget/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}
export const getAllBudgetAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
    const encodedSearchValue = encodeURIComponent(searchValue);
    if (advanceSearchValue && advanceSearchValue.length) {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_STO_BUDGET}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
        config
      );
    } else {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_STO_BUDGET}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        config
      );
    }
};
export const uploadBudgetCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_STO_BUDGET}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// RMPO
export const getAllRMPOAPI = async (body) => {
  const { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } =
    body;
    const encodedSearchValue = encodeURIComponent(searchValue);
    let newbody = JSON.stringify(body.advanceSearchValue);
    if (buttonStatus) {
      return axios.post(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_RMPO}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        newbody,
        config
      );
    } else {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_RMPO}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        config
      );
    }
};
export const uploadRMPOCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_RMPO}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// feature API
export const getHeaderAPI = async (entity) => {
  return axios.get(
    `${EDM_API_ENDPOINT}/entities/${entity}/v2/getStaticHeaders?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    config
  );
}
export const getEditFeatureHeaderAPI = async (body) => {
  return axios.put(
    `${EDM_API_ENDPOINT}/entities-transactions/features/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}
export const postAddFeatureHeaderAPI = async (body) => {
  return axios.post(
    `${EDM_API_ENDPOINT}/entities-transactions/features/v2?tenant_id=${tenantId}&bu_id=${buId}&api_version=2`,
    body,
    config
  );
}
export const getAllFeatureAPI = async (body) => {
  let { pageIndex, pageSize, sortFieldStringWithASCOrDESC, searchValue,buttonStatus,
    advanceSearchValue, } = body;
  if (sortFieldStringWithASCOrDESC === "period ASC") {
    sortFieldStringWithASCOrDESC = "date ASC";
  }
  if (sortFieldStringWithASCOrDESC === "period DESC") {
    sortFieldStringWithASCOrDESC = "date DESC";
  }
  const encodedSearchValue = encodeURIComponent(searchValue);

    if (advanceSearchValue && advanceSearchValue.length) {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_FEATURES}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&${advanceSearchValue}`,
        config
      );
    } else {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_FEATURES}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}`,
        config
      );
    }
};
export const uploadFeatureCSVDataAPI = async ({ csvFile }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_FEATURES}?tenant_id=${tenantId}&bu_id=${buId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// Forecast API
export const getAllForecastSummaryAPI = async (body) => {
  const {
    pageIndex,
    pageSize,
    sortFieldStringWithASCOrDESC,
    searchValue,
    snopId,
    buttonStatus,
    advanceSearchValue,
  } = body;
  const encodedSearchValue = encodeURIComponent(searchValue);

    if (advanceSearchValue && advanceSearchValue.length) {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_FORECAST}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&snop_id=${snopId}&${advanceSearchValue}`,
        config
      );
    } else {
      return axios.get(
        `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_FORECAST}?pageIndex=${pageIndex}&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${encodedSearchValue}&tenant_id=${tenantId}&bu_id=${buId}&snop_id=${snopId}`,
        config
      );
    }
};
export const uploadForecastSummaryCSVDataAPI = async ({ csvFile, snopId }) => {
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
  const uploadConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return await axios.post(
    `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_FORECAST}?tenant_id=${tenantId}&bu_id=${buId}&snop_id=${snopId}`,
    uploadData,
    config,
    uploadConfig
  );
};

// Snop list API
export const getAllSnopListApi = async () => {
  return await axios.get(
    `${SNOP_API_ENDPOINT}/snop?bu_id=${buId}&tenant_id=${tenantId}`,
    config
  );
};

export const downloadCsvApi = ({ url, fileName, entityName }) => {
  let anchor = document.createElement("a");
  document.body.appendChild(anchor);
 
  const toastInfoId=toast.info('Download in progress', {
    autoClose: false, 
    closeButton:false
  });
  
  axios
    .get(url, {
      ...config,
    })
    .then((response) => {
      if (response.headers["content-type"] === "application/csv") {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const toastInfo = toast.success('Download complete!', {
            autoClose: false,
            closeOnClick: false,
            draggable: false, 
          onClose: () => {
            toast.dismiss(toastInfoId);
          },
        });


        const link = document.createElement("a");
        link.href = url;
        link.download = fileName ?? "data1.csv";
        link.click();
      }
    })
    .catch((error) => {
      if(error?.response?.data?.responseData?.errorCode==="400"){
        toast.error(error?.response?.data?.responseData?.message, {
          autoClose: false,
            closeOnClick: false,
            draggable: false,
            
        });    

      }
      if (error.response.status === 401) {
        toast.error("Unable to Proceed Due to Authentication", {
          autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
        toast.dismiss(toastInfoId);
      } else {
        toast.error(error?.message, {
          autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
        toast.dismiss(toastInfoId);
      }
    });
};
export const downloadErrorCsvApi = ({ fileName, url }) => {
  let anchor = document.createElement("a");
  document.body.appendChild(anchor);
  axios
    .get(url, {
      responseType: "blob",
      ...config,
    })
    .then((response) => response)
    .then((blobby) => {
      let objectUrl = window.URL.createObjectURL(blobby?.data);
      anchor.href = objectUrl;
      anchor.download = fileName ?? "download.csv";
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    })
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error("Unable to Proceed Due to Authentication", {
          autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      } else {
        toast.error(error?.message, {
          autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      }
    });
};

export const getAllSyncStatusAPI = async (body) => {
  let {
    pageIndex,
    pageSize,
    sortFieldStringWithASCOrDESC,
    searchValue,
    Route_type,
    Start_Date,
    End_Date,
  } = body;
  if(body.Route_type == "dtrmevents"){
    Route_type =  "poevents"
  }
   
  let url;
  if (Start_Date && End_Date) {
    url = `${EDM_API_ENDPOINT}/entities/${Route_type}/v2/uploadHistoryGetAll?pageIndex=${
      pageIndex - 1
    }&pageSize=${pageSize}&dateFrom=${Start_Date}&dateTo=${End_Date}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${searchValue}&bu_id=${buId}&tenant_id=${tenantId}`;
  } else {
    url = `${EDM_API_ENDPOINT}/entities/${Route_type}/v2/uploadHistoryGetAll?pageIndex=${
      pageIndex - 1
    }&pageSize=${pageSize}&sortFieldStringWithASCOrDESC=${sortFieldStringWithASCOrDESC}&searchValue=${searchValue}&bu_id=${buId}&tenant_id=${tenantId}`;
  }
  return axios.get(url, config);
};
