import React, { useState, useEffect } from "react";
import "../../../../style/sass/main.scss";
import TableGrid from "../../../../components/tableGrid";
import TableToolbar from "../../../../components/tableGrid/toolbar";
import "@progress/kendo-theme-default/dist/all.css";
import { get } from "lodash";
import { filesize } from "filesize";

import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_MAPPINGS,
  UPLOAD_MAPPING_CSV_DATA,
} from "../../../../store/types";
import { useTranslation } from "react-i18next";
import { SHOW_ROWS } from "../../../../constant/constant";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Breadcrumb from "../../../../components/breadcrumb";
import UploadDialog from "../../../../components/uploadDialog";
import { downloadCsvApi } from "../../../../apis";
import { csvFileReader } from "../../../../utils/csvFileReader";
import { entitiesName, permission } from "../../../../utils/Constants";
import moment from "moment";
import { useNumericFormat } from "react-number-format";
import ProcessingLoader from "../../../../components/processingLoader/";
import { use } from "i18next";

const List = (props) => {
  const { t } = useTranslation();
  const localeConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).locale
    : {};
  const EDM_API_ENDPOINT = process.env.REACT_APP_EDM_API_ENDPOINT;
  const SCAI_API_BASEURL = process.env.REACT_APP_SCAI_API_BASEURL_MAPPING;
  const locationConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .locationHierarchy
    : {};
  const productConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .productHierarchy
    : {};
  const user = JSON.parse(localStorage.getItem("user"));
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);
  const addEditPermission = permissions.includes(permission.mappingAddEdit);
  const dispatch = useDispatch();
  const [rowLength, setRowLength] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [errorCSV, setErrorCSV] = useState("");

  const [csvDownloadTableDataRows, setCSVDownloadTableDataRows]=useState("")
 
  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const error = await csvFileReader(file);
      if (!error) {
        setMyFiles([file]);
        setErrorCSV("");
      } else {
        setErrorCSV(error);
        setMyFiles([]);
      }
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const files = myFiles.map((file) => (
    <li key={file.path} className="csv-upload-success-li">
      {file.path} - {file.size} bytes
    </li>
  ));
  useEffect(() => {
    if (visible === false) {
      setMyFiles([]);
      setErrorCSV("");
    }
  }, [visible]);

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `${t("Show")} ${item} ${t("Rows")}`,
    value: item,
  }));

  const body = {
    pageIndex: pageIndex,
    pageSize: rowLength,
    sortFieldStringWithASCOrDESC: sortValue,
    searchValue: searchField,
    productId: "0",
    tenantId: tenantId,
  };

  useEffect(() => {
    if (!refresh) {
      if (csvDownloadTableDataRows === "") {
        dispatch({ type: GET_ALL_MAPPINGS, payload: body });
      } else {
        body.advanceSearchValue = csvDownloadTableDataRows;
        body.buttonStatus = true;
        dispatch({ type: GET_ALL_MAPPINGS, payload: { ...body } });
      }
    }
  }, [dispatch, pageIndex, sortValue, rowLength]);

  const handleSearchSubmit = () => {
    setPageIndex(1);
    dispatch({ type: GET_ALL_MAPPINGS, payload: { ...body, pageIndex: 1 } });
  };

  const handleAdvanceSearch = (rows) => {
    if (
      rows.length === 1 &&
      rows[0].columnName === "Select Value" &&
      rows[0].operator === "Select Type"
    ) {
    } else {
      let result = "";
      rows.forEach((item, index) => {
        const keyIndex = index + 1;
        
        result += `value${keyIndex}=${
          mappingStaticHeader.filter(
            (data) => data.feName === item.columnName
          )[0].alias
        }.${
          mappingStaticHeader.filter(
            (data) => data.feName === item.columnName
          )[0].beName
        },${item.operator.toLowerCase().replace(/\s+/g, "_")},${item.value}`;
        if (index !== rows.length - 1) {
          result += "&";
        }
      });
      setCSVDownloadTableDataRows(result);
      body.advanceSearchValue = result;
      body.buttonStatus = true;
      setPageIndex(1);
      dispatch({ type: GET_ALL_MAPPINGS, payload: { ...body, pageIndex: 1 } });
    }
  };

  const clearAdvanceSearch = () => {
    body.buttonStatus = false;
    setPageIndex(1);
    dispatch({ type: GET_ALL_MAPPINGS, payload: { ...body, pageIndex: 1 } });
  };

  const refreshHandler = () => {
    setRefresh(true);
    setPageIndex(1);
    setSearchField("");
    setSortValue("");
    setRowLength(10);
    const refreshBody = {
      ...body,
      searchValue: "",
      pageIndex: 1,
      pageSize: 10,
      sortFieldStringWithASCOrDESC: "",
    };
    setTimeout(() => {
      dispatch({ type: GET_ALL_MAPPINGS, payload: refreshBody });
      setRefresh(false);
    }, 300);
  };

  const {
    mappingList,
    csvUploadResponseData,
    csvUploadError,
    csvUpload,
    loader,
  } = useSelector((state) => state.MappingReducer);
  const mappingEntities = get(mappingList, "entities", []);
  const totalElement = get(mappingList, "totalElements", "");
  const pageSize = get(mappingList, "pageSize", "");
  const { format } = useNumericFormat({
    decimalSeparator: localeConfiguration["decimalSeparator"],
    thousandsGroupStyle: localeConfiguration["thousandsGroupStyle"],
    thousandSeparator: localeConfiguration["thousandSeparator"],
    decimalScale: 2,
  });
  const [mappingStaticHeader,setMappingStaticHeader]=useState([])
  useEffect(() => {
    const mappingStaticHeaderData = sessionStorage.getItem("mappingStaticHeader");
    if (mappingStaticHeaderData && mappingStaticHeaderData !== "undefined") {
      const parsedMappingStaticHeader = JSON.parse(mappingStaticHeaderData);
      setMappingStaticHeader(parsedMappingStaticHeader);
    }
  }, [mappingList]);
  console.log("mappingStaticHeaderrrrr",mappingStaticHeader)
  const mappingGridColumn=mappingStaticHeader && mappingStaticHeader.length && mappingStaticHeader.filter(col=>col.scope.includes('D'))
  console.log("mappingGridColumnnnn",mappingGridColumn)
  const mappingDetails =
    mappingEntities &&
    mappingEntities.map((item) => {
      const items = { ...item };
      items.mapping_start_date = item.mapping_start_date
        ? moment(item.mapping_start_date).format(
            localeConfiguration["dateFormat"].toUpperCase()
          )
        : "";
      items.mapping_end_date = item.mapping_end_date
        ? moment(item.mapping_end_date).format(
            localeConfiguration["dateFormat"].toUpperCase()
          )
        : "";
      items.mapping_sunset_date = item.mapping_sunset_date
        ? moment(item.mapping_sunset_date).format(
            localeConfiguration["dateFormat"].toUpperCase()
          )
        : "";
      items.mapping_life_cycle_status = item.mapping_life_cycle_status
        ? t("active")
        : t("inactive");

        // items=reorderObjectWithChannelAtFifthPosition(item);

      // items.cycle_time_manufacturing_unit = format(
      //   (
      //     item?.cycle_time_manufacturing_unit &&
      //     item?.cycle_time_manufacturing_unit?.toFixed(2)
      //   )?.toString() ?? "0"
      // );
      // items.cost_manufacturing_unit = format(
      //   (
      //     item?.cost_manufacturing_unit &&
      //     item?.cost_manufacturing_unit.toFixed(2)
      //   )?.toString() ?? "0"
      // );
      items.units_manufactured_full_utilization_day = format(
        (
          item?.units_manufactured_full_utilization_day &&
          item?.units_manufactured_full_utilization_day.toFixed(2)
        )?.toString() ?? "0"
      );
      items.cycle_time_warehouse_unit = format(
        (
          item.cycle_time_warehouse_unit &&
          item.cycle_time_warehouse_unit.toFixed(2)
        )?.toString() ?? "0"
      );
      items.cost_warehouse_unit = format(
        (
          item?.cost_warehouse_unit && item?.cost_warehouse_unit.toFixed(2)
        )?.toString() ?? "0"
      );
      items.node_code = item?.node?.node_code ?? "";
      items.node = item?.node?.node ?? "";
      items.sku_code = item?.sku?.sku_code ?? "";
      items.sku_name = item?.sku?.sku_name ?? "";
      items.channel_name = item?.channel?.channel_code ?? "";
      return items;
    });

  const customColumnHeaders = () => {
    if (mappingDetails && mappingDetails.length > 0) {
      return [
        {
          replaceWith: "sku_code",
          newLabel: productConfiguration
            ? productConfiguration["base"] + " " + t("labelCode")
            : t("sku_code"),
        },
        {
          replaceWith: "sku_name",
          newLabel: productConfiguration
            ? productConfiguration["base"]
            : t("sku_name"),
        },
        {
          replaceWith: "node_code",
          newLabel: locationConfiguration
            ? locationConfiguration["base"] + " " + t("labelCode")
            : t("node_code"),
        },
        {
          replaceWith: "node",
          newLabel: locationConfiguration
            ? locationConfiguration["base"]
            : t("sku_code"),
        },
      ];
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (csvUploadError) {
      setVisible(false);
      toast.error(t(`${csvUploadError.message}`),
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    }
  }, [csvUploadError]);

  useEffect(() => {
    if (csvUpload) {
      setVisible(false);
      if (csvUploadResponseData.responseCode === "FAILED_HEADER_VALIDATION") {
        toast.error(`${csvUploadResponseData.responseMessage}`,
        {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_ROW_FAILURE"
      ) {
        toast.warning(
          `${t(`${csvUploadResponseData.responseCode + "\n"}`)}`,

          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
        toast.warning(
          <a href={`${csvUploadResponseData.errorFileName}`} target="_blank">
            {" "}
            Download Error File{" "}
          </a>,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_OUT_ROW_FAILURE"
      ) {
        toast.success("Data Uploaded Successfully !",
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else {
        toast.success(t(`${csvUploadResponseData.responseCode}`),
        {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      }
      refreshHandler();
    }
  }, [csvUpload]);
  const toggleDialog = () => {
    dispatch({
      type: "mapping/mappingCSVUploadInitiate",
      payload: { csvUpload: false, loader: false },
    });
    setVisible(!visible);
  };
  const handleCSVUpload = () => {
    const fileSizeInMB = myFiles[0].size / (1024 * 1024);
    if (fileSizeInMB > 40) {
      return toast.warning("File size should be less than or equal to 40 MB",
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    }
    //  if(myFiles[0].type!="text/csv" && myFiles[0].type!="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
    //   return toast.warning("Only CSV and EXCEL is supported")

    //  }
    if (myFiles[0].type != "text/csv") {
      setVisible(!visible);

      return toast.error("Only CSV file is supported",
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    }
    const uploadBody = {
      csvFile: myFiles[0],
      tenantId: tenantId,
    };
    dispatch({ type: UPLOAD_MAPPING_CSV_DATA, payload: uploadBody });
    dispatch({
      type: "mapping/mappingCSVUploadInitiate",
      payload: { csvUpload: false, loader: true },
    });
  };
  const handleChange = (e) => {
    setSearchField(e.target.value);

  };
  return (
    <>
      {loader && <ProcessingLoader />}
      <div>
        <section className="client-listing">
          <h3 className="section-heading">{t("Mapping")}</h3>
          <Breadcrumb list={["Entities Data", "Mapping"]} />
          <div className="client-listing-content">
            <div className="client-listing-toolbar">
              <TableToolbar
                showMoreOptions={showMoreOptions}
                setRowLength={setRowLength}
                setPageIndex={setPageIndex}
                searchField={searchField}
                handleChange={handleChange}
                setSearchField={setSearchField}
                toggleDialog={toggleDialog}
                handleSearchSubmit={handleSearchSubmit}
                refreshHandler={refreshHandler}
                rowLength={rowLength}
                downloadUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/csv?routeType=mapping&tenant_id=${tenantId}&bu_id=${buId}&sortFieldStringWithASCOrDESC=${sortValue}&file_type=csv&searchValue=${searchField}&${csvDownloadTableDataRows}`}
                dataList={mappingDetails}
                downloadCsvApi={downloadCsvApi}
                downloadName={"mapping.csv"}
                csvUploadPermission={addEditPermission}
                entityName={"mapping"}
                componentNameAddEdit={"mapping"}

                showAdvanceSearch={"mapping"}
                handleAdvanceSearch={handleAdvanceSearch}
                clearAdvanceSearch={clearAdvanceSearch}
                advanceSearchTableColumn={mappingStaticHeader}
            
                storedFeatureHeader={mappingStaticHeader}
                dataRows={mappingDetails}

             
              />
            </div>
            <div className="client-listing-grid">
              {" "}
              {
                mappingDetails && mappingDetails.length &&
                mappingGridColumn && mappingGridColumn.length?
                 <TableGrid
                dataRows={mappingDetails && mappingDetails.length && mappingGridColumn && mappingGridColumn.length ?mappingDetails:[]}
                dataColumn={
                  // updateflag
                  //   ? (Array.isArray(dataColumn) ? dataColumn.filter((element) => !newallCheckboxId.includes(element)) : [])
                  //   : (Array.isArray(dataColumn) ? dataColumn : [])
                  mappingDetails && mappingDetails.length && mappingGridColumn && mappingGridColumn.length ?mappingGridColumn:[]
                }
                refresh={refresh}
                pageSize={pageSize}
                totalElement={totalElement}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setSortValue={setSortValue}
                isLoading={loader}
                replaceColumnLevels={customColumnHeaders()}
                componentName={"mapping"}
              />:<div className="text-center">No records found</div>
              }
              
            </div>
          </div>
        </section>

        {visible && (
          <UploadDialog
            templateUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/template?routeType=mapping&tenant_id=${tenantId}&bu_id=${buId}`}
            toggleDialog={toggleDialog}
            handleCSVUpload={handleCSVUpload}
            myFiles={myFiles}
            listing={files}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            downloadCsvApi={downloadCsvApi}
            templateName={"mapping-template.csv"}
            errorCSV={errorCSV}
            dateFormat={localeConfiguration["dateFormat"]}
          />
        )}
      </div>
    </>
  );
};

export default List;
