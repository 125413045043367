import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MenuItem from "@mui/material/MenuItem";
import { get } from "lodash";
import Checkbox from "@mui/material/Checkbox";

import SyncIcon from "@mui/icons-material/Sync";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  addEditTableColumnDataTypes,
  addEditPermission,
  advanceSearchPermission,
} from "../../../utils/Constants";
import { Collapse } from "@mui/material";
// new code - 0
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import Popover from "@mui/material/Popover";
import DialogActions from "@mui/material/DialogActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  forecastSyncAPI,
  getAllLikeSkuAPI,
  forecastReverseSyncAPI,
  getLikeSkuSearchAdvanceFilterAPI,
} from "../../../apis";

import {
  GET_ALL_EDIT_FEATURES,
  GET_ALL_EDIT_LOCATION,
  POST_ALL_ADD_LOCATION,
  POST_ALL_ADD_FEATURES,
  GET_ALL_EDIT_PRODUCT,
  POST_ALL_ADD_PRODUCT,
  GET_ALL_EDIT_BUDGET,
  POST_ALL_ADD_BUDGET,
  GET_ALL_EDIT_PROMO_MASTER,
  POST_ALL_PROMO_MASTER,
  GET_ALL_EDIT_PROMO_TRANSACTION,
  POST_ALL_PROMO_TRANSACTION,
  GET_ALL_EDIT_MAPPING,
  POST_ALL_ADD_MAPPING,
  POST_ALL_ADD_PERSONNEL,
  GET_ALL_EDIT_PERSONNEL,
} from "../../../store/types";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import Typography from "@mui/material/Typography";
import Dialogg from "@mui/material/Dialog";
import Buttonn from "@mui/material/Button";
import { Dialog } from "@progress/kendo-react-dialogs";
import MenuIcon from "@mui/icons-material/Menu";
import { DatePicker } from "antd";
import { Input } from "@progress/kendo-react-inputs";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTrashAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TableGrid from "..";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
// end new code - 0
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { InputSelect } from "../../inputSelect/";
import { Button } from "../../button/";

import { InputText } from "../../inputText/";
import { FILE_TYPE } from "../../../constant/constant";
import { MdOutlineFileDownload } from "react-icons/md";
import { ROUTE_TYPE } from "../../../constant/constant";
import DownloadIcon from "@mui/icons-material/Download";
// new code - 1
import { operators, operatorsDataType } from "../../../constant/constant";
import isAfter from "date-fns/isAfter";
import { makeStyles } from "@mui/styles";

import "./addEdit.scss";

const useStyles = makeStyles((theme) => ({
  redStart: {
    color: "red",
  },
}));
// import { Select } from 'antd';

const { RangePicker } = DatePicker;

const BootstrapDialog = styled(Dialogg)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
// end new code -1

const TableToolbar = ({
  showMoreOptions,
  setRowLength,
  setPageIndex,
  searchField,
  setSearchField,
  handleChange,
  toggleDialog,
  handleSearchSubmit,
  componentNameAddEdit,
  refreshHandler,
  downloadUrl,
  dataList,
  showAdvanceSearch,
  disabled,
  downloadCsvApi,
  downloadName,
  rowLength,
  csvUploadPermission,
  entityName,
  addRemoveTableDataColumn,
  productHierarchyConfiguration,
  locationHierarchyConfiguration,
  sendDataToParent,
  onCheckboxChange,
  allCheckboxId,
  handleSearchItem,
  promoNamesList,
  ondefaultbutton,
  unCheckDefault,
  handleAdvanceSearch,
  clearAdvanceSearch,
  hierarchyName,
  hierarchyNameExtra,
  handleHideandShow,
  dataColumnDetails,
  dataForHideShowList,
  dataRows,
  storedFeatureHeader,
  componentName,
  snopId,
  advanceSearchTableColumn,
  snopStartDate,
  snopEndDate,
}) => {
  const { t } = useTranslation();
  // new code -2
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [showHideAdvanceSearch, setShowHideAdvanceSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isGoButtonClicked, setIsGoButtonClicked] = useState(false);
  const [isOkButtonClicked, setIsOkButtonClicked] = useState(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [dataTypeError, setDataTypeErrors] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [initialValues, setInitialValues] = useState({});
  
  const handleButtonClick = (index, status) => {
    setActiveInactiveColor(prevState => ({
      ...prevState,
      [index]: status
    }));
  };

  const onChangeSearchItem = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearchItem(e.target.value);
  };

  const hierarchyKeyExtra = `${hierarchyNameExtra}Hierarchy`;

  const hierarchyConfigurationExtra = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities[
        hierarchyKeyExtra
      ]
    : {};
  const hierarchyKey = `${hierarchyName}Hierarchy`;
  const hierarchyConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration")).entities[hierarchyKey]
    : {};

  let [rows, setRows] = useState([
    { id: 1, columnName: "Select Value", operator: "Select Type", value: "" }, //1
  ]);
  const syncConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration"))?.entities?.custom
    : null;

  const { selectedRowIds } = useSelector((store) => store.commonReducer);
  const { addEditTableColumnLocation,locationAddError ,locationEditError} = useSelector(
    (state) => state.LocationReducer
  );
  const { addEditTableColumnProduct ,productAddError,productEditError} = useSelector(
    (state) => state.ProductReducer
  );
  const { addEditTableColumnFeature,featureAddError,featureEditError } = useSelector(
    (state) => state.FeatureReducer
  );
  const { addEditTableColumnBudget ,budgetAddError, budgetEditError} = useSelector(
    (state) => state.BudgetReducer
  );
  
  const { addEditTableColumnPromoMaster,promoMasterAddError, promoMasterEditError } = useSelector(
    (state) => state.PromoMasterReducer
  );
  const { addEditTableColumnPromoTransaction,promoTransactionAddError, promoTransactionEditError } = useSelector(
    (state) => state.PromoReducer
  );
  const { addEditTableColumnMapping,mappingAddError, mappingEditError } = useSelector(
    (state) => state.MappingReducer
  );
  const { addEditTableColumnPersonnel,personnelAddError, personnelEditError } = useSelector(
    (state) => state.PersonnelReducer
  );
  useEffect(()=>{
    if(promoMasterAddError){
      toast.error(promoMasterAddError)
    }
  },[promoMasterAddError])
  useEffect(()=>{
    if(budgetAddError){
      toast.error(budgetAddError)
    }
  },[budgetAddError])
  useEffect(()=>{
    if(promoTransactionAddError){
      toast.error(promoTransactionAddError)
    }
  },[promoTransactionAddError])
  useEffect(()=>{
    if(personnelAddError){
      toast.error(personnelAddError)
    }
  },[personnelAddError])
  useEffect(()=>{
    if(featureAddError){
      toast.error(featureAddError)
    }
  },[featureAddError])
  useEffect(()=>{
    if(locationAddError){
      toast.error(locationAddError)
    }
  },[locationAddError])
  useEffect(()=>{
    if(productAddError){
      toast.error(productAddError)
    }
  },[productAddError])

  useEffect(()=>{
    if(mappingAddError){
      toast.error(mappingAddError)
    }
  },[mappingAddError])


  // Edit Error Handling

  useEffect(()=>{
    if(promoMasterEditError){
      toast.error(promoMasterEditError)
    }
  },[promoMasterEditError])
  useEffect(()=>{
    if(budgetEditError){
      toast.error(budgetEditError)
    }
  },[budgetEditError])
  useEffect(()=>{
    if(promoTransactionEditError){
      toast.error(promoTransactionEditError)
    }
  },[promoTransactionEditError])
  useEffect(()=>{
    if(personnelEditError){
      toast.error(personnelEditError)
    }
  },[personnelEditError])
  useEffect(()=>{
    if(featureEditError){
      toast.error(featureEditError)
    }
  },[featureEditError])
  useEffect(()=>{
    if(locationEditError){
      toast.error(locationEditError)
    }
  },[locationEditError])
  useEffect(()=>{
    if(productEditError){
      toast.error(productEditError)
    }
  },[productEditError])

  useEffect(()=>{
    if(mappingEditError){
      toast.error(mappingEditError)
    }
  },[mappingEditError])


  // addEditTableColumnPromoMaster

  useEffect(() => {
    if (typeof addEditTableColumnPersonnel?.message === "string") {
      refreshHandler();
      setIsEditModalOpen(false);
          setAddEditValue("");
      toast.success(`${addEditTableColumnPersonnel?.message}`);
    }
  }, [addEditTableColumnPersonnel]);

  useEffect(() => {
    if (typeof addEditTableColumnMapping?.message === "string") {
      refreshHandler();
      setIsEditModalOpen(false);
          setAddEditValue("");
      toast.success(`${addEditTableColumnMapping?.message}`);
    }
  }, [addEditTableColumnMapping]);

  useEffect(() => {
    if (typeof addEditTableColumnPromoTransaction?.message === "string") {
      refreshHandler();
      setIsEditModalOpen(false);
          setAddEditValue("");
      toast.success(`${addEditTableColumnPromoTransaction?.message}`);
    }
  }, [addEditTableColumnPromoTransaction]);
  useEffect(() => {
    if (typeof addEditTableColumnBudget?.message === "string") {
      refreshHandler();
          setIsEditModalOpen(false);
          setAddEditValue("");



      toast.success(`${addEditTableColumnBudget?.message}`);
    }
  }, [addEditTableColumnBudget]);
  console.log("addEditTableColumnBudgetttt",addEditTableColumnBudget)
  useEffect(() => {
    if (typeof addEditTableColumnPromoMaster?.message === "string") {
      refreshHandler();
      setIsEditModalOpen(false);
          setAddEditValue("");
      toast.success(`${addEditTableColumnPromoMaster?.message}`);
    }
  }, [addEditTableColumnPromoMaster]);

  useEffect(() => {
    if (typeof addEditTableColumnFeature?.message === "string") {
      refreshHandler();
      setIsEditModalOpen(false);
          setAddEditValue("");
      toast.success(`${addEditTableColumnFeature?.message}`);
    }
  }, [addEditTableColumnFeature]);
  useEffect(() => {
    if (typeof addEditTableColumnLocation?.message === "string") {
      refreshHandler();
      setIsEditModalOpen(false);
          setAddEditValue("");
      toast.success(`${addEditTableColumnLocation?.message}`);
    }
  }, [addEditTableColumnLocation]);
  useEffect(() => {
    if (typeof addEditTableColumnProduct?.message === "string") {
      refreshHandler();
      setIsEditModalOpen(false);
          setAddEditValue("");
      toast.success(`${addEditTableColumnProduct?.message}`);
    }
  }, [addEditTableColumnProduct]);
  const selectedIds = selectedRowIds.map((row) => row.id);
  let selectedRowData;
  if (componentNameAddEdit && componentNameAddEdit.length) {
    if (
      componentNameAddEdit.toLowerCase() === "feature" ||
      componentNameAddEdit.toLowerCase() === "budget" ||
      componentNameAddEdit.toLowerCase() === "promo" ||
      componentNameAddEdit.toLowerCase() === "promomaster" ||
      componentNameAddEdit.toLowerCase() === "mapping"
    ) {
      selectedRowData =
        dataRows?.length &&
        dataRows?.filter((item) => selectedIds.includes(item.id));
    } else if (componentNameAddEdit.toLowerCase() === "product") {
      selectedRowData =
        dataRows.length &&
        dataRows?.filter((item) => selectedIds.includes(item.mapping_id));
    } else if (componentNameAddEdit.toLowerCase() === "personnel") {
      selectedRowData =
        dataRows.length &&
        dataRows?.filter((item) => selectedIds.includes(item.personnel_id));
    } else if (componentNameAddEdit.toLowerCase() === "location") {
      selectedRowData =
        dataRows.length &&
        dataRows?.filter((item) => selectedIds.includes(item.node_mapping_id));
    }
  }

  const flattenedRawData = flattenObject(selectedRowData);

  function flattenObject(obj) {
    let result = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          // Recursively flatten nested objects
          const nestedValues = flattenObject(obj[key]);
          Object.assign(result, nestedValues);
        } else {
          // Assign key-value pair for non-object values
          result[key] = obj[key];
        }
      }
    }

    return result;
  }

  const [nextId, setNextId] = useState(2);
  const handleClearFilter = () => {
    setAdvanceFilterInputDataType([]);
    if (isOkButtonClicked) {
      setIsOkButtonClicked(false);
    }
    // const defaultValue = [
    //   { id: 1, columnName: "Select Value", operator: "Select Type", value: "" }, //2
    // ];
    setRows([]);
    setNextId(1);
  };

  const addRow = () => {
    if (rows.length > 4) {
      return toast.warning("Can not add more than 5 row");
    }
    setRows([
      ...rows,
      {
        id: nextId,
        columnName: "Select Value",
        operator: "Select Type",
        value: "", //3
      },
    ]);
    setNextId(nextId + 1);
  };

  const [advanceFilterInputDataType, setAdvanceFilterInputDataType] = useState(
    []
  );
  const handleOperators = (id, event) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, operator: event.target.value, value: "" }; //4
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleAdvanceSearchDateRange = (id, event) => {
    let startDateString = event[0].$d;
    let endDateString = event[1].$d;
    const sDate = new Date(startDateString);
    const eDate = new Date(endDateString);
    let startDate = sDate.toISOString().slice(0, 10);
    let endDate = eDate.toISOString().slice(0, 10);
    let date = "";
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        if (event.length) return { ...row, value: `${startDate},${endDate}` };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleInputChangeOne = (id, event) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        if (event.length) return { ...row, value: event };
        return { ...row, value: event.target.value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const removeRow = (id) => {
    const newArray = [...advanceFilterInputDataType];

    // Set the value at the specific index in the new array
    const updatedRows = rows.filter((row) => row.id !== id);
    const newUpdatedRows = updatedRows.map((row) => {
      if (row.id > id) {
        row.id = row.id - 1;
        return row;
      } else {
        return row;
      }
    });
    newArray.splice(id - 1, 1);
    setAdvanceFilterInputDataType(newArray);
    setRows(updatedRows);
    setNextId(nextId - 1);
  };
  const handleFilteredData = () => {
    setAdvanceFilterInputDataType([]);

    let flag = false;
    rows.map((row) => {
      if (row.columnName === "Select Value" || row.operator === "Select Type" || row.value==="") {
        flag = true;
        return;
      }
    });
    if (flag) {
      return toast.warning("All field are mandatory.");
    }

    if (
      rows.length === 1 &&
      rows[0].columnName === "Select Value" &&
      rows[0].operator === "Select Type"
    ) {
      setShowHideAdvanceSearch(false);
    } else {
      handleAdvanceSearch(rows);
      setRows([
        ...rows, //1
      ]);
      setShowHideAdvanceSearch(false);
      setIsOkButtonClicked(true);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // new code -2
  const handleDefault = () => {
    ondefaultbutton();
  };
  const unCheckClick = () => {
    unCheckDefault();
  };
  const handleUpdatebutton = () => {
    if (allCheckboxId.length > addRemoveTableDataColumn.length - 3) {
      toast.error("Please Select at least 3 Columns", {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    } else {
      handleHideandShow();
    }
    setOpen(false);
  };
  // end new code - 2
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchField.length >= 3) {
      setIsGoButtonClicked(true);
    }
    handleSearchSubmit(e);
  };

  // new code -3
  const handleCheckbox = (e) => {
    onCheckboxChange(e);
  };

  const handleOnDragEnd = (result) => {
    if (!searchTerm) {
      if (!result.destination) {
        return;
      }
      const temparray = Array.from(addRemoveTableDataColumn);
      const [tempreorderItem] = temparray.splice(result.source.index, 1);
      temparray.splice(result.destination.index, 0, tempreorderItem);
      addRemoveTableDataColumn = temparray;
      dataForHideShowList(temparray);

      const items = Array.from(dataColumnDetails);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      dataColumnDetails = items;
      sendDataToParent(items);
    }
  };

  const handleAdvanceSearchPopup = () => {
    setShowHideAdvanceSearch(true);
  };

  const handleEditClick = (e) => {
    setAddEditValue("Edit");
    console.log("addEditPopupData",addEditPopupData,flattenedRawData)
    const initialValues = {};
    if (addEditPopupData && addEditPopupData.length) {
      addEditPopupData.forEach((item) => {
        if (Object.keys(flattenedRawData).includes(item.beName)) {
          initialValues[item.beName] = flattenedRawData[item.beName];
        }
      });
      console.log("initialValuesss",initialValues)

      setInputValues(initialValues);
    }
    setIsEditModalOpen(true);
  };

  const handleAddClick = (e) => {
    const mandatoryFieldRed = document.getElementsByClassName(
      "label.MuiInputLabel-root"
    );
    setAddEditValue("Add");
    setProductAiReplacemtnDate("");
    setSkuSelectTableData([]);
    setPrefilledValueLikeSku(false);
    setLikeSkuId("");
    setSkuCodeFromCheckBox("");
    const initialValues = {};

    if (addEditPopupData && addEditPopupData.length) {
      addEditPopupData.forEach((item) => {
        initialValues[item.beName] = "";
      });
      if (componentNameAddEdit.toLowerCase() === "product") {
        initialValues["product_lifecycle_status"] = "true";
      }
      if (componentNameAddEdit.toLowerCase() === "location") {
        initialValues["node_lifecycle_Status"] = "true";
      }
      if (componentNameAddEdit.toLowerCase() === "promomaster") {
        initialValues["promo_status"] = "true";
      }
      if (componentNameAddEdit.toLowerCase() === "promo") {
        initialValues["status"] = "true";
      }
      if (componentNameAddEdit.toLowerCase() === "mapping") {
        initialValues["mapping_life_cycle_status"] = "true";
        initialValues["mapping_life_cycle_status_PP"] = "true";
        initialValues["mapping_life_cycle_status_RP"] = "true";
        initialValues["mapping_life_cycle_status_RMP"] = "true";
      }

      setInputValues(initialValues);
    }
    setIsEditModalOpen(true);
  };
  const [mandatoryFieldError, setMandatoryFieldError] = useState(false);
  useEffect(() => {
    let setError = inputValues;
    addEditPopupData.map((item, index) => {
      if (item.mandatory === true) {
        if (setError[item.beName] === "") {
          setMandatoryFieldError(true);
        } else {
          setMandatoryFieldError(false);
        }
      }
    });
  }, [inputValues]);
  const [dataTypeErrors, setDataTypeError] = useState({});
  const [dataTypeErrorBoolean, setDataTypeErrorBoolean] = useState({});

  const validateJSON = (value) => {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  };
  const validateBoolean = (value) => {
    return value.toLowerCase() === "true" || value.toLowerCase() === "false";
  };
  const handleInputChange = (column, value, dataType, currentIndex, event) => {

    console.log("currentIndexxxx",currentIndex)
    if (dataType.toLowerCase() === "json") {
      const isValidJSON = validateJSON(value);

      setDataTypeError((prevErrors) => ({
        ...prevErrors,
        [column]: !isValidJSON,
      }));
    } else if (dataType.toLowerCase() === "boolean") {
      if (value === "true") {
        value = true;
      } else if (value === "false") {
        value = false;
      }
    }
    setInputValues((prevValues) => ({
      ...prevValues,
      [column]: value,
    }));
  };
  const handleRemoveClick = () => {
    setShowRemoveConfirmation(true);
  };

  const handleConfirmRemove = () => {
    setShowRemoveConfirmation(false);
  };

  const handleCancelRemove = () => {
    setShowRemoveConfirmation(false);
  };
  // edit

  const user = JSON.parse(localStorage.getItem("user"));
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);

  // edit end
  const [addEditValue, setAddEditValue] = useState("");
  const {
    featureList,
    csvUploadResponseData,
    csvUploadError,
    csvUpload,
    loader,
    addEditTableColumnMessage,
  } = useSelector((state) => state.FeatureReducer);
  const entities = get(featureList, "entities", []);

  const customDataTextField = [
    {
      lavel: "First",
    },
    {
      lavel: "Second",
    },
  ];

  const [activeInactiveBoolean, setActiveInactiveBoolean] = useState(true);

  // like sku search code
  const ProductCustomHeaderConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration"))?.entities
        ?.productCustomHeader
    : null;
  const ProductHierarchyConfiguration = JSON.parse(
    localStorage.getItem("configuration")
  )
    ? JSON.parse(localStorage.getItem("configuration"))?.entities
        ?.productHierarchy
    : null;
  const [likeSkuSearchBody, setLikeSkuSearchBody] = useState({});
  const [likeSkuSearchResponse, setLikeSkuSearchResponse] = useState([]);
  const [copyAddEditValue, setCopyAddEditValue] = useState(null);
  const [skuSelectTableData, setSkuSelectTableData] = useState([]);
  const [autocompleteFocused, setAutocompleteFocused] = useState(false);
  const [likeSkuData, setLikeSkuData] = useState([
    { label: "Advance Search", year: 1996 },
  ]);
  const top100Films = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
    { label: "12 Angry Men", year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: "Pulp Fiction", year: 1994 },
  ];
  const [likeSkuId, setLikeSkuId] = useState("");
  const [skuCodeFromCheckBox, setSkuCodeFromCheckBox] = useState("");
  const [callOnFocusOnce, setCallOnfocusOnce] = useState(false);
  const handleAutocompleteFocus = (event, newValue) => {
    const { sku_code, channel_name } = inputValues;
    let body = {};
    for (let index = 0; index < Object.keys(inputValues).length; index++) {
      if (
        Object.keys(inputValues)
          [index].toLowerCase()
          .includes("producthierarchylevel") &&
        !Object.keys(inputValues)
          [index].toLowerCase()
          .includes("descriptionproducthierarchylevel")
      ) {
        body[addEditPopupData[index].feName] =
          inputValues[Object.keys(inputValues)[index]];
      }
    }
    body["sku_code"] = sku_code;
    body["channel_name"] = channel_name;

    if (!callOnFocusOnce) {
      const toastInfoId = toast.info("Searching for sku", {
        autoClose: false,
        closeButton: false,
      });
      getAllLikeSkuAPI(body)
        .then((res) => {
          setCallOnfocusOnce(true);

          const toastInfo = toast.success("Download complete!", {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            onClose: () => {
              toast.dismiss(toastInfoId);
            },
          });
          setLikeSkuData(res?.data?.responseData);
        })
        .catch((err) => {
          toast.dismiss(toastInfoId);
        });
    }
  };
  const [productAiReplacemtnDate, setProductAiReplacemtnDate] = useState("");
  const productAiReplacementDate = (date) => {
    if (productAiReplaceButton) {
      setInputValues((prevValues) => ({
        ...prevValues,
        ["likeSku_lifecycle_end_date"]: date.target.value,
      }));
    }
    setProductAiReplacemtnDate(date.target.value);
  };
  const handleAutocompleteChange = (event, newValue) => {};

  const selectSkuOnChangeHandle = (checkOrNot, index, skuId, skuCode) => {
    setSkuCodeFromCheckBox(checkOrNot ? skuCode : ""); // Clear the SKU code if unchecked
    setLikeSkuId(checkOrNot ? skuId : ""); // Clear the SKU ID if unchecked

    // Update the state to deselect other checkboxes
    const updatedData = skuSelectTableData.map((skus, i) => {
      if (i !== index) {
        return { ...skus, isChecked: false }; // Set isChecked to false for other checkboxes
      }
      return { ...skus, isChecked: checkOrNot }; // Update the current checkbox state
    });

    // Update the state with the modified data
    setSkuSelectTableData(updatedData);
  };
  const handleLikeSearch = () => {
    const { sku_code, channel_name } = inputValues;
    const toastInfoId = toast.info("Searching SKUs", {
      autoClose: false,
      closeButton: false,
    });
    let body = likeSkuSearchBody;
    body["sku_code"] = sku_code;
    body["channel_name"] = channel_name;

    getLikeSkuSearchAdvanceFilterAPI(body)
      .then((res) => {
        if (
          res?.data?.responseData?.length ||
          res?.data?.responseData?.length === 0
        ) {
          setSkuSelectTableData(res?.data?.responseData);
          const toastInfo = toast.success("Download complete!", {
            autoClose: 2000,
            closeOnClick: false,
            draggable: false,
            onClose: () => {
              toast.dismiss(toastInfoId);
            },
          });
        }
      })
      .catch((error) => {
        toast.error(error?.message);
        toast.dismiss(toastInfoId);
      });
  };
  const handleLikeSkuOnChange = (e, column) => {
    const value = e.target.value;
    setLikeSkuSearchBody((prevValues) => ({
      ...prevValues,
      [column]: value,
    }));
  };

  const [isLikeSearchOpen, setIsLikeSearchOpen] = useState(false);
  const [prefilledValueLikeSku, setPrefilledValueLikeSku] = useState(false);
  const handleLikeSearchPopup = () => {
    setIsLikeSearchOpen(false);
  };
  const handleAdvanceFilterLikeSku = () => {
    setIsLikeSearchOpen(true);
    setIsEditModalOpen(false);
    setSkuSelectTableData([]);
    setPrefilledValueLikeSku(true);
  };
  const handleLikeSkuSelect = () => {
    setInputValues((prevValues) => ({
      ...prevValues,
      ["like_sku_code"]: likeSkuId,
    }));
    setIsLikeSearchOpen(false);
    setIsEditModalOpen(true);
  };
  const handleCancelLikeSearchSku = () => {
    setIsLikeSearchOpen(false);
    setLikeSkuId("");
    setIsEditModalOpen(true);
  };

  const handleSaveEdit = () => {
    const ids = storedFeatureHeader.map((item) => item.isPrimaryKey === "U");
    const id = ids[0];

    //  handle add edit personnel


    
    if (
      addEditValue.toLowerCase() === "add" &&
      componentNameAddEdit.toLowerCase() === "personnel"
    ) {
      let flag = false;
      addEditPopupData.map((item, index) => {
        // if(item.isPrimaryKey !=="C" && item.mandatory===true ){
        if (item.mandatory === true) {
          if (inputValues[item.beName] === "") {
            flag = true;
          }
        }
      });
      if (flag) {
        flag = false;
        return toast.error("Please fill all mandatory field.", {
          style: {
            position: "relative",
            zIndex: "9999999 !important",
          },
        });
      }
      dispatch({ type: POST_ALL_ADD_PERSONNEL, payload: inputValues });
    }
    if (
      addEditValue.toLowerCase() === "edit" &&
      componentNameAddEdit.toLowerCase() === "personnel"
    ) {
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      let convertedValues = {};
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;

          if (
            dataType.toLowerCase() === "localdate" ||
            dataType.toLowerCase() === "datetime" ||
            dataType.toLowerCase() === "date"
          ) {
            if (inputValues[key] === null) continue;
            // if (inputValues[key].includes("GMT")) {
            //   const dateObject = new Date(inputValues[key]);
            //   // Extract year, month, and day components
            //   const year = dateObject.getFullYear();
            //   const month = (dateObject.getMonth() + 1)
            //     .toString()
            //     .padStart(2, "0"); // Months are zero-based
            //   const day = dateObject.getDate().toString().padStart(2, "0");

            //   // Form the resulting date string in "yyyy-mm-dd" format
            //   const formattedDate = `${year}-${month}-${day}`;
            //   convertedValues[key] = formattedDate;
            convertedValues[key] = inputValues[key];
            // } else {
            // }
            // let yyyMMDD=String(new Date (inputValues[key]) )
            // convertedValues[key] = yyyMMDD.split("T")[0]
            // }
            // convertedValues[key] = new Date (inputValues[key])
          } else if (
            dataType.toLowerCase() === "double" ||
            dataType.toLowerCase() === "integer" ||
            dataType.toLowerCase() === "long" ||
            dataType.toLowerCase() === "float"
          ) {
            convertedValues[key] = Number(inputValues[key]) || 0.0;
          } else if (dataType.toLowerCase() === "boolean") {
            convertedValues[key] = Boolean(inputValues[key]);
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }

      convertedValues.personnel_id = selectedRowIds[0].id;

      dispatch({ type: GET_ALL_EDIT_PERSONNEL, payload: convertedValues });
    }

   
    if (
      addEditValue.toLowerCase() === "add" &&
      componentNameAddEdit.toLowerCase() === "mapping"
    ) {
      let flag = false;
      addEditPopupData.map((item, index) => {
        // if(item.isPrimaryKey !=="C" && item.mandatory===true ){
        if (item.mandatory === true) {
          if (inputValues[item.beName] === "") {
            flag = true;
          }
        }
      });
      if (flag) {
        flag = false;
        return toast.error("Please fill all mandatory field.", {
          style: {
            position: "relative",
            zIndex: "9999999 !important",
          },
        });
      }
      dispatch({ type: POST_ALL_ADD_MAPPING, payload: inputValues });
    }
    if (
      addEditValue.toLowerCase() === "edit" &&
      componentNameAddEdit.toLowerCase() === "mapping"
    ) {
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      let convertedValues = {};
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;

          if (
            dataType.toLowerCase() === "localdate" ||
            dataType.toLowerCase() === "datetime" ||
            dataType.toLowerCase() === "date"
          ) {
            if (inputValues[key] === null) continue;
            // if (inputValues[key].includes("GMT")) {
            //   const dateObject = new Date(inputValues[key]);
            //   // Extract year, month, and day components
            //   const year = dateObject.getFullYear();
            //   const month = (dateObject.getMonth() + 1)
            //     .toString()
            //     .padStart(2, "0"); // Months are zero-based
            //   const day = dateObject.getDate().toString().padStart(2, "0");

            //   // Form the resulting date string in "yyyy-mm-dd" format
            //   const formattedDate = `${year}-${month}-${day}`;
            //   convertedValues[key] = formattedDate;
            convertedValues[key] = inputValues[key];
            // } else {
            // }
            // let yyyMMDD=String(new Date (inputValues[key]) )
            // convertedValues[key] = yyyMMDD.split("T")[0]
            // }
            // convertedValues[key] = new Date (inputValues[key])
          } else if (
            dataType.toLowerCase() === "double" ||
            dataType.toLowerCase() === "integer" ||
            dataType.toLowerCase() === "long" ||
            dataType.toLowerCase() === "float"
          ) {
            convertedValues[key] = Number(inputValues[key]) || 0.0;
          } else if (dataType.toLowerCase() === "boolean") {
            convertedValues[key] = Boolean(inputValues[key]);
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }

      convertedValues.id = selectedRowIds[0].id;

      dispatch({ type: GET_ALL_EDIT_MAPPING, payload: convertedValues });
    }

    if (
      addEditValue.toLowerCase() === "add" &&
      componentNameAddEdit.toLowerCase() === "promo"
    ) {
      let flag = false;
      addEditPopupData.map((item, index) => {
        if (item.mandatory === true) {
          if (inputValues[item.beName] === "") {
            flag = true;
          }
        }
      });
      if (flag) {
        flag = false;
        return toast.error("Please fill all mandatory field.", {
          style: {
            position: "relative",
            zIndex: "9999999 !important",
          },
        });
      }
      dispatch({ type: POST_ALL_PROMO_TRANSACTION, payload: inputValues });
    }
    if (
      addEditValue.toLowerCase() === "edit" &&
      componentNameAddEdit.toLowerCase() === "promo"
    ) {
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      let convertedValues = {};
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;

          if (
            dataType.toLowerCase() === "localdate" ||
            dataType.toLowerCase() === "datetime" ||
            dataType.toLowerCase() === "date"
          ) {
            if (inputValues[key] === null) continue;

            convertedValues[key] = inputValues[key];
          } else if (
            dataType.toLowerCase() === "double" ||
            dataType.toLowerCase() === "integer" ||
            dataType.toLowerCase() === "long" ||
            dataType.toLowerCase() === "float"
          ) {
            convertedValues[key] = Number(inputValues[key]) || 0.0;
          } else if (dataType.toLowerCase() === "boolean") {
            convertedValues[key] = Boolean(inputValues[key]);
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }

      convertedValues.id = selectedRowIds[0].id;

      dispatch({
        type: GET_ALL_EDIT_PROMO_TRANSACTION,
        payload: convertedValues,
      });
    }

    if (
      addEditValue.toLowerCase() === "add" &&
      componentNameAddEdit.toLowerCase() === "promomaster"
    ) {
      let flag = false;
      addEditPopupData.map((item, index) => {
        if (item.mandatory === true) {
          if (inputValues[item.beName] === "") {
            flag = true;
          }
        }
      });
      if (flag) {
        flag = false;
        return toast.error("Please fill all mandatory field.", {
          style: {
            position: "relative",
            zIndex: "9999999 !important",
          },
        });
      }
      dispatch({ type: POST_ALL_PROMO_MASTER, payload: inputValues });
    }
    if (
      addEditValue.toLowerCase() === "edit" &&
      componentNameAddEdit.toLowerCase() === "promomaster"
    ) {
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      let convertedValues = {};
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;

          if (
            dataType.toLowerCase() === "localdate" ||
            dataType.toLowerCase() === "datetime" ||
            dataType.toLowerCase() === "date"
          ) {
            if (inputValues[key] === null) continue;

            convertedValues[key] = inputValues[key];
          } else if (
            dataType.toLowerCase() === "double" ||
            dataType.toLowerCase() === "integer" ||
            dataType.toLowerCase() === "long" ||
            dataType.toLowerCase() === "float"
          ) {
            convertedValues[key] = Number(inputValues[key]) || 0.0;
          } else if (dataType.toLowerCase() === "boolean") {
            convertedValues[key] = Boolean(inputValues[key]);
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }

      convertedValues.id = selectedRowIds[0].id;

      dispatch({ type: GET_ALL_EDIT_PROMO_MASTER, payload: convertedValues });
    }

    if (
      addEditValue.toLowerCase() === "add" &&
      componentNameAddEdit.toLowerCase() === "feature"
    ) {
      let flag = false;
      addEditPopupData.map((item, index) => {
        if (item.mandatory === true) {
          if (inputValues[item.beName] === "") {
            flag = true;
          }
        }
      });
      if (flag) {
        flag = false;
        return toast.error("Please fill all mandatory field.", {
          style: {
            position: "relative",
            zIndex: "9999999 !important",
          },
        });
      }
      dispatch({ type: POST_ALL_ADD_FEATURES, payload: inputValues });
    }
    if (
      addEditValue.toLowerCase() === "edit" &&
      componentNameAddEdit.toLowerCase() === "feature"
    ) {
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      let convertedValues = {};
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;

          if (
            dataType.toLowerCase() === "localdate" ||
            dataType.toLowerCase() === "datetime" ||
            dataType.toLowerCase() === "date"
          ) {
            if (inputValues[key] === null) continue;
            convertedValues[key] = inputValues[key];
          } else if (
            dataType.toLowerCase() === "double" ||
            dataType.toLowerCase() === "integer" ||
            dataType.toLowerCase() === "long" ||
            dataType.toLowerCase() === "float"
          ) {
            convertedValues[key] = Number(inputValues[key]) || 0.0;
          } else if (dataType.toLowerCase() === "boolean") {
            convertedValues[key] = Boolean(inputValues[key]);
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }

      convertedValues.id = selectedRowIds[0].id;

      dispatch({ type: GET_ALL_EDIT_FEATURES, payload: convertedValues });
    }

    if (
      addEditValue.toLowerCase() === "add" &&
      componentNameAddEdit.toLowerCase() === "budget"
    ) {
      let flag = false;
      addEditPopupData.map((item, index) => {
        if (item.mandatory === true) {
          if (inputValues[item.beName] === "") {
            flag = true;
          }
        }
      });
      if (flag) {
        flag = false;
        return toast.error("Please fill all mandatory field.", {
          style: {
            position: "relative",
            zIndex: "999999",
          },
        });
      }

      dispatch({ type: POST_ALL_ADD_BUDGET, payload: inputValues });
    }
    if (
      addEditValue.toLowerCase() === "edit" &&
      componentNameAddEdit.toLowerCase() === "budget"
    ) {
      let { ...rest } = inputValues;
      rest.id = selectedRowIds[0].id;

      let convertedValues = {};
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;

          if (
            dataType.toLowerCase() === "localdate" ||
            dataType.toLowerCase() === "datetime" ||
            dataType.toLowerCase() === "date"
          ) {
            if (inputValues[key] === null) continue;
            convertedValues[key] = inputValues[key];
          } else if (
            dataType.toLowerCase() === "double" ||
            dataType.toLowerCase() === "integer" ||
            dataType.toLowerCase() === "long" ||
            dataType.toLowerCase() === "float"
          ) {
            convertedValues[key] = inputValues[key];
          } else if (dataType.toLowerCase() === "boolean") {
            convertedValues[key] = Boolean(inputValues[key]);
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }

      convertedValues.id = selectedRowIds[0].id;

      dispatch({ type: GET_ALL_EDIT_BUDGET, payload: convertedValues });
    }
    if (
      addEditValue.toLowerCase() === "add" &&
      componentNameAddEdit.toLowerCase() === "location"
    ) {
      let flag = false;
      addEditPopupData.map((item, index) => {
        if (item.mandatory === true) {
          if (inputValues[item.beName] === "") {
            flag = true;
          }
        }
      });
      if (flag) {
        flag = false;
        return toast.error("Please fill all mandatory field.", {
          style: {
            position: "relative",
            zIndex: "999999",
          },
        });
      }
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      const currentDate = new Date();

      // Get the year, month, and day
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
      const day = String(currentDate.getDate()).padStart(2, "0");

      // Form the date string in "yyyy-mm-dd" format
      const formattedDate = `${year}-${month}-${day}`;
      let convertedValues = {};
      convertedValues.created_at = formattedDate;
      convertedValues.created_by = formattedDate;
      convertedValues.modified_at = formattedDate;
      convertedValues.modified_by = formattedDate;

      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;

          if (dataType.toLowerCase() === "json" && inputValues[key]) {
            try {
              JSON.parse(inputValues[key]);
              convertedValues[key] = inputValues[key];
            } catch (error) {
              flag = true;
            }
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }
      if (flag) {
        flag = false;
        return toast.error(`Json is not valid`, {
          style: {
            position: "relative",
            zIndex: "999999",
          },
        });
      }
      dispatch({ type: POST_ALL_ADD_LOCATION, payload: convertedValues });
    }
    if (
      addEditValue.toLowerCase() === "edit" &&
      componentNameAddEdit.toLowerCase() === "location"
    ) {
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      rest.id = selectedRowIds[0].id;

      let convertedValues = {};
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;

          if (
            dataType.toLowerCase() === "localdate" ||
            dataType.toLowerCase() === "datetime" ||
            dataType.toLowerCase() === "date"
          ) {
            if (inputValues[key] === null) continue;
            convertedValues[key] = inputValues[key];
          } else if (
            dataType.toLowerCase() === "double" ||
            dataType.toLowerCase() === "integer" ||
            dataType.toLowerCase() === "long" ||
            dataType.toLowerCase() === "float"
          ) {
            convertedValues[key] = Number(inputValues[key]) || 0.0;
          } else if (dataType.toLowerCase() === "boolean") {
            convertedValues[key] = Boolean(inputValues[key]);
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }

      let mappingId = selectedRowIds[0].id;
      convertedValues.node_mapping_id = mappingId;

      dispatch({ type: GET_ALL_EDIT_LOCATION, payload: convertedValues });
    }

    if (
      addEditValue.toLowerCase() === "add" &&
      componentNameAddEdit.toLowerCase() === "product"
    ) {
      let flag = false;
      addEditPopupData.map((item, index) => {
        if (item.mandatory === true) {
          if (inputValues[item.beName] === "") {
            flag = true;
          }
        }
      });
      if (flag) {
        flag = false;
        return toast.error("Please fill all mandatory field.", {
          style: {
            position: "relative",
            zIndex: "999999",
          },
        });
      }
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      const currentDate = new Date();

      // Get the year, month, and day
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
      const day = String(currentDate.getDate()).padStart(2, "0");

      // Form the date string in "yyyy-mm-dd" format
      const formattedDate = `${year}-${month}-${day}`;
      let convertedValues = {};
      convertedValues.created_at = formattedDate;
      convertedValues.created_by = formattedDate;
      convertedValues.modified_at = formattedDate;
      convertedValues.modified_by = formattedDate;
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;
          if (key === "likeSku_lifecycle_end_date") {
            convertedValues[key] = inputValues[key];
          } else {
            if (dataType.toLowerCase() === "json" && inputValues[key]) {
              try {
                JSON.parse(inputValues[key]);
                convertedValues[key] = inputValues[key];
              } catch (error) {
                flag = true;
              }
            } else {
              convertedValues[key] = inputValues[key];
            }
          }
        }
      }
      if (flag) {
        flag = false;
        return toast.error(`Json is not valid`, {
          style: {
            position: "relative",
            zIndex: "999999",
          },
        });
      }
      dispatch({ type: POST_ALL_ADD_PRODUCT, payload: rest });
    }
    if (
      addEditValue.toLowerCase() === "edit" &&
      componentNameAddEdit.toLowerCase() === "product"
    ) {
      let { created_at, created_by, modified_at, modified_by, ...rest } =
        inputValues;
      rest.id = selectedRowIds[0].id;

      let convertedValues = {};
      for (const key in rest) {
        if (inputValues.hasOwnProperty(key)) {
          const dataType = addEditPopupData.find(
            (item) => item.beName === key
          )?.dataType;
          if (key === "likeSku_lifecycle_end_date") {
            convertedValues[key] = inputValues[key];
          } else if (
            dataType.toLowerCase() === "localdate" ||
            dataType.toLowerCase() === "datetime" ||
            dataType.toLowerCase() === "date"
          ) {
            if (inputValues[key] === null) continue;
            convertedValues[key] = inputValues[key];
          } else if (
            dataType.toLowerCase() === "double" ||
            dataType.toLowerCase() === "integer" ||
            dataType.toLowerCase() === "long" ||
            dataType.toLowerCase() === "float"
          ) {
            convertedValues[key] = Number(inputValues[key]) || 0.0;
          } else if (dataType.toLowerCase() === "boolean") {
            convertedValues[key] = Boolean(inputValues[key]);
          } else {
            convertedValues[key] = inputValues[key];
          }
        }
      }
      let mappingId = selectedRowIds[0].id;
      convertedValues.mapping_id = mappingId;

      dispatch({ type: GET_ALL_EDIT_PRODUCT, payload: convertedValues });
    }
    // setAddEditValue("");
    // setIsEditModalOpen(false);
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
    setAddEditValue("");
    setLikeSkuId("");
  };

  const navigate = useNavigate();
  // csv and excel file download
  const filedownloadoptions = FILE_TYPE.map((item) => ({
    label: `${item}`,
    value: item,
  }));

  const handleClear = () => {
    if (isGoButtonClicked) {
      refreshHandler();
      setIsGoButtonClicked(false);
    } else {
      setSearchField("");
    }
  };

  useEffect(() => {
    if (!searchField && isGoButtonClicked) {
      setIsGoButtonClicked(false);
      refreshHandler();
    }
  });
  let addEditPopupData = [];
  let showMandatoryTextOnce = 0;
  let showOptionalTextOnce = 0;

  if (storedFeatureHeader && storedFeatureHeader.length) {
    for (let i = 0; i < storedFeatureHeader.length; i++) {
      if (storedFeatureHeader[i].isPrimaryKey === "C") {
        addEditPopupData.push(storedFeatureHeader[i]);
      }
    }
    for (let i = 0; i < storedFeatureHeader.length; i++) {
      if (
        storedFeatureHeader[i].isPrimaryKey !== "C" &&
        storedFeatureHeader[i].mandatory === true &&
        storedFeatureHeader[i].isPrimaryKey !== "U"
      ) {
        addEditPopupData.push(storedFeatureHeader[i]);
      }
    }
    for (let i = 0; i < storedFeatureHeader.length; i++) {
      if (
        storedFeatureHeader[i].isPrimaryKey !== "C" &&
        storedFeatureHeader[i].mandatory === false &&
        storedFeatureHeader[i].isPrimaryKey !== "U"
      ) {
        addEditPopupData.push(storedFeatureHeader[i]);
      }
    }
  }
  if (addEditPopupData && addEditPopupData.length) {
    for (let i = 0; i < addEditPopupData.length; i++) {}
    for (let i = 0; i < addEditPopupData.length; i++) {
      if (
        addEditPopupData[i].isPrimaryKey !== "C" &&
        addEditPopupData[i].mandatory === true &&
        addEditPopupData[i].isPrimaryKey !== "U"
      ) {
        if (showMandatoryTextOnce === 0) {
          showMandatoryTextOnce = i;
        }
      }
    }
    for (let i = 0; i < addEditPopupData.length; i++) {
      if (
        addEditPopupData[i].isPrimaryKey !== "C" &&
        addEditPopupData[i].mandatory === false &&
        addEditPopupData[i].isPrimaryKey !== "U"
      ) {
        if (showOptionalTextOnce === 0) {
          showOptionalTextOnce = i;
        }
      }
    }
  }
  const [activeInactiveColor, setActiveInactiveColor] = useState(
    {}
  );

  const handleAddEditPopupClose = () => {
    setIsEditModalOpen(false);
  };
  const [previousFieldFilled, setPreviousFieldFilled] = useState(true);

  const [isListOpen, setListOpen] = useState(true);
  const [isListOpenMandatory, setisListOpenMandatory] = useState(true);
  const [isListOpenCustom, setisListOpenCustom] = useState(true);
  const [isListOpenProductAi, setisListOpenProductAi] = useState(true);
  const [productAiReplaceButton, setProductAiReplaceButton] = useState(false);
  const handleProductAiFieldToggle = () => {
    setisListOpenProductAi(!isListOpenProductAi);
  };

  const handleSyncForecast = () => {
    forecastSyncAPI(snopId, syncConfiguration?.sync)
      .then((res) => {
        alert(`${res?.data?.responseCode}`);
      })
      .catch((err) => {});
  };
  const handleReverseSyncForecast=()=>{
    //console.log("reverse sync forecast started")
    forecastReverseSyncAPI(snopId, syncConfiguration?.['reverse-sync'], snopStartDate,snopEndDate)
    .then((res) => {
      //console.log("syncres", res);
      alert(`${res?.data?.responseCode}`);
    })
    .catch((err) => {
      console.log("ReverseSync Trigger failed", err);
    });
  }
  const toggleList = () => {
    setListOpen(!isListOpen);
  };
  const likeSkuCodeSearchData = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
    { label: "12 Angry Men", year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: "Pulp Fiction", year: 1994 },
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
    { label: "12 Angry Men", year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: "Pulp Fiction", year: 1994 },
    { label: "Advance Filter", year: 1996 },
  ];
  const handleCustomFieldToggle = () => {
    setisListOpenCustom(!isListOpenCustom);
  };
  const toggleListMandatory = () => {
    setisListOpenMandatory(!isListOpenMandatory);
  };

  // advance search
  const [advanceSearchTableOperators, setAdvanceSearchTableOperators] =
    useState({});
  const handleTableColumn = (id, event) => {
    let value = event.target.value;
    let dataType = advanceSearchTableColumn
      .filter((data) => data.feName === value)[0]
      .dataType.toLowerCase();

    const filteredObject = operatorsDataType.find(
      (obj) => Object.keys(obj)[0] === dataType
    );

    setAdvanceSearchTableOperators(filteredObject[dataType]);

    // Display the result
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        const newArray = [...advanceFilterInputDataType];

        // Set the value at the specific index in the new array
        newArray[id - 1] = filteredObject[dataType].inputFieldType;
        setAdvanceFilterInputDataType(newArray);
        return { ...row, columnName: event.target.value };
      }
      return row;
    });

    setRows(updatedRows);
  };
  return (
    <>
      <Modal
        open={showHideAdvanceSearch}
        onClose={false}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        style={{ position: "relative !important", zIndex: 999 }}
        disableBackdropClick
      >
        <div className="advance-search-popup-container">
          <div className="advance-search-table-container">
            <table className="advance-search-table">
              <thead>
                <tr>
                  <th>Column Name</th>
                  <th>Operator</th>
                  <th>Value</th>
                  <th>Remove Column</th>
                </tr>
              </thead>
              <tbody>
                {rows?.map((row, index) => (
                  <tr key={row.id}>
                    <td>
                      <select
                        style={{
                          width: "100%",
                          textAlign: "center",
                          padding: "3px",
                          border: "none",
                          outline: "none",
                          border: "1px solid lightgray",
                          color: "#444",
                          fontWeight: "400",
                        }}
                        onChange={(event) => handleTableColumn(row.id, event)}
                      >
                        <option value="" disabled selected hidden>
                          {row.columnName === "Select Value"
                            ? "Select Value"
                            : row.columnName}
                        </option>
                        {advanceSearchTableColumn?.map((column) => {
                          return (
                            <>
                              {column.scope.includes("D") ? (
                                <option value={column.feName}>
                                  {column.feName}
                                </option>
                              ) : null}
                            </>
                          );
                        })}
                      </select>
                    </td>
                    <td>
                      <select
                        style={{
                          width: "100%",
                          textAlign: "center",
                          padding: "3px",
                          border: "none",
                          outline: "none",
                          border: "1px solid lightgray",
                          color: "#444",
                          fontWeight: "400",
                        }}
                        onChange={(event) => handleOperators(row.id, event)}
                      >
                        <option value="" disabled selected hidden>
                          {row.operator === "Select Type"
                            ? "Select Type"
                            : row.operator}
                        </option>
                        {advanceSearchTableOperators.operatorList?.map(
                          (operator, index) => {
                            return (
                              <>
                                <option id={index} value={operator}>
                                  {operator}
                                </option>
                              </>
                            );
                          }
                        )}
                      </select>
                    </td>
                    {row.operator == "Date Range" ? (
                      <td>
                        <RangePicker
                          editable={false}
                          disabledDate={(date) => isAfter(date, new Date())}
                          onChange={(event) =>
                            handleAdvanceSearchDateRange(row.id, event)
                          }
                        />
                      </td>
                    ) : row.operator === "Is null" ||
                      row.operator === "Is not null" ? (
                      <td>
                        <Input disabled={true} />
                      </td>
                    ) : (
                      <td>
                        <input
                          style={{
                            border: "none",
                            width: "100%",
                            margin: "3px",
                            outline: "none",
                            border: "1px solid lightgray",
                            padding: "5px",
                            borderRadius: "2px",
                          }}
                          onChange={(event) =>
                            handleInputChangeOne(row.id, event)
                          }
                          value={row.value}
                          placeholder={"Enter Value"}
                          type={advanceFilterInputDataType[index]}
                        />
                      </td>
                    )}
                    <td>
                      <button onClick={() => removeRow(row.id)}>
                        <RemoveIcon />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="advance-search-button-container">
            <div className="advance-search-button-container-left">
              <Button
                buttonType={"primary"}
                disabled={disabled ?? false}
                className="ml-5"
                label={"Add Column"}
                onClick={addRow}
                type="submit"
              />
            </div>
            <div className="advance-search-button-container-right">
              <Button
                buttonType={"primary"}
                disabled={disabled ?? false}
                className="ml-5"
                label={"Clear Filter"}
                type="submit"
                onClick={handleClearFilter}
              />
              <Button
                buttonType={"primary"}
                disabled={disabled ?? false}
                className="ml-5"
                label={"OK"}
                type="submit"
                onClick={handleFilteredData}
              />
              <Button
                buttonType={"primary"}
                disabled={disabled ?? false}
                className="ml-5"
                label={"Cancel"}
                type="submit"
                onClick={() => {
                  setShowHideAdvanceSearch(false);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>

      <div className="client-listing-toolbar-left">
        <div className="client-listing-toolbar-left-show">
          <InputSelect
            name="show"
            placeholder="Show 10 Rows"
            data={showMoreOptions ?? []}
            value={rowLength}
            onChange={(e) => {
              setRowLength(e.target.value);
              setPageIndex(1);
            }}
            disabled={disabled ?? false}
          />
        </div>

        <Button
          buttonType={"refresh"}
          disabled={disabled}
          className="ml-15"
          onClick={refreshHandler}
        />
        {componentNameAddEdit &&
          addEditPermission.includes(componentNameAddEdit.toLowerCase()) && (
            <>
              <Button
                className="ml-15"
                style={{ backgroundColor: "#E94F1C" }}
                label={
                  <>
                    <span className="mr-5">Add</span>
                    <FontAwesomeIcon icon={faPlus} />
                  </>
                }
                type="submit"
                onClick={handleAddClick}
              />

              <Button
                disabled={!(selectedRowIds?.length === 1)}
                className="ml-15"
                label={
                  <>
                    <span className="mr-5">Edit</span>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </>
                }
                type="submit"
                onClick={handleEditClick}
              />
            </>
          )}

        {/* like sku model start_with */}
        <Modal
          open={isLikeSearchOpen}
          onClose={handleLikeSearchPopup}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{ position: "relative !important", zIndex: "99 !important" }}
        >
          <div className="edit-confirmation-popup">
            <AppBar
              style={{
                position: "sticky",
                top: 0,
                width: "100%",
                backgroundColor: "#efefef",
                color: "black",
              }}
            >
              <Toolbar>Like Search Sku</Toolbar>
            </AppBar>
            <div className="add_edit_popup_container">
              <div className="edit-popup-content">
                <div className="input-fields-container">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "100%",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    {typeof ProductCustomHeaderConfiguration?.productCustomHeaderCount ==
                      "number" &&
                      ProductCustomHeaderConfiguration?.productCustomHeaderCount >
                        0 &&
                      Object.keys(ProductCustomHeaderConfiguration)?.map(
                        (field, index) => {
                          return (
                            <>
                              {field.includes("header") && (
                                <TextField
                                  type={"text"}
                                  onChange={(e) => {
                                    handleLikeSkuOnChange(
                                      e,
                                      ProductCustomHeaderConfiguration[field]
                                    );
                                  }}
                                  label={
                                    ProductCustomHeaderConfiguration[field]
                                  }
                                />
                              )}
                            </>
                          );
                        }
                      )}
                    {typeof ProductHierarchyConfiguration?.productHierarchyNoOfLevels ==
                      "number" &&
                      ProductHierarchyConfiguration?.productHierarchyNoOfLevels >
                        0 &&
                      Object.keys(ProductHierarchyConfiguration)?.map(
                        (field, index) => {
                          return (
                            <>
                              {field.includes("productHierarchyLevel") && (
                                <TextField
                                  onChange={(e) => {
                                    handleLikeSkuOnChange(
                                      e,
                                      ProductHierarchyConfiguration[field]
                                    );
                                  }}
                                  type={"text"}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  label={ProductHierarchyConfiguration[field]}
                                />
                              )}
                            </>
                          );
                        }
                      )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    {" "}
                    <button
                      style={{
                        backgroundColor: "#ff4500",
                        color: "white",
                        fontSize: "12px",
                        padding: "3px 5px",
                        borderRadius: "10px",
                        fontWeight: "400",
                      }}
                      onClick={handleLikeSearch}
                    >
                      Search
                    </button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "100%",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    {skuSelectTableData && skuSelectTableData.length ? (
                      <table className="select_sku_table_checkbox">
                        <thead>
                          <tr>
                            <th></th>
                            <th>SKU Code</th>
                            <th>SKU Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {skuSelectTableData &&
                            skuSelectTableData.length &&
                            skuSelectTableData.map((skus, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={skus.isChecked}
                                        onChange={(e) => {
                                          selectSkuOnChangeHandle(
                                            e.target.checked,
                                            index,
                                            skus.skuId,
                                            skus.skuCode
                                          );
                                        }}
                                      />
                                    </td>
                                    <td>{skus.skuCode}</td>
                                    <td>{skus.skuName}</td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    ) : (
                      <p style={{ color: "red" }}>No SKU Data</p>
                    )}
                  </Box>
                </div>
              </div>
              <div className="add_edit_button">
                <button
                  style={{ backgroundColor: "#ff4500" }}
                  size="small"
                  variant="contained"
                  onClick={handleLikeSkuSelect}
                  disabled={skuCodeFromCheckBox.length ? false : true}
                >
                  Select
                </button>

                <button
                  size="small"
                  variant="contained"
                  onClick={handleCancelLikeSearchSku}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {/* like sku model end */}

        <Modal
          open={isEditModalOpen}
          onClose={handleAddEditPopupClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{ position: "relative !important", zIndex: "99 !important" }}
        >
          <div className="edit-confirmation-popup">
            <AppBar
              style={{
                position: "sticky",
                top: 0,
                width: "100%",
                backgroundColor: "#efefef",
                color: "black",
              }}
            >
              <Toolbar>
                {addEditValue}{" "}
                {componentNameAddEdit === "promo"
                  ? "Promotion Transactions"
                  : componentNameAddEdit === "promomaster"
                  ? "Promotion"
                  : componentNameAddEdit}
              </Toolbar>
            </AppBar>
            <div className="add_edit_popup_container">
              <div className="edit-popup-content">
                <div className="input-fields-container">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "100%",
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    {
                      console.log("inputValuesaddEditPopupDatre",addEditPopupData,inputValues)
                    }
                    {addEditPopupData?.length > 0 &&
                      addEditPopupData?.map((header, index) => {
                        return (
                          <>
                            {header?.isPrimaryKey === "C" ? (
                              <>
                                {header.feName === "Product Hierarchy Level" &&
                                componentNameAddEdit === "promo" ? (
                                  <>
                                    <label
                                      htmlFor=""
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "8px",
                                        color: "gray",
                                      }}
                                    >
                                      {header.feName}{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                      disabled={
                                        addEditValue.toLowerCase() === "edit"
                                          ? true
                                          : false
                                      }
                                      defaultValue={
                                        addEditValue.toLowerCase() === "edit"
                                          ? flattenedRawData[header.beName] ??
                                            ""
                                          : null
                                      }
                                      placeholder="Select Value"
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        padding: "3px",
                                        border: "none",
                                        outline: "none",
                                        border: "1px solid lightgray",
                                        color: "#444",
                                        fontWeight: "400",
                                      }}
                                      onChange={(e) => {
                                        handleInputChange(
                                          header.beName,
                                          e.target.value,
                                          header.dataType,
                                          index,
                                          e
                                        );
                                      }}
                                      name="cars"
                                      id="cars"
                                    >
                                      <option value="" disabled selected hidden>
                                        Select Value
                                      </option>
                                      {Object.values(
                                        productHierarchyConfiguration
                                      )
                                        .filter(
                                          (item) => typeof item != "number"
                                        )
                                        ?.map((item) => {
                                          return (
                                            <>
                                              <option value={item}>
                                                {item}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </select>
                                  </>
                                ) : header.feName ===
                                    "Location Hierarchy Level" &&
                                  componentNameAddEdit === "promo" ? (
                                  <>
                                    <label
                                      htmlFor=""
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "8px",
                                        color: "gray",
                                      }}
                                    >
                                      {header.feName}{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <select
                                      defaultValue={
                                        addEditValue.toLowerCase() === "edit"
                                          ? flattenedRawData[header.beName] ??
                                            ""
                                          : null
                                      }
                                      disabled={
                                        addEditValue.toLowerCase() === "edit"
                                          ? true
                                          : false
                                      }
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        padding: "3px",
                                        border: "none",
                                        outline: "none",
                                        border: "1px solid lightgray",
                                        color: "#444",
                                        fontWeight: "400",
                                      }}
                                      placeholder="Select Value"
                                      onChange={(e) => {
                                        handleInputChange(
                                          header.beName,
                                          e.target.value,
                                          header.dataType,
                                          index,
                                          e
                                        );
                                      }}
                                      name="cars"
                                      id="cars"
                                    >
                                      <option value="" disabled selected hidden>
                                        Select Value
                                      </option>
                                      {Object.values(
                                        locationHierarchyConfiguration
                                      )
                                        .filter(
                                          (item) => typeof item != "number"
                                        )
                                        ?.map((item) => {
                                          return (
                                            <>
                                              <option value={item}>
                                                {item}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </select>
                                  </>
                                ) : header.feName === "Promo Name" &&
                                  componentNameAddEdit === "promo" ? (
                                  <>
                                    <label
                                      htmlFor=""
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "8px",
                                        color: "gray",
                                      }}
                                    >
                                      {header.feName}{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <select
                                      disabled={
                                        addEditValue.toLowerCase() === "edit"
                                          ? true
                                          : false
                                      }
                                      defaultValue={
                                        addEditValue.toLowerCase() === "edit"
                                          ? flattenedRawData[header.beName] ??
                                            ""
                                          : null
                                      }
                                      style={{
                                        width: "100%",
                                        cursor:
                                          addEditValue.toLowerCase() === "edit"
                                            ? "not-allowed"
                                            : "pointer",
                                        textAlign: "center",
                                        padding: "3px",
                                        border: "none",
                                        outline: "none",
                                        border: "1px solid lightgray",
                                        color: "#444",
                                        fontWeight: "400",
                                      }}
                                      placeholder="Select Value"
                                      onChange={(e) => {
                                        handleInputChange(
                                          header.beName,
                                          e.target.value,
                                          header.dataType,
                                          index,
                                          e
                                        );
                                      }}
                                      name="cars"
                                      id="cars"
                                    >
                                      <option value="" disabled selected hidden>
                                        Select Value
                                      </option>
                                      {promoNamesList &&
                                        promoNamesList.length &&
                                        promoNamesList?.map((item) => {
                                          return (
                                            <>
                                              <option value={item.promoName}>
                                                {item.promoName}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </select>
                                  </>
                                ) : (
                                  <TextField
                                    width={200}
                                    style={{ width: "100%" }}
                                    sx={{
                                      ".MuiInputBase-input": {
                                        fontWeight: "100 !important",
                                      },
                                    }}
                                    label={
                                      <span>
                                        {header.feName}{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </span>
                                    }
                                    disabled={
                                      addEditValue === "Edit" ? true : false
                                    }
                                    placeholder={`Data Type : ${header.dataType}`}
                                    type={
                                      header.dataType.toLowerCase() ===
                                        "localdate" ||
                                      header.dataType.toLowerCase() ===
                                        "datetime" ||
                                      header.dataType.toLowerCase() === "date"
                                        ? "date"
                                        : header.dataType.toLowerCase() ===
                                            "string" ||
                                          header.dataType.toLowerCase() ===
                                            "json"
                                        ? "text"
                                        : header.dataType.toLowerCase() ===
                                            "double" ||
                                          header.dataType.toLowerCase() ===
                                            "integer" ||
                                          header.dataType.toLowerCase() ===
                                            "long" ||
                                          header.dataType.toLowerCase() ===
                                            "float"
                                        ? "number"
                                        : "text"
                                    }
                                    // required
                                    id={`outlined-size-normal-${index}`}
                                    defaultValue={
                                      addEditValue.toLowerCase() === "edit"
                                        ? flattenedRawData[header.beName] ?? ""
                                        : prefilledValueLikeSku
                                        ? inputValues[header.beName]
                                        : null
                                        

                                    }
                                    // skuCodeFromCheckBox
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    editable={
                                      header?.isPrimaryKey === "C" &&
                                      storedFeatureHeader?.isEditUi === true
                                    }
                                    onChange={(e) => {
                                      const enteredValue = e.target.value;
                                      const defaultDataType =
                                        flattenedRawData[header.beName];
                                      handleInputChange(
                                        header.beName,
                                        e.target.value,
                                        header.dataType,
                                        index,
                                        e
                                      );

                                      // Validate data type when the user enters data
                                      let isValidDataType = true;

                                      if (defaultDataType === "number") {
                                        // If the expected data type is number, check if the entered value is a valid number
                                        isValidDataType = !isNaN(
                                          Number(enteredValue)
                                        );
                                      }

                                      // Update error state for the specific column
                                      setDataTypeErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [header.beName]: !isValidDataType,
                                      }));
                                    }}
                                    select={header.enums == null || "NULL" || "null" ? false : true}
                                  >
                                    {header?.enums?.split(",").map((option) => (
                                      <MenuItem key={option} value={option}>
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              </>
                            ) : null}

                            {header?.mandatory === true &&
                            header?.isPrimaryKey !== "U" &&
                            header?.isPrimaryKey !== "C" ? (
                              <>
                                {showMandatoryTextOnce === index ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div style={{ color: "gray" }}>
                                      Other mandatory fields
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100px",
                                          margin: "0px 5px",
                                          height: "1.3px",
                                          backgroundColor: "gray",
                                        }}
                                      ></div>

                                      <div>
                                        {isListOpenMandatory ? (
                                          <span
                                            onClick={toggleListMandatory}
                                            style={{
                                              height: "10px",
                                              width: "10px",
                                              backgroundColor: "gray",
                                              borderRadius: "50%",
                                            }}
                                          >
                                            <ExpandMoreIcon />
                                          </span>
                                        ) : (
                                          <span
                                            style={{
                                              height: "10px",
                                              width: "10px",
                                              backgroundColor: "gray",
                                              borderRadius: "50%",
                                            }}
                                            onClick={toggleListMandatory}
                                          >
                                            <ExpandLessIcon />
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                <Collapse
                                  style={{
                                    display: isListOpenMandatory
                                      ? "block"
                                      : "none",
                                  }}
                                  in={isListOpenMandatory}
                                  className="show_hide_mandatory_field"
                                >
                                  {header.feName ===
                                    "Product Hierarchy Level" &&
                                  componentNameAddEdit === "promo" ? (
                                    <>
                                      <label
                                        htmlFor=""
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "8px",
                                          color: "gray",
                                        }}
                                      >
                                        {header.feName}{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <select
                                        disabled={
                                          addEditValue.toLowerCase() === "edit"
                                            ? true
                                            : false
                                        }
                                        defaultValue={
                                          addEditValue.toLowerCase() === "edit"
                                            ? flattenedRawData[header.beName] ??
                                              ""
                                            : // : inputValues[header.beName]=Object.values(productHierarchyConfiguration).filter(item=>typeof item!="number")[0]
                                              null
                                        }
                                        placeholder="Select Value"
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          padding: "3px",
                                          border: "none",
                                          outline: "none",
                                          border: "1px solid lightgray",
                                          color: "#444",
                                          fontWeight: "400",
                                        }}
                                        onChange={(e) => {
                                          handleInputChange(
                                            header.beName,
                                            e.target.value,
                                            header.dataType,
                                            index,
                                            e
                                          );
                                        }}
                                        name="cars"
                                        id="cars"
                                      >
                                        <option
                                          value=""
                                          disabled
                                          selected
                                          hidden
                                        >
                                          Select Value
                                        </option>
                                        {Object.values(
                                          productHierarchyConfiguration
                                        )
                                          .filter(
                                            (item) => typeof item != "number"
                                          )
                                          ?.map((item) => {
                                            return (
                                              <>
                                                <option value={item}>
                                                  {item}
                                                </option>
                                              </>
                                            );
                                          })}
                                      </select>
                                    </>
                                  ) : header.feName ===
                                      "Location Hierarchy Level" &&
                                    componentNameAddEdit === "promo" ? (
                                    <>
                                      <label
                                        htmlFor=""
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "8px",
                                          color: "gray",
                                        }}
                                      >
                                        {header.feName}{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>

                                      <select
                                        disabled={
                                          addEditValue.toLowerCase() === "edit"
                                            ? true
                                            : false
                                        }
                                        defaultValue={
                                          addEditValue.toLowerCase() === "edit"
                                            ? flattenedRawData[header.beName] ??
                                              ""
                                            : // :inputValues[header.beName]=Object.values(locationHierarchyConfiguration).filter(item=>typeof item!="number")[0]
                                              null
                                        }
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          padding: "3px",
                                          border: "none",
                                          outline: "none",
                                          border: "1px solid lightgray",
                                          color: "#444",
                                          fontWeight: "400",
                                        }}
                                        placeholder="Select Value"
                                        onChange={(e) => {
                                          handleInputChange(
                                            header.beName,
                                            e.target.value,
                                            header.dataType,
                                            index,
                                            e
                                          );
                                        }}
                                        name="cars"
                                        id="cars"
                                      >
                                        <option
                                          value=""
                                          disabled
                                          selected
                                          hidden
                                        >
                                          Select Value
                                        </option>
                                        {Object.values(
                                          locationHierarchyConfiguration
                                        )
                                          .filter(
                                            (item) => typeof item != "number"
                                          )
                                          ?.map((item) => {
                                            return (
                                              <>
                                                <option value={item}>
                                                  {item}
                                                </option>
                                              </>
                                            );
                                          })}
                                      </select>
                                    </>
                                  ) : header.feName === "Promo Name" &&
                                    componentNameAddEdit === "promo" ? (
                                    <>
                                      <label
                                        htmlFor=""
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "8px",
                                          color: "gray",
                                        }}
                                      >
                                        {header.feName}{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>

                                      <select
                                        disabled={
                                          addEditValue.toLowerCase() === "edit"
                                            ? true
                                            : false
                                        }
                                        defaultValue={
                                          addEditValue.toLowerCase() === "edit"
                                            ? flattenedRawData[header.beName] ??
                                              ""
                                            : // : inputValues[header.beName]=true
                                              // :inputValues[header.beName]=promoNamesList[0].promoName
                                              null
                                        }
                                        style={{
                                          width: "100%",
                                          cursor:
                                            addEditValue.toLowerCase() ===
                                            "edit"
                                              ? "not-allowed"
                                              : "pointer",
                                          textAlign: "center",
                                          padding: "3px",
                                          border: "none",
                                          outline: "none",
                                          border: "1px solid lightgray",
                                          color: "#444",
                                          fontWeight: "400",
                                        }}
                                        placeholder="Select Value"
                                        onChange={(e) => {
                                          handleInputChange(
                                            header.beName,
                                            e.target.value,
                                            header.dataType,
                                            index,
                                            e
                                          );
                                        }}
                                        name="cars"
                                        id="cars"
                                      >
                                        <option
                                          value=""
                                          disabled
                                          selected
                                          hidden
                                        >
                                          Select Value
                                        </option>
                                        {promoNamesList &&
                                          promoNamesList.length &&
                                          promoNamesList?.map((item) => {
                                            return (
                                              <>
                                                <option value={item.promoName}>
                                                  {item.promoName}
                                                </option>
                                              </>
                                            );
                                          })}
                                      </select>
                                    </>
                                  ) : header.dataType.toLowerCase() ===
                                      "boolean" &&
                                    header.feName
                                      .toLowerCase()
                                      .includes("status") ? (
                                    <div className="add_edit_active_inactive">
                                      <div>
                                        <p className="add_edit_lifecycle_status">
                                          {header.feName}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </p>
                                      </div>

                                      <div>
                                        <button
                                          className="add_edit_active_button"
                                          type="button"
                                          style={{
                                            backgroundColor: activeInactiveColor[index] === "active" ? "#32b199" : "",
                                            color: activeInactiveColor[index] === "active" ? "white" : "",
                                          }}
                                          onClick={(e) => {
                                            handleInputChange(header.beName, "true", header.dataType, index, e);
                                            handleButtonClick(index, "active");
                                          }}
                                        >
                                          Active
                                        </button>
                                        <button
                                          className="add_edit_inactive_button"
                                          style={{
                                            backgroundColor: activeInactiveColor[index] === "inactive" ? "#ef3a3a" : "",
                                            color: activeInactiveColor[index] === "inactive" ? "white" : "",
                                          }}
                                          type="button"
                                          onClick={(e) => {
                                            handleInputChange(header.beName, "false", header.dataType, index, e);
                                            handleButtonClick(index, "inactive");
                                          }}
                                        >
                                          Inactive
                                        </button>
                                      </div>
                                    </div>
                                  ) : header.dataType.toLowerCase() ===
                                    "boolean" ? (
                                    <>
                                      <label
                                        htmlFor=""
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "8px",
                                          color: "gray",
                                        }}
                                      >
                                        {header.feName}{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <select
                                        defaultValue={
                                          addEditValue.toLowerCase() === "edit"
                                            ? flattenedRawData[header.beName] ??
                                              ""
                                            : (inputValues[
                                                header.beName
                                              ] = true)
                                        }
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          padding: "3px",
                                          border: "none",
                                          outline: "none",
                                          border: "1px solid lightgray",
                                          color: "#444",
                                          fontWeight: "400",
                                        }}
                                        onChange={(e) => {
                                          handleInputChange(
                                            header.beName,
                                            e.target.value,
                                            header.dataType,
                                            index,
                                            e
                                          );
                                        }}
                                        name="cars"
                                        id="cars"
                                      >
                                        <option value={"true"}>Yes</option>
                                        <option value={"false"}>No</option>
                                      </select>
                                    </>
                                  ) : (
                                    <TextField
                                      width={200}
                                      sx={{
                                        ".MuiInputBase-input": {
                                          fontWeight: "100 !important",
                                        },
                                      }}
                                      label={
                                        <span>
                                          {header.feName}{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </span>
                                      }
                                      placeholder={`Data Type : ${header.dataType}`}
                                      style={{
                                        width: "100%",
                                        border:
                                          dataTypeErrors[header.beName] ||
                                          dataTypeErrorBoolean[header.beName]
                                            ? "1px solid red"
                                            : "",
                                      }}
                                      type={
                                        header.dataType.toLowerCase() ===
                                          "localdate" ||
                                        header.dataType.toLowerCase() ===
                                          "datetime" ||
                                        header.dataType.toLowerCase() === "date"
                                          ? "date"
                                          : header.dataType.toLowerCase() ===
                                              "string" ||
                                            header.dataType.toLowerCase() ===
                                              "json"
                                          ? "text"
                                          : header.dataType.toLowerCase() ===
                                              "double" ||
                                            header.dataType.toLowerCase() ===
                                              "integer" ||
                                            header.dataType.toLowerCase() ===
                                              "long" ||
                                            header.dataType.toLowerCase() ===
                                              "float"
                                          ? "number"
                                          : "text"
                                      }
                                      // required
                                      id={`outlined-size-normal-${index}`}
                                      defaultValue={
                                        addEditValue.toLowerCase() === "edit"
                                          ? flattenedRawData[header.beName] ??
                                            ""
                                          : prefilledValueLikeSku
                                          ? inputValues[header.beName]
                                          : null
                                      }
                                      variant="outlined"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      onChange={(e) => {
                                        const enteredValue = e.target.value;
                                        const defaultDataType =
                                          flattenedRawData[header.beName];
                                        handleInputChange(
                                          header.beName,
                                          e.target.value,
                                          header.dataType,
                                          index,
                                          e
                                        );

                                        // Validate data type when the user enters data
                                        let isValidDataType = true;

                                        if (defaultDataType === "number") {
                                          // If the expected data type is number, check if the entered value is a valid number
                                          isValidDataType = !isNaN(
                                            Number(enteredValue)
                                          );
                                        }

                                        // Update error state for the specific column
                                        setDataTypeErrors((prevErrors) => ({
                                          ...prevErrors,
                                          [header.beName]: !isValidDataType,
                                        }));
                                      }}
                                      select={
                                        header.enums === null || "NULL" || "null" ? false : true
                                      }
                                    >
                                      {header?.enums
                                        ?.split(",")
                                        .map((option) => (
                                          <MenuItem key={option} value={option}>
                                            {option}
                                          </MenuItem>
                                        ))}
                                    </TextField>
                                  )}
                                  {dataTypeErrors[header.beName] ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Please enter valid JSON
                                    </p>
                                  ) : dataTypeErrorBoolean[header.beName] ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Please enter valid Boolean
                                    </p>
                                  ) : null}
                                </Collapse>
                              </>
                            ) : null}
                            {header?.mandatory === false &&
                            header?.isPrimaryKey !== "U" &&
                            header?.isPrimaryKey !== "C" ? (
                              <>
                                {showOptionalTextOnce === index ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div style={{ color: "gray" }}>
                                      Optional fields
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100px",
                                          margin: "0px 5px",
                                          height: "1.3px",
                                          backgroundColor: "gray",
                                        }}
                                      ></div>

                                      <div>
                                        {isListOpen ? (
                                          <span
                                            style={{
                                              height: "10px",
                                              width: "10px",
                                              backgroundColor: "gray",
                                              borderRadius: "50%",
                                            }}
                                            onClick={toggleList}
                                          >
                                            <ExpandMoreIcon />
                                          </span>
                                        ) : (
                                          <span
                                            style={{
                                              height: "10px",
                                              width: "10px",
                                              backgroundColor: "gray",
                                              borderRadius: "50%",
                                            }}
                                            onClick={toggleList}
                                          >
                                            {" "}
                                            <ExpandLessIcon />
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                <Collapse
                                  style={{
                                    display: isListOpen ? "block" : "none",
                                  }}
                                  in={isListOpen}
                                >
                                  {header.beName === "created_at" ||
                                  header.beName === "created_by" ||
                                  header.beName === "modified_at" ||
                                  header.beName === "modified_by" ? null : (
                                    <>
                                      {header.dataType.toLowerCase() ===
                                        "boolean" &&
                                      header.feName
                                        .toLowerCase()
                                        .includes("status") ? (
                                        <div className="add_edit_active_inactive">
                                          <div>
                                            <p className="add_edit_lifecycle_status">
                                              {header.feName}
                                              {/* <span style={{ color: "red" }}>
                                                *
                                              </span> */}
                                            </p>
                                          </div>

                                          <div>
                                            <button
                                              className="add_edit_active_button"
                                              type="button"
                                              style={{
                                                backgroundColor:
                                                  activeInactiveColor ===
                                                  "active"
                                                    ? "#32b199"
                                                    : "",
                                                color:
                                                  activeInactiveColor ===
                                                  "active"
                                                    ? "white"
                                                    : "",
                                              }}
                                              onClick={(e) => {
                                                handleInputChange(
                                                  header.beName,
                                                  "true",
                                                  header.dataType,
                                                  index,
                                                  e
                                                );
                                                setActiveInactiveColor(
                                                  "active"
                                                );
                                                // setActiveInactiveBoolean(true)
                                              }}
                                            >
                                              Active
                                            </button>
                                            <button
                                              className="add_edit_inactive_button"
                                              style={{
                                                backgroundColor:
                                                  activeInactiveColor ===
                                                  "inactive"
                                                    ? "#ef3a3a"
                                                    : "",
                                                color:
                                                  activeInactiveColor ===
                                                  "inactive"
                                                    ? "white"
                                                    : "",
                                              }}
                                              type="button"
                                              onClick={(e) => {
                                                handleInputChange(
                                                  header.beName,
                                                  "false",
                                                  header.dataType,
                                                  index,
                                                  e
                                                );
                                                setActiveInactiveColor(
                                                  "inactive"
                                                );
                                                // setActiveInactiveBoolean(false)
                                              }}
                                            >
                                              Inactive
                                            </button>
                                          </div>
                                        </div>
                                      ) : header.beName.toLowerCase() ===
                                        "like_sku_code" ? (
                                        <>
                                          <div className="like_sku_advance_filter_container">
                                            <Autocomplete
                                              style={{
                                                width: "100%",
                                              }}
                                              disabled={
                                                inputValues["sku_code"] &&
                                                inputValues["channel_name"]
                                                  ? false
                                                  : true
                                              }
                                              disablePortal
                                              id="combo-box-demo"
                                              defaultValue={
                                                likeSkuId && likeSkuId?.length
                                                  ? skuCodeFromCheckBox
                                                  : ""
                                              }
                                              options={likeSkuData}
                                              onChange={(event, newValue) => {
                                                if (newValue === null) {
                                                  setInputValues(
                                                    (prevValues) => ({
                                                      ...prevValues,
                                                      ["like_sku_code"]: "",
                                                    })
                                                  );
                                                  setLikeSkuId("");
                                                }

                                                // Check the structure of the selected value
                                                if (newValue) {
                                                  handleInputChange(
                                                    "like_sku_code",
                                                    newValue.skuId, // Assuming skuCode is the property you want to extract
                                                    header.dataType,
                                                    index
                                                  );
                                                }
                                              }}
                                              sx={{ width: 300 }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label="Like Sku Code"
                                                />
                                              )}
                                              renderOption={(props, option) => {
                                                if (option.year === 1996) {
                                                  return (
                                                    <a
                                                      href="https://example.com"
                                                      style={{
                                                        textDecoration:
                                                          "underline",
                                                        display: "block",
                                                        padding: "8px",
                                                      }}
                                                      onClick={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                        handleAdvanceFilterLikeSku();
                                                      }}
                                                    >
                                                      {option.label}
                                                    </a>
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <TextField
                                          placeholder={`Data Type : ${header.dataType}`}
                                          label={header.feName}
                                          sx={{
                                            ".MuiInputBase-input": {
                                              fontWeight: "100 !important",
                                            },
                                          }}
                                          style={{
                                            width: "100%",
                                            border:
                                              dataTypeErrors[header.beName] ||
                                              dataTypeErrorBoolean[
                                                header.beName
                                              ]
                                                ? "1px solid red"
                                                : "",
                                          }}
                                          type={
                                            header.dataType.toLowerCase() ===
                                              "localdate" ||
                                            header.dataType.toLowerCase() ===
                                              "datetime" ||
                                            header.dataType.toLowerCase() ===
                                              "date"
                                              ? "date"
                                              : header.dataType.toLowerCase() ===
                                                  "string" ||
                                                header.dataType.toLowerCase() ===
                                                  "json"
                                              ? "text"
                                              : header.dataType.toLowerCase() ===
                                                  "double" ||
                                                header.dataType.toLowerCase() ===
                                                  "integer" ||
                                                header.dataType.toLowerCase() ===
                                                  "long" ||
                                                header.dataType.toLowerCase() ===
                                                  "float"
                                              ? "number"
                                              : "text"
                                          }
                                          id={`outlined-size-normal-${index}`}
                                          defaultValue={
                                            addEditValue.toLowerCase() ===
                                            "edit"
                                              ? flattenedRawData[
                                                  header.beName
                                                ] ?? ""
                                              : prefilledValueLikeSku
                                              ? inputValues[header.beName]
                                              : null
                                          }
                                          variant="outlined"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          onChange={(e) => {
                                            const enteredValue = e.target.value;
                                            const defaultDataType =
                                              flattenedRawData[header.beName];
                                            handleInputChange(
                                              header.beName,
                                              e.target.value,
                                              header.dataType,
                                              index,
                                              e
                                            );

                                            // Validate data type when the user enters data
                                            let isValidDataType = true;

                                            if (defaultDataType === "number") {
                                              // If the expected data type is number, check if the entered value is a valid number
                                              isValidDataType = !isNaN(
                                                Number(enteredValue)
                                              );
                                            }

                                            // Update error state for the specific column
                                            setDataTypeErrors((prevErrors) => ({
                                              ...prevErrors,
                                              [header.beName]: !isValidDataType,
                                            }));
                                          }}
                                          select={
                                            header.enums === null || "NULL" || "null"? false : true
                                          }
                                        >
                                          {header?.enums
                                            ?.split(",")
                                            .map((option) => (
                                              <MenuItem
                                                key={option}
                                                value={option}
                                              >
                                                {option}
                                              </MenuItem>
                                            ))}
                                        </TextField>
                                      )}

                                      {dataTypeErrors[header.beName] ? (
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "10px",
                                          }}
                                        >
                                          Please enter valid JSON
                                        </p>
                                      ) : dataTypeErrorBoolean[
                                          header.beName
                                        ] ? (
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "10px",
                                          }}
                                        >
                                          Please enter valid Boolean
                                        </p>
                                      ) : null}
                                    </>
                                  )}
                                </Collapse>
                              </>
                            ) : null}
                          </>
                        );
                      })}
                    {likeSkuId.length &&
                    componentNameAddEdit.toLowerCase() === "product" ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            width: "100%",
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ color: "gray" }}>Product Ai</div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "100px",
                                  margin: "0px 5px",
                                  height: "1.3px",
                                  backgroundColor: "gray",
                                }}
                              ></div>

                              <div>
                                {isListOpenProductAi ? (
                                  <span
                                    onClick={handleProductAiFieldToggle}
                                    style={{
                                      height: "10px",
                                      width: "10px",
                                      backgroundColor: "gray",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <ExpandMoreIcon />
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      height: "10px",
                                      width: "10px",
                                      backgroundColor: "gray",
                                      borderRadius: "50%",
                                    }}
                                    onClick={handleProductAiFieldToggle}
                                  >
                                    <ExpandLessIcon />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <Collapse
                              style={{
                                display: isListOpenProductAi ? "block" : "none",
                              }}
                              in={isListOpenProductAi}
                            >
                              <div>
                                <p>Is this a replacement ?</p>
                              </div>
                              <div
                                style={{
                                  padding: "8px 12px",
                                  borderRadius: "5px",
                                  backgroundColor: "#f7f9fc",
                                  width: "40%",
                                  fontWeight: "400",
                                }}
                              >
                                <button
                                  style={{
                                    padding: "8px 12px",
                                    borderRadius: "5px",
                                    color: productAiReplaceButton
                                      ? "white"
                                      : "black",
                                    backgroundColor: productAiReplaceButton
                                      ? "#8d939b"
                                      : "#f7f9fc",
                                  }}
                                  onClick={() =>
                                    setProductAiReplaceButton(true)
                                  }
                                >
                                  Yes
                                </button>
                                <button
                                  style={{
                                    padding: "8px 12px",
                                    borderRadius: "5px",
                                    color: productAiReplaceButton
                                      ? "black"
                                      : "white",
                                    backgroundColor: productAiReplaceButton
                                      ? "#f7f9fc"
                                      : "#8d939b",
                                    marginLeft: "20px",
                                  }}
                                  onClick={() =>
                                    setProductAiReplaceButton(false)
                                  }
                                >
                                  No
                                </button>
                              </div>
                              <div>
                                {productAiReplaceButton ? (
                                  <TextField
                                    style={{ width: "100%", marginTop: "10px" }}
                                    type="date"
                                    label="Life Cycle End Date of Like Sku"
                                    defaultValue={""}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    value={productAiReplacemtnDate}
                                    onChange={(newValue) => {
                                      productAiReplacementDate(newValue);
                                    }}
                                  />
                                ) : null}
                              </div>
                            </Collapse>
                          </div>
                        </Box>
                      </>
                    ) : null}
                  </Box>
                </div>
              </div>
              <div className="add_edit_button">
                <button
                  style={{ backgroundColor: "#ff4500" }}
                  size="small"
                  variant="contained"
                  onClick={handleSaveEdit}
                >
                  Apply
                </button>

                <button
                  size="small"
                  variant="contained"
                  onClick={handleCancelEdit}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {showRemoveConfirmation && (
          <div className="confirmation-popup">
            <div className="popup-content">
              <p>Are you sure you want to delete ?</p>
            </div>
            <div className="buttons-container">
              <button onClick={handleCancelRemove}>No</button>
              <button onClick={handleConfirmRemove}>Yes</button>
            </div>
          </div>
        )}
        {/* new code - 5 */}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="bootstrap_dialog"
        >
          <DialogContent dividers style={{ width: "auto", minWidth: "420px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    defaultChecked={true}
                    // checked={allCheckboxId.length == 0 ? true : false}
                    //   onChange={
                    //     allCheckboxId.length == 0 ? unCheckClick : handleDefault
                    //   }
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div style={{ marginLeft: "-40%" }}>
                <Input
                  type="text"
                  size="sm"
                  style={{ width: "180%" }}
                  className="searchItem"
                  value={searchTerm}
                  placeholder="Search column..."
                  onChange={onChangeSearchItem}
                />
              </div>
              <div>
                <span className="hideshowclose" onClick={handleClose}>
                  &times;
                </span>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              label={"Update"}
              buttonType={"primary"}
              className={"ml-16"}
              onClick={handleUpdatebutton}
            />
          </DialogActions>
        </BootstrapDialog>
        {/* end new code - 5 */}
      </div>

      <div className="client-listing-toolbar-right">
        {componentName === "forecast" &&
        snopId &&
        syncConfiguration?.sync?.length ? (
          <Buttonn
            startIcon={<SyncIcon />}
            className="ml-15"
            onClick={handleSyncForecast}
          >
            Sync
          </Buttonn>
        ) : null}
        {componentName === "forecast" &&
        snopId &&
        syncConfiguration?.["reverse-sync"]?.length ? (
          <Button
            buttonType={"upload"}
            // disabled={disabled ?? false}
            className="ml-15"
            onClick={handleReverseSyncForecast}
            label={t("Reverse Sync")}
          />
        ) : null}
        {csvUploadPermission && (
          <Button
            buttonType={"upload"}
            disabled={disabled ?? false}
            className="ml-15"
            onClick={toggleDialog}
            label={t("Upload")}
          />
        )}{" "}
        &nbsp;&nbsp;&nbsp;
        <div className="client-listing-toolbar-right-show input-select-wrapper">
          <Button
            buttonType={"download"}
            disabled={!(dataList?.length > 0) || !downloadUrl}
            className="ml-15"
            onClick={() => {
              return dataList?.length > 0 && downloadUrl
                ? downloadCsvApi({
                    url: downloadUrl,
                    fileName: downloadName,
                  })
                : false;
            }}
            label={t("CSV Download")}
          />
        </div>
        <form onSubmit={handleSearch} className={"custom-search-form"}>
          <div className={`client-listing-toolbar-right-csv-download mrg`}>
            <InputText
              name={"search"}
              value={searchField}
              placeholder={"Search..."}
              onChange={handleChange}
              adornmentIcon={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {searchField && (
                      <IconButton onClick={handleClear}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              disabled={disabled ?? false}
              className={
                isGoButtonClicked
                  ? "highlight-border"
                  : "search-listing-toolbar"
              }
            />
          </div>
          <div className="client-listing-toolbar-right-add-client toolbar-search-btn">
            <Button
              buttonType={"primary"}
              disabled={searchField.length < 3}
              className={"ml-5"}
              label={t("GO")}
              type="submit"
            />
          </div>
        </form>
        {/* new code - 7  */}
        {showAdvanceSearch &&
          advanceSearchPermission.includes(showAdvanceSearch) && (
            <div>
              <Button
                buttonType={"primary"}
                disabled={disabled ?? false}
                className={isOkButtonClicked ? "highlight-body" : "ml-5"}
                label={"Advance Filter"}
                type="submit"
                onClick={handleAdvanceSearchPopup}
              />
            </div>
          )}
        {/* end new code  - 7 */}
      </div>
    </>
  );
};

export default TableToolbar;
