import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_ROW_MATERIAL, UPLOAD_ROW_MATERIAL_CSV_DATA } from "../../../types";
import { getAllRowMaterialAPI, getHeaderAPI, uploadRowMaterialCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";
import { getAllRowMaterialSlice,RowMaterialCSVUploadComplete,RowMaterialCSVUploadError } from "../../../slices/entities/rawmaterial";

export function* getAllRowMaterialSaga(action) {
  try {
    yield put(getAllRowMaterialSlice({
      data: {},
      loader: true
    }))
    if(!sessionStorage.getItem('rawMaterialStaticHeader')){
      const response = yield getHeaderAPI("rawmaterial");
    yield sessionStorage.setItem('rawMaterialStaticHeader', JSON.stringify(response.data.responseData));
    }
   
    const res = yield getAllRowMaterialAPI(action.payload);
    yield put(getAllRowMaterialSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllRowMaterialSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadRowMaterialCSVDataAPI(action.payload);
    yield put(RowMaterialCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        RowMaterialCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchRowMaterialSaga() {
  yield takeEvery(GET_ALL_ROW_MATERIAL, getAllRowMaterialSaga);
  yield takeEvery(UPLOAD_ROW_MATERIAL_CSV_DATA,uploadCSVSaga);
}
