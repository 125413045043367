import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_RMPO,UPLOAD_RMPO_CSV_DATA } from "../../../types";
import { getAllRMPOAPI,getHeaderAPI,uploadRMPOCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";

import { getAllRMPOSlice,rmpoCSVUploadComplete,rmpoCSVUploadError } from "../../../slices/transactional/rmpo";

export function* getAllRMPOSaga(action) {

 
  try {
    yield put(getAllRMPOSlice({
      data: {},
      loader: true
    }))
    if(!sessionStorage.getItem('rawMaterialPurchaseOrderStaticHeader')){
      const response = yield getHeaderAPI("rmpo");
    yield sessionStorage.setItem('rawMaterialPurchaseOrderStaticHeader', JSON.stringify(response.data.responseData));
    }
    const res = yield getAllRMPOAPI(action.payload);
    yield put(getAllRMPOSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllRMPOSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadRMPOCSVDataAPI(action.payload);
    yield put(rmpoCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        rmpoCSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchRMPOSaga() {
  yield takeEvery(GET_ALL_RMPO, getAllRMPOSaga);
  yield takeEvery(UPLOAD_RMPO_CSV_DATA,uploadCSVSaga);
}
