import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  promoList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  addEditTableColumnPromoTransaction: null,
  promoTransactionAddError:null,
  promoTransactionEditError:null

};

const PromoSlice = createSlice({
  name: "promo",
  initialState: initialState,
  reducers: {
    getAllPromoSlice: (state, action) => {
      state.promoList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    getEditAllPromoTransactionSlice: (state, action) => {
      state.addEditTableColumnPromoTransaction = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    promoTransactionAddError: (state, action) => {
      state.promoTransactionAddError = action.payload;
      state.loader = false
      return state;
    },
    promoTransactionEditError: (state, action) => {
      state.promoTransactionEditError = action.payload;
      state.loader = false
      return state;
    },
    postAddAllPromoTransactionSlice: (state, action) => {
      state.addEditTableColumnPromoTransaction = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    promoCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    promoCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    promoCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
 getAllPromoSlice,
 promoCSVUploadComplete,
 promoTransactionEditError,
 getEditAllPromoTransactionSlice,
 promoTransactionAddError,
 postAddAllPromoTransactionSlice,
 promoCSVUploadError,
 promoCSVUploadInitiate
} = PromoSlice.actions;

export default PromoSlice.reducer;
