import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  networkList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
  networkStaticHeader:[]
};

export const NetworkSlice = createSlice({
  name: "network",
  initialState: initialState,
  reducers: {
    getAllNetworkSlice: (state, action) => {
      state.networkList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    getNetworkStaticHeaderSlice: (state, action) => {
      state.networkStaticHeader = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    networkCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    networkCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.csvUploadResponseData = action.payload;
      state.loader = false
      return state;
    },
    networkCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
  getAllNetworkSlice,
  networkCSVUploadInitiate,
  networkCSVUploadComplete,
  getNetworkStaticHeaderSlice,
  networkCSVUploadError,
} = NetworkSlice.actions;

export default NetworkSlice.reducer;
