import React, { useState, useEffect } from "react";
import "../../../../style/sass/main.scss";
import TableGrid from "../../../../components/tableGrid";
import TableToolbar from "../../../../components/tableGrid/toolbar";
import "@progress/kendo-theme-default/dist/all.css";
import {filesize} from 'filesize'
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_RMNETWORK,
  UPLOAD_RMNETWORK_CSV_DATA,
} from "../../../../store/types";
import { useTranslation } from "react-i18next";
import { SHOW_ROWS } from "../../../../constant/constant";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Breadcrumb from "../../../../components/breadcrumb";
import UploadDialog from "../../../../components/uploadDialog";
import { downloadCsvApi } from "../../../../apis";
import { csvFileReader } from "../../../../utils/csvFileReader";
import moment from "moment";
import { useNumericFormat } from "react-number-format";
import { entitiesName, permission } from "../../../../utils/Constants";
import ProcessingLoader from '../../../../components/processingLoader/'
import { use } from "i18next";

const List = (props) => {
  const { t } = useTranslation();
  const EDM_API_ENDPOINT = process.env.REACT_APP_EDM_API_ENDPOINT;  
  const SCAI_API_BASEURL = process.env.REACT_APP_SCAI_API_BASEURL_RM_NETWORK;

  const user = JSON.parse(localStorage.getItem("user"));
  const localeConfiguration = JSON.parse(localStorage.getItem("configuration")) ? JSON.parse(localStorage.getItem("configuration")).locale : {};
  const locationConfiguration = JSON.parse(localStorage.getItem("configuration")) ? JSON.parse(localStorage.getItem("configuration")).entities.locationHierarchy : {};
  const productConfiguration = JSON.parse(localStorage.getItem("configuration")) ? JSON.parse(localStorage.getItem("configuration")).entities.productHierarchy : {};
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);
  const addEditPermission = permissions.includes(permission.networkAddEdit);
  const dispatch = useDispatch();
  const [rowLength, setRowLength] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [errorCSV, setErrorCSV] = useState("");

  const [csvDownloadTableDataRows, setCSVDownloadTableDataRows]=useState("")
 

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };
  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const error = await csvFileReader(file);
      if (!error) {
        setMyFiles([file])
        setErrorCSV("");
      } else {
        setErrorCSV(error);
        setMyFiles([]);
      }
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const files = myFiles.map((file) => (
    <li key={file.path} className="csv-upload-success-li">
      {file.path} - {file.size} bytes
    </li>
  ));

  useEffect(() => {
    if (visible === false) {
      setMyFiles([]);
      setErrorCSV("");
    }
  }, [visible]);
  const { RMNetworkList, csvUploadResponseData, csvUploadError, csvUpload, loader } =
  useSelector((state) => state.RMNetworkReducer);
const RMNetworkEntities = get(RMNetworkList, "entities", "");
const totalElement = get(RMNetworkList, "totalElements", "");
const pageSize = get(RMNetworkList, "pageSize", "");
  const [RMNetworkStaticHeader,setRMNetworkStaticHeader]=useState([])
  useEffect(() => {
    const RMNetworkStaticHeaderData = sessionStorage.getItem("RMNetworkStaticHeader");
    if (RMNetworkStaticHeaderData && RMNetworkStaticHeaderData !== "undefined") {
      const parsedRMNetworkStaticHeader = JSON.parse(RMNetworkStaticHeaderData);
      setRMNetworkStaticHeader(parsedRMNetworkStaticHeader);
    }
  }, [RMNetworkList]);

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `${t("Show")} ${item} ${t("Rows")}`,
    value: item,
  }));

  const toggleDialog = () => {
    dispatch({ type: "network/RMNetworkCSVUploadInitiate", payload: { csvUpload: false, loader: false } });
    setVisible(!visible);
  };

  const body = {
    pageIndex: pageIndex,
    pageSize: rowLength,
    sortFieldStringWithASCOrDESC: sortValue,
    searchValue: searchField,
    tenantId: tenantId,
  };

  useEffect(() => {
    if (!refresh) {
      if (csvDownloadTableDataRows === "") {
        dispatch({ type: GET_ALL_RMNETWORK, payload: body });
      }else{
        body.advanceSearchValue = csvDownloadTableDataRows;
        body.buttonStatus = true;
        dispatch({ type: GET_ALL_RMNETWORK, payload: { ...body } });
      }
    }
  }, [dispatch, pageIndex, sortValue, rowLength]);

  const handleSearchSubmit = () => {
    setPageIndex(1);
    dispatch({ type: GET_ALL_RMNETWORK, payload: { ...body, pageIndex: 1 } });
  };

  const handleAdvanceSearch = (rows) => {
    if(rows.length===1 && rows[0].columnName==="Select Value" && rows[0].operator==="Select Type"){
    } else{
      let result = '';
    rows.forEach((item, index) => {
        const keyIndex = index + 1;
        result += `value${keyIndex}=${RMNetworkStaticHeader.filter(data=>data.feName===item.columnName)[0].dbName},${item.operator.toLowerCase().replace(/\s+/g, '_')},${item.value}`;
        if (index !== rows.length - 1) {
            result += '&';
        }
    });
    setCSVDownloadTableDataRows(result)
    body.advanceSearchValue = result;
    body.buttonStatus = true;
    setPageIndex(1);
    dispatch({ type: GET_ALL_RMNETWORK, payload: { ...body, pageIndex: 1 } });
    }
  };

  const clearAdvanceSearch = () => {
    body.buttonStatus = false;
    setPageIndex(1);
    dispatch({ type: GET_ALL_RMNETWORK, payload: { ...body, pageIndex: 1 } });
  };

  const refreshHandler = () => {
    setRefresh(true);
    setPageIndex(1);
    setSearchField("");
    setSortValue("");
    setRowLength(10);
    const refreshBody = {
      ...body,
      searchValue: "",
      pageIndex: 1,
      pageSize: 10,
      sortFieldStringWithASCOrDESC: "",
    };
    setTimeout(() => {
      dispatch({ type: GET_ALL_RMNETWORK, payload: refreshBody });
      setRefresh(false);
    }, 300);
  };

 

  const { format } = useNumericFormat({
    decimalSeparator: localeConfiguration["decimalSeparator"],
    thousandsGroupStyle: localeConfiguration["thousandsGroupStyle"],
    thousandSeparator: localeConfiguration["thousandSeparator"],
    decimalScale: 2
  });

  const RMNetworkDetails =
    RMNetworkEntities &&
    RMNetworkEntities.map((item) => {
      const items = { ...item };
      if (item.contract_end_date) {
        items.contract_end_date = moment(item?.contract_end_date)?.format(localeConfiguration["dateFormat"]?.toUpperCase());
      } else {
        items.contract_end_date = "";
      }
      
      if (item.contract_start_date) {
        items.contract_start_date = moment(item?.contract_start_date)?.format(localeConfiguration["dateFormat"]?.toUpperCase());
      } else {
        items.contract_start_date = "";
      }
      
      if (item.contract_sunset_date) {
        items.contract_sunset_date = moment(item.contract_sunset_date)?.format(localeConfiguration["dateFormat"]?.toUpperCase());
      } else {
        items.contract_sunset_date = "";
      }
      items.start_node = item?.start_node?.node ?? ''
      items.mode = item?.mode?.mode ?? ''
      items.mode_type = item?.mode?.mode_type ?? ''
      // items.item_code = item?.sku?.sku_code ?? ''
      items.sku_name = item?.sku?.sku_name ?? ''
      items.sku_code = item?.sku?.sku_code ?? ''

      items.shipping_type = item?.mode?.shipping_type ?? ''

      items.end_node = item?.end_node?.node_code ?? ''
       items.start_code = item?.start_node?.node_code ?? ''
      // items.item_code = item?.sku?.sku_code ?? ''
      items.sku_name = item?.sku?.sku_name ?? ''
      items.rm_code=item?.rm?.rm_code??''
      items.rm_description=item?.rm?.rm_description??''
      items.rm_name=item?.rm?.rm_name??''
      items.rm_type=item?.rm?.rm_type??''





     
      items.asset_capacity_unit = format(item?.asset_capacity_unit?.toFixed(2));
      items.order_cycle_time = format(item?.order_cycle_time?.toFixed(2));
      items.fixed_cost = format(item?.fixed_cost?.toFixed(2));
      // items.variable_cost = format(item?.variable_cost?.toFixed(2));
      items.contract_life_cycle_status = item?.contract_life_cycle_status ? t("active") : t("inactive");
      items.is_normal = item?.mode?.is_normal ?? ''
      items.channel_name = (item?.channel && item?.channel?.channel_name) ?? ''
      return items;
    });

  const removeColumn = ["network_id","rm",  "sku", "id", "sku_network_mapping_id", "channel","sku_name","is_normal"];



    
     
 



 
  





  const customColumnHeaders = () => {
    if (RMNetworkDetails && RMNetworkDetails.length > 0) {
      return [
        { replaceWith: "sku_code", newLabel: productConfiguration ? productConfiguration["base"] + " " + t("labelCode") : t("sku_code") },
        { replaceWith: "sku_name", newLabel: productConfiguration ? productConfiguration["base"] : t("sku_name") },
        { replaceWith: "start_node", newLabel: locationConfiguration ? t("labelStart") + " " + locationConfiguration["base"] : t("start_node") },
        { replaceWith: "end_node", newLabel: locationConfiguration ? t("labelEnd") + " " + locationConfiguration["base"] : t("end_node") },
      ];
    }
    else {
      return [];
    }
  }

  useEffect(() => {
    if (csvUploadError) {
      setVisible(false);
      toast.error(t(`${csvUploadError.message}`),
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      });
    }
  }, [csvUploadError]);

  useEffect(() => {
    if (csvUpload) {
      setVisible(false);
      if (csvUploadResponseData.responseCode === "FAILED_HEADER_VALIDATION") {
        toast.error(`${csvUploadResponseData.responseMessage}`,
        {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_ROW_FAILURE"
      ) {
        toast.warning(
          `${t(`${csvUploadResponseData.responseCode + '\n'}`)
          }`,

          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        )
        toast.warning(
          <a href={`${csvUploadResponseData.errorFileName}`} target="_blank"> Download Error File </a>,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        )
      } else if (
        csvUploadResponseData.responseCode === "SUCCESS_WITH_OUT_ROW_FAILURE"
      ) {
        toast.success("Data Uploaded Successfully !",
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else {
        toast.success(t(`${csvUploadResponseData.responseCode}`),
        {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      }
      refreshHandler();
    }

  }, [csvUpload]);
  const handleCSVUpload = () => {
    const fileSizeInMB = myFiles[0].size / (1024 * 1024);
    if(fileSizeInMB>40){
      return toast.warning("File size should be less than or equal to 40 MB",
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      })
    }
  //  if(myFiles[0].type!="text/csv" && myFiles[0].type!="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
  //   return toast.warning("Only CSV and EXCEL is supported")

  //  }
  if(myFiles[0].type!="text/csv" ){
    setVisible(!visible)

      return toast.error("Only CSV file is supported",
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      })

   }
    
    const uploadBody = {
      csvFile: myFiles[0],
      tenantId: tenantId,
    };
    dispatch({ type: UPLOAD_RMNETWORK_CSV_DATA, payload: uploadBody });
    dispatch({ type: "network/RMNetworkCSVUploadInitiate", payload: { csvUpload: false, loader: true } });
  };

  
  
  return (
    <>
      {loader && <ProcessingLoader />}
      <div>
        <section className="client-listing">
          <h3 className="section-heading">{t("RM Network")}</h3>
          <Breadcrumb list={[t("Entities Data"), t("RM Network")]} />
          <div className="client-listing-content">
            <div className="client-listing-toolbar">
              <TableToolbar
                showMoreOptions={showMoreOptions}
                setRowLength={setRowLength}
                setPageIndex={setPageIndex}
                searchField={searchField}
                setSearchField={setSearchField}
                handleChange={handleChange}
                toggleDialog={toggleDialog}
                handleSearchSubmit={handleSearchSubmit}
                refreshHandler={refreshHandler}
                downloadUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/csv?tenant_id=${tenantId}&bu_id=${buId}&sortFieldStringWithASCOrDESC=${sortValue}&file_type=csv&searchValue=${searchField}&${csvDownloadTableDataRows}`}
                dataList={RMNetworkDetails}
                downloadCsvApi={downloadCsvApi}
                downloadName={"rm-network.csv"}
                showAdvanceSearch={"rmnetwork"}

                advanceSearchTableColumn={RMNetworkStaticHeader}

                rowLength={rowLength}
                csvUploadPermission={addEditPermission}
                entityName={"network"}






       
                handleAdvanceSearch={handleAdvanceSearch}
                clearAdvanceSearch={clearAdvanceSearch}
          
    

              />
            </div>
            <div className="client-listing-grid">
              {" "}
              {
                RMNetworkDetails && RMNetworkStaticHeader &&
                RMNetworkDetails.length && RMNetworkStaticHeader.length?
                 <TableGrid
                dataRows={RMNetworkDetails && RMNetworkStaticHeader && RMNetworkDetails.length && RMNetworkStaticHeader.length ?RMNetworkDetails:[]  }
                dataColumn={
                    RMNetworkDetails && RMNetworkStaticHeader && RMNetworkDetails.length && RMNetworkStaticHeader.length ?RMNetworkStaticHeader:[] 
                }
                refresh={refresh}
                pageSize={pageSize}
                totalElement={totalElement}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setSortValue={setSortValue}
                isLoading={loader}
                replaceColumnLevels={customColumnHeaders()}
                componentName={"rmnetwork"}

              />:<div className="text-center">No records found</div>
              }
              
            </div>

          </div>
        </section>

        {visible && (
          <UploadDialog
            templateUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL}/template?tenant_id=${tenantId}&bu_id=${buId}`}
            toggleDialog={toggleDialog}
            handleCSVUpload={handleCSVUpload}
            myFiles={myFiles}
            listing={files}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            downloadCsvApi={downloadCsvApi}
            templateName={"rm-network-template.csv"}
            errorCSV={errorCSV}
            dateFormat={localeConfiguration["dateFormat"]}
          />
        )}
      </div>
    </>
  );
};

export default List;
