import { put, takeEvery } from "redux-saga/effects";
import { getAllPersonnelAPI, 
  uploadCSVDataPersonnelAPI,
  getEditPersonnelAPI,
  postAddPersonnelAPI,
  getHeaderAPI
 } from "../../../../apis";
import {
  getAllPersonnelSlice,
  personnelCSVUploadComplete,
  personnelCSVUploadError,
  getEditAllPersonnelSlice,
  postAddAllPersonnelSlice,
  personnelAddError,
  personnelEditError
} from "../../../slices/entities/personnel";
import { GET_ALL_PERSONNEL, UPLOAD_PERSONNEL_CSV_DATA,
  GET_ALL_EDIT_PERSONNEL,
  POST_ALL_ADD_PERSONNEL
 } from "../../../types";
import { toast } from "react-toastify";



export function* postAllPersonnelSaga(action) {
  try {
    yield put(postAddAllPersonnelSlice({
      data: {},
      loader: true
    }))
    const res = yield postAddPersonnelAPI(action.payload);
    yield put(postAddAllPersonnelSlice());
    yield put(postAddAllPersonnelSlice({
      data: res?.data ?? {},
      loader: false
    }))
    
  } catch (error) {
    yield put(postAddAllPersonnelSlice({
      data: {},
      loader: false
    }))
    yield put(personnelAddError(error.response?.data?.responseData?.message));

    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}
export function* getAllEditPersonnelSaga(action) {
  try {
    yield put(getEditAllPersonnelSlice({
      data: {},
      loader: true
    }))
    const res = yield getEditPersonnelAPI(action.payload);
    console.log("mysaga",res)
    yield put(getEditAllPersonnelSlice());
    yield put(getEditAllPersonnelSlice({
      
      data: res?.data ?? {},
      loader: false
    }))
    
  } catch (error) {
    yield put(getEditAllPersonnelSlice({
      data: {},
      loader: false
    }))
    yield put(personnelEditError(error.response?.data?.responseData?.message));

    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}


export function* getAllPersonnelSaga(action) {
  try {
    yield put(getAllPersonnelSlice({
      data: {},
      loader: true
    }))
      if(!sessionStorage.getItem('personnelStaticHeader')){
        const personnelStaticHeader = yield getHeaderAPI("personnel");
      yield sessionStorage.setItem('personnelStaticHeader', JSON.stringify(personnelStaticHeader.data.responseData));
      }
    const personnel = yield getAllPersonnelAPI(action.payload);

    
    yield put(getAllPersonnelSlice({
      data: personnel.data.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllPersonnelSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadPersonnelCSVSaga(action) {
  try {
    const res = yield uploadCSVDataPersonnelAPI(action.payload);
    yield put(personnelCSVUploadComplete(res.data.responseData));
  } catch (error) {
    yield put(personnelCSVUploadError(error.response.data.responseData));
  }
}

export function* watchPersonnelSaga() {
  yield takeEvery(GET_ALL_PERSONNEL, getAllPersonnelSaga);
  yield takeEvery(UPLOAD_PERSONNEL_CSV_DATA, uploadPersonnelCSVSaga);
  yield takeEvery(GET_ALL_EDIT_PERSONNEL, getAllEditPersonnelSaga);
  yield takeEvery(POST_ALL_ADD_PERSONNEL, postAllPersonnelSaga);
}
