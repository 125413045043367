import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_GOODS_IN_PNBS, UPLOAD_GOODS_IN_PNBS_CSV_DATA } from "../../../types";
import { getAllGoodsInPNBSAPI, getHeaderAPI, uploadGoodsInPNBSCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";
import { getAllGoodsInPNBESlice,goodsInPNBECSVUploadComplete,goodsInPNBECSVUploadError } from "../../../slices/transactional/goodsinpnbs";

export function* getAllGoodsInPNBSSaga(action) {
  try {
    yield put(getAllGoodsInPNBESlice({
      data: {},
      loader: true
    }))

    if(!sessionStorage.getItem('goodsInPBNSStaticHeader')){
      const response = yield getHeaderAPI("goodsinpbns");
    yield sessionStorage.setItem('goodsInPBNSStaticHeader', JSON.stringify(response.data.responseData));
    }
    const res = yield getAllGoodsInPNBSAPI(action.payload);
    yield put(getAllGoodsInPNBESlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllGoodsInPNBESlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadGoodsInPNBSCSVDataAPI(action.payload);
    yield put(goodsInPNBECSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(
        goodsInPNBECSVUploadError(error.response?.data ?? error?.message)
    );
  }
}
export function* watchGoodsInPNBSSaga() {
  yield takeEvery(GET_ALL_GOODS_IN_PNBS, getAllGoodsInPNBSSaga);
  yield takeEvery(UPLOAD_GOODS_IN_PNBS_CSV_DATA,uploadCSVSaga);
}
