import { put, takeEvery } from "redux-saga/effects";
import {
  getAllProductsAPI,
  uploadCSVDataAPI,getHeaderAPI,getEditProductHeaderAPI,postAddProductHeaderAPI
} from "../../../../apis";
import {
  getAllProductsSlice,
  productCSVUploadComplete,
  productCSVUploadError,
  getEditAllProductSlice,
  productAddError,
  postAddAllProductSlice
} from "../../../slices/entities/product";
import { GET_ALL_PRODUCTS, UPLOAD_PRODUCT_CSV_DATA,GET_ALL_EDIT_PRODUCT,POST_ALL_ADD_PRODUCT } from "../../../types";
import { toast } from "react-toastify";


export function* postAllProductSaga(action) {
  try {
    yield put(postAddAllProductSlice({
      data: {},
      loader: true
    }))
    const res = yield postAddProductHeaderAPI(action.payload);
    yield put(postAddAllProductSlice());
    yield put(postAddAllProductSlice({
      data: res?.data ?? {},
      loader: false
    }))
    
  } catch (error) {
    yield put(postAddAllProductSlice({
      data: {},
      loader: false
    }))
    yield put(productAddError(error.response?.data?.responseData?.message));

    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}
export function* getAllEditProductSaga(action) {
  try {
    yield put(getEditAllProductSlice({
      data: {},
      loader: true
    }))
    const res = yield getEditProductHeaderAPI(action.payload);
    console.log("mysaga",res)
    yield put(getEditAllProductSlice());
    yield put(getEditAllProductSlice({
      
      data: res?.data ?? {},
      loader: false
    }))
    
  } catch (error) {
    yield put(getEditAllProductSlice({
      data: {},
      loader: false
    }))
    yield put(productAddError(error.response?.data?.responseData?.message));

    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* getAllProductsSaga(action) {
  try {
    yield put(getAllProductsSlice({
      data: {},
      loader: true
    }))
    if(!sessionStorage.getItem('productStaticHeader')){
      const response = yield getHeaderAPI("product");
    yield sessionStorage.setItem('productStaticHeader', JSON.stringify(response.data.responseData));
    }
    
    const products = yield getAllProductsAPI(action.payload);
    yield put(getAllProductsSlice({
      data: products.data.responseData ?? {},
      loader: false
    }))
  } catch (error) {
    yield put(getAllProductsSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

export function* uploadProductCSVSaga(action) {
  try {
    const res = yield uploadCSVDataAPI(action.payload);
    yield put(productCSVUploadComplete(res.data.responseData));
  } catch (error) {
    yield put(productCSVUploadError(error.response.data.responseData));
  }
}

export function* watchProductSaga() {
  yield takeEvery(GET_ALL_PRODUCTS, getAllProductsSaga);
  yield takeEvery(UPLOAD_PRODUCT_CSV_DATA, uploadProductCSVSaga);
  yield takeEvery(GET_ALL_EDIT_PRODUCT, getAllEditProductSaga);
  yield takeEvery(POST_ALL_ADD_PRODUCT, postAllProductSaga);

}
