import React, { useState, useEffect } from "react";
import "../../../../style/sass/main.scss";
import ProductGrid from "./productGrid";
import Select from "react-select";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { TextField, InputAdornment } from "@mui/material";
import "@progress/kendo-theme-default/dist/all.css";
import { isEmpty, get } from "lodash";
import {filesize} from 'filesize'

import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_PRODUCTS,
  UPLOAD_PRODUCT_CSV_DATA,
} from "../../../../store/types";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { SHOW_ROWS } from "../../../../constant/constant";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { downloadCsvApi } from "../../../../apis";
import { csvFileReader } from "../../../../utils/csvFileReader";
import { Error } from "@progress/kendo-react-labels";
import { entitiesName, permission } from "../../../../utils/Constants";
import ProcessingLoader from '../../../../components/processingLoader/'
import { AiOutlineCloseCircle } from "react-icons/ai";
import Breadcrumb from "../../../../components/breadcrumb";
import TableToolbar from "../../../../components/tableGrid/toolbar";
import { use } from "i18next";

const List = (props) => {
  const { t } = useTranslation();
  const EDM_API_ENDPOINT = process.env.REACT_APP_EDM_API_ENDPOINT;
  const SCAI_API_BASEURL_PRODUCT =
    process.env.REACT_APP_SCAI_API_BASEURL_PRODUCT;

    const productConfiguration = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration")).entities
        .productHierarchy
    : {};
    const productCustomHeader = JSON.parse(localStorage.getItem("configuration"))
    ? JSON.parse(localStorage.getItem("configuration"))?.entities
        ?.productCustomHeader
    : {};
  const user = JSON.parse(localStorage.getItem("user"));
  const localeConfiguration = JSON.parse(localStorage.getItem("configuration")) ? JSON.parse(localStorage.getItem("configuration")).locale : {};
  const tenantId = get(user, "tenant_id", "");
  const buId = get(user, "business_unit_id", "");
  const permissions = get(user, "permissions", []);
  const addEditPermission = permissions.includes(permission.productAddEdit);
  const dispatch = useDispatch();
  const [rowLength, setRowLength] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [errorCSV, setErrorCSV] = useState("");

  const hierarchyName = "product";



  const isLoading = useSelector((state) => state.ProductReducer.loader);
  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const error = await csvFileReader(file);
      if (!error) {
        setMyFiles([file]);
        setErrorCSV("");
      } else {
        setErrorCSV(error);
        setMyFiles([]);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const files = myFiles.map((file) => (
    <li key={file.path} className="csv-upload-success-li">
      {file.path} - {file.size} bytes
    </li>
  ));
  useEffect(() => {
    if (visible === false) {
      setMyFiles([]);
      setErrorCSV('')
    }
  }, [visible]);

  const handleChange = (e) => {
    setSearchField(e.target.value);

  };

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `${t("Show")} ${item} ${t("Rows")}`,
    value: item,
  }));

  const toggleDialog = () => {
    dispatch({ type: "product/productCSVUploadInitiate", payload: { csvUpload: false, loader: false } });
    setVisible(!visible);
  };

  const body = {
    pageIndex: pageIndex,
    pageSize: rowLength,
    sortFieldStringWithASCOrDESC: sortValue,
    searchValue: searchField,
    productId: "0",
    tenantId: tenantId,
  };


  useEffect(() => {

    if (!refresh) {

      if (csvDownloadTableDataRows === "") {
        dispatch({ type: GET_ALL_PRODUCTS, payload: body });
      } else {
        body.advanceSearchValue = csvDownloadTableDataRows;
        body.buttonStatus = true;
        dispatch({ type: GET_ALL_PRODUCTS, payload: { ...body } });
      }
    }
  }, [dispatch, pageIndex, sortValue, rowLength]);

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    setPageIndex(1);
    dispatch({ type: GET_ALL_PRODUCTS, payload: { ...body, pageIndex: 1 } });
  };
  const [csvDownloadTableDataRows, setCSVDownloadTableDataRows]=useState("")

  const handleAdvanceSearch = (rows) => {  
     if(rows.length===1 && rows[0].columnName==="Select Value" && rows[0].operator==="Select Type"){

    }
    else{

    let result = '';
    rows.forEach((item, index) => {
        const keyIndex = index + 1;
        if(productStaticHeader.filter(data=>data.feName===item.columnName)[0].alias===null){
          result += `value${keyIndex}=${productStaticHeader.filter(data=>data.feName===item.columnName)[0].dbName},${item.operator.toLowerCase().replace(/\s+/g, '_')},${item.value}`;

        }else{
          result += `value${keyIndex}=${productStaticHeader.filter(data=>data.feName===item.columnName)[0].alias}.${productStaticHeader.filter(data=>data.feName===item.columnName)[0].dbName},${item.operator.toLowerCase().replace(/\s+/g, '_')},${item.value}`;

        }

        if (index !== rows.length - 1) {
            result += '&';
        }
    });
    setCSVDownloadTableDataRows(result)
    body.advanceSearchValue=result
    body.buttonStatus = true;
    setPageIndex(1);
    dispatch({ type: GET_ALL_PRODUCTS, payload: { ...body, pageIndex: 1 } });
    }   
  };

  const clearAdvanceSearch = () => {
    body.buttonStatus = false;
    setPageIndex(1);
    dispatch({ type: GET_ALL_PRODUCTS, payload: { ...body, pageIndex: 1 } });
  };

  const refreshHandler = () => {
    setRefresh(true);
    setPageIndex(1);
    setSearchField("");
    setSortValue("");
    setRowLength(10);

    const refreshBody = {
      ...body,
      searchValue: "",
      pageIndex: 1,
      pageSize: 10,
      sortFieldStringWithASCOrDESC: "",
    };
    setTimeout(() => {
      dispatch({ type: GET_ALL_PRODUCTS, payload: refreshBody });
      setRefresh(false);
    }, 300);
  };
  const products = useSelector((state) => state.ProductReducer.products);
  const productDetails = get(products, "entities", "");
  const totalElement = get(products, "totalElements", "");
  const pageSize = get(products, "pageSize", "");
  const csvUpload = useSelector((state) => state.ProductReducer.csvUpload);
  const csvUploadResponse = useSelector(
    (state) => state.ProductReducer.csvUploadResponseData
  );
  const csvUploadErrorResponse = useSelector(
    (state) => state.ProductReducer.csvUploadError
  );
  const [productStaticHeader,setProductStaticHeader]=useState([])
  useEffect(() => {
    const productStaticHeaderData = sessionStorage.getItem("productStaticHeader");
    if (productStaticHeaderData && productStaticHeaderData !== "undefined") {
      const parsedProductStaticHeader = JSON.parse(productStaticHeaderData);
      setProductStaticHeader(parsedProductStaticHeader);
    }
  }, [products]);
   
  console.log("mappingStaticHeaderrrrr",productStaticHeader)
  const productGridColumn=productStaticHeader && productStaticHeader.length && productStaticHeader.filter(col=>col.scope.includes('D'))
  console.log("mappingGridColumnnnn",productGridColumn)

  const productDataDetails =
  productDetails &&
  productDetails.map((item) => {
    const items = { ...item };
    items.channel_name =
      Object.keys(item?.channel ?? {}).length > 0
        ? item?.channel?.channel_name
        : "";
    
    items.channel_description =
      Object.keys(item?.channel ?? {}).length > 0
        ? item?.channel?.channel_description
        : "";
        // if (
        //   productCustomHeader &&
        //   Number(productCustomHeader?.productCustomHeaderCount) &&
        //   Number(productCustomHeader?.productCustomHeaderCount) > 0
        // ) {
        //   for (
        //     let index = 1;
        //     index <= productCustomHeader?.productCustomHeaderCount;
        //     index++
        //   ) {
        //     items[productCustomHeader[`header${index}`]] = item[`header${index}`]
        //     delete items[`header${index}`]
        //   }
        // }
    items.like_sku_code =
      Object.keys(item?.like_sku ?? {}).length > 0
        ? item?.like_sku?.like_sku_code != null ? item?.like_sku?.like_sku_code
        : "" : "";
    return items;
  });
  const csvUploadpp = useSelector((state) => state.ProductReducer);

  useEffect(() => {
    if (csvUploadErrorResponse) {
      setVisible(false);
      toast.error(t(`${csvUploadErrorResponse.message}`),
      {
        autoClose: false,
            closeOnClick: false,
            draggable: false,
      });
    }
  }, [csvUploadErrorResponse]);

  useEffect(() => {
    if (csvUpload) {
      setVisible(false);
      if (csvUploadResponse.responseCode === "FAILED_HEADER_VALIDATION") {
        toast.error(`${csvUploadResponse.responseMessage}`,
        {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
      } else if (csvUploadResponse.responseCode === "SUCCESS_WITH_ROW_FAILURE") {
        toast.warning(
          `${t(`${csvUploadResponse.responseCode
            + '\n'
            }`)
          }`,

          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        )
        toast.warning(
          <a href={`${csvUploadResponse.errorFileName}`} target="_blank"> Download Error File </a>,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        )
      } else if (csvUploadResponse.responseCode === "SUCCESS_WITH_OUT_ROW_FAILURE") {
        toast.success("Data Uploaded Successfully !",
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      } else {
        toast.success(
          t(`${csvUploadResponse.responseCode}`),
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      }
      refreshHandler();
    }
  }, [csvUpload]);

  const handleCSVUpload = () => {
    const fileSizeInMB = myFiles[0].size / (1024 * 1024);
    if(fileSizeInMB>40){
      return toast.warning("File size should be less than or equal to 40 MB",
      {
        autoClose: false,
            closeOnClick: false,
            draggable: false,
      })
    }
  if(myFiles[0].type!="text/csv" ){
    setVisible(!visible)

      return toast.error("Only CSV file is supported",
      {
        autoClose: false,
            closeOnClick: false,
            draggable: false,
      })

   }
    const uploadBody = {
      csvFile: myFiles[0],
      tenantId: tenantId,
    };
    dispatch({ type: UPLOAD_PRODUCT_CSV_DATA, payload: uploadBody });
    dispatch({ type: "product/productCSVUploadInitiate", payload: { csvUpload: false, loader: true } });
  };


  const removeColumn = ["id", "channel", "mapping_id",  "like_sku"];
  
  let productGrid =
    productDataDetails &&
    productDataDetails.length > 0 &&
    Object.keys(productDataDetails[0]).filter((item, i) => {
      return !removeColumn.includes(item);
    });
    // console.log("productGriddddd",productGrid)


    if (productGrid.length) {
      let elementToBeDeleted = ["created_at","created_by","modified_at","modified_by"];
      for (let i = 0; i < elementToBeDeleted.length; i++) {
        const indexToDelete = productGrid.indexOf(elementToBeDeleted[i]);
        if (indexToDelete !== -1) {
          productGrid.splice(indexToDelete, 1);
        }
      }
      for (let i = 0; i < elementToBeDeleted.length; i++) {
        productGrid.push(elementToBeDeleted[i]);
      }
    }

    // column rearranging
    let productHierarchyLevel = productGrid && productGrid.length &&productGrid.filter(key => key.startsWith("productHierarchyLevel"));
 
    // Remove filtered keys from original array
    productGrid = productGrid && productGrid.length && productGrid.filter(key => !key.startsWith("productHierarchyLevel"));
     
    // Insert filtered keys at the 3rd index of the array
    productGrid && productGrid.length &&productGrid.splice(2, 0, ...productHierarchyLevel);


    // column rearranging


// const productHierarchyLevel = productGrid && productGrid.length && productGrid.filter(item => item.startsWith("productHierarchyLevel"));
// const exceptProductHierarchyLevel =productGrid && productGrid.length && productGrid.filter(item => !item.startsWith("productHierarchyLevel"));

// productGrid = productHierarchyLevel.concat(exceptProductHierarchyLevel);

    



  








 



  return (
    <>
      {isLoading && <ProcessingLoader />}
      <div>
        <section className="client-listing">
          <h3 className="section-heading">{t("Product")}</h3>
          <Breadcrumb list={[t("Entities Data"), t("Product")]} />

          <div className="client-listing-content">
            <div className="client-listing-toolbar">
              <TableToolbar
                showMoreOptions={showMoreOptions}
                setRowLength={setRowLength}
                setPageIndex={setPageIndex}
                searchField={searchField}
                setSearchField={setSearchField}
                handleChange={handleChange}
                toggleDialog={toggleDialog}
                handleSearchSubmit={handleSearchSubmit}
                refreshHandler={refreshHandler}
                rowLength={rowLength}
                downloadUrl={`${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PRODUCT}/csv?tenant_id=${tenantId}&bu_id=${buId}&sortFieldStringWithASCOrDESC=${sortValue}&file_type=csv&searchValue=${searchField}&${csvDownloadTableDataRows}`}

                dataList={productDetails}
                downloadCsvApi={downloadCsvApi}
                downloadName={"product.csv"}
                csvUploadPermission={addEditPermission}
                entityName={"product"}
        
                componentName={"product"}
                componentNameAddEdit={"Product"}
                storedFeatureHeader={productStaticHeader}
                showAdvanceSearch={"product"}
             
                handleAdvanceSearch={handleAdvanceSearch}
                clearAdvanceSearch={clearAdvanceSearch}
                advanceSearchTableColumn={productStaticHeader}
                hierarchyName={hierarchyName}
           
                dataRows={productDetails}
              />
            </div>

            <div className="client-listing-grid">
              {" "}
              {
                productDataDetails && productDataDetails.length && productGridColumn && productGridColumn.length ?
                <ProductGrid
                productData={productDataDetails && productDataDetails.length && productGridColumn && productGridColumn.length ?productDataDetails: []}
                refresh={refresh}
                pageSize={pageSize}
                totalElement={totalElement}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setSortValue={setSortValue}
                isLoading={isLoading}
                componentName={"product"}
                productGrid={
                  // updateflag
                  //   ? (Array.isArray(productGrid) ? productGrid.filter((element) => !newallCheckboxId.includes(element)) : [])
                  //   : (Array.isArray(productGrid) ? productGrid : [])
                  productDataDetails && productDataDetails.length && productGridColumn && productGridColumn.length ?productGridColumn: []
                }
              />:<div className="text-center">No records found</div>
              }
              
            </div>

          </div>
        </section>

        {visible && (
          <Dialog id="window" className="csv_upload_popup">
            <span className="span1">{t("Upload")}</span>
            <AiOutlineCloseCircle
              onClick={toggleDialog}
              className="k-icon k-btn1"
            />
            <DialogActionsBar>
              <div className="border2">
                <div className="div2">
                  <section className="container">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input
                        {...getInputProps()}
                        accept={".csv, .xlsx"}
                        type="file"
                        name="file"
                      />
                      <h4 className="kh4">Select a CSV file to upload</h4>
                      <p className="kp mb-0">or drag or drop it here</p>
                      <p className="kp date_format_text mt-2"><b>Note:</b> Date format should be <b>{localeConfiguration["dateFormat"]}</b></p>
                      <p className="kp date_format_text mt-2">
                        <b>* -</b> denotes mandatory columns
                      </p>
                    </div>
                    <aside>{myFiles[0] ? <ul>{files}</ul> : ""}</aside>
                    {errorCSV && <Error className="dialog-error">{t(errorCSV)}</Error>}
                  </section>
                </div>
              </div>
            </DialogActionsBar>

            <DialogActionsBar>
              <div className="custom-k-dialog-buttongroup">
                <button
                  onClick={() => {
                    downloadCsvApi({
                      url: `${EDM_API_ENDPOINT}${SCAI_API_BASEURL_PRODUCT}/template?tenant_id=${tenantId}&bu_id=${buId}`,
                      fileName: "product-template.csv",
                    });
                  }}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base download-template-button"
                >
                  {t("Download Template")}
                  <span className="k-icon k-i-download"></span>
                </button>
                <button
                  className={`k-button k-button-md k-rounded-md k-button-solid k-button-solid-base upload-csv-button-dialog ${isEmpty(files) && "k-disabled"
                    } `}
                  onClick={handleCSVUpload}
                >
                  {t("Upload")}
                </button>
              </div>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default React.memo(List)
