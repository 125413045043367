import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_LOCATION,GET_ALL_EDIT_LOCATION,POST_ALL_ADD_LOCATION, UPLOAD_LOCATION_CSV_DATA } from "../../../types";
import { getAllLocationAPI,getEditLocationHeaderAPI,postAddLocationHeaderAPI, getHeaderAPI,uploadLocationCSVDataAPI } from "../../../../apis";
import { toast } from "react-toastify";
import { getAllLocationSlice,getEditAllLocationSlice,postAddAllLocationSlice, locationCSVUploadComplete, locationCSVUploadError, locationAddError, locationEditError } from '../../../slices/entities/location'



export function* postAllLocationSaga(action) {
  try {
    yield put(postAddAllLocationSlice({
      data: {},
      loader: true
    }))
    const res = yield postAddLocationHeaderAPI(action.payload);
    yield put(postAddAllLocationSlice());
    yield put(postAddAllLocationSlice({
      data: res?.data ?? {},
      loader: false
    }))
    
  } catch (error) {
    yield put(postAddAllLocationSlice({
      data: {},
      loader: false
    }))
    yield put(locationAddError(error.response?.data?.responseData?.message));

    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}
export function* getAllEditLocationSaga(action) {
  try {
    yield put(getEditAllLocationSlice({
      data: {},
      loader: true
    }))
    const res = yield getEditLocationHeaderAPI(action.payload);
    yield put(getEditAllLocationSlice());
    yield put(getEditAllLocationSlice({
      data: res?.data?? {},
      loader: false
    }))
    
  } catch (error) {
    yield put(getEditAllLocationSlice({
      data: {},
      loader: false
    }))
    yield put(locationEditError(error.response?.data?.responseData?.message));

    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
  }
}

function* getAllLocationSaga(action) {
  try {
    yield put(getAllLocationSlice({
      data: {},
      loader: true
    }))
    if(!sessionStorage.getItem('locationStaticHeader')){
      const response = yield getHeaderAPI("location");
    yield sessionStorage.setItem('locationStaticHeader', JSON.stringify(response.data.responseData));
    }
    const products = yield getAllLocationAPI(action.payload);
    yield put(getAllLocationSlice({
      data: products?.data?.responseData ?? {},
      loader: false
    }))
   
    // }else{
    //   console.log("trsting meri",localStorage.getItem("featureHeader"));
    // }
  } catch (error) {
    yield put(getAllLocationSlice({
      data: {},
      loader: false
    }))
    toast.error(error?.response?.data?.responseData?.message ?? error.message,
      {
        autoClose: false,
      closeOnClick: false,
      draggable: false,
      });
    }
  }



  export function* uploadLocationCSVSaga(action) {
    try {
      const res = yield uploadLocationCSVDataAPI(action.payload);
      yield put(locationCSVUploadComplete(res?.data?.responseData));
    } catch (error) {
      yield put(locationCSVUploadError(error.response?.data?.responseData));
    }
  }
  export function* watchLocationSaga() {
    yield takeEvery(GET_ALL_LOCATION, getAllLocationSaga);
    yield takeEvery(UPLOAD_LOCATION_CSV_DATA, uploadLocationCSVSaga);
    yield takeEvery(GET_ALL_EDIT_LOCATION, getAllEditLocationSaga);
    yield takeEvery(POST_ALL_ADD_LOCATION, postAllLocationSaga);

  }
