
import { all } from "redux-saga/effects";
import { watchPersonnelSaga } from "./entities/personnel";
import { watchProductSaga } from "./entities/product";
import { watchCurrencySaga } from "./entities/currency";
import { watchMappingSaga } from './entities/mapping'
import { watchNetworkSaga } from './entities/network'
import { watchLocationSaga } from './entities/location'
import { watchFeatureSaga } from './transactional/feature'
import { watchSalesHistorySaga } from './transactional/actualSalesHistory'
import { watchForecastSaga } from './transactional/forecast'
import { watchGoodsInTransitSaga } from "./transactional/goodsintransit";
import { watchGoodsInHandSaga } from "./transactional/goodsinhand";
import { watchGoodsInPNBSSaga } from "./transactional/goodsinpnbs";
import {watchProductionWorkOrderSaga} from "./transactional/productionWorkOrder"
import { watchpoEventsSaga } from "./transactional/poEvents";
import { watchpurchaseorderEventsSaga } from "./transactional/purchaseOrder";
import {watchBillOfMaterialsSaga} from "./transactional/billOfMaterials"
import { watchSyncStatusSaga } from "./syncstatus";
import {watchStockTransferOrderSaga} from "./transactional/stocktransferorder";
import { watchRMNodeMappingSaga } from "./transactional/rmNodeMapping";
import {watchShipmentSaga} from "./transactional/shipment"
import {watchInventoryEventsSaga} from "./transactional/inventoryEvents"
import {watchRMPOSaga} from "./transactional/rmpo"
import {watchSTOEventsSaga} from "./transactional/stoEvents" 
import {watchBudgetSaga} from "./entities/budget"
import {watchRowMaterialSaga} from "./entities/rawmaterial"
import {watchMarketingEventsSaga} from "./transactional/marketingevents"
import { watchPromotionTypeSaga } from "./transactional/promotiontype";
import { watchSplitMasteraga } from "./entities/splitmaster";
import {watchSalesOrdersSaga} from "./transactional/salesOrders"
import { watchPromoSaga } from "./transactional/promo";
import { watchPromoMasterSaga } from "./entities/promo";
import {watchRouteMasteraga} from "./entities/route-master"
import {watchSNPPersonnelSaga} from "./transactional/snp-personnel"
import {watchRMNetworkSaga} from "./entities/RMNetwork"


export function* watcherSaga() {
  yield all([
    watchProductSaga(),
    watchPersonnelSaga(),
    watchCurrencySaga(),
    watchRMNetworkSaga(),
    watchMappingSaga(),
    watchNetworkSaga(),
    watchLocationSaga(),
    watchFeatureSaga(),
    watchSalesHistorySaga(),
    watchForecastSaga(),
    watchSyncStatusSaga(),
    watchGoodsInTransitSaga(),
    watchGoodsInHandSaga(),
    watchGoodsInPNBSSaga(),
    watchProductionWorkOrderSaga(),
    watchpoEventsSaga(),
    watchBillOfMaterialsSaga(),
    watchStockTransferOrderSaga(),
    watchRMNodeMappingSaga(),
    watchShipmentSaga(),
    watchInventoryEventsSaga(),
    watchRMPOSaga(),
    watchpurchaseorderEventsSaga(),
    watchSTOEventsSaga(),
    watchBudgetSaga(),
    watchRowMaterialSaga(),
    watchMarketingEventsSaga(),
    watchPromotionTypeSaga(),
    watchSplitMasteraga(),
    watchSalesOrdersSaga(),
    watchPromoSaga(),
    watchPromoMasterSaga(),
    watchRouteMasteraga(),
    watchSNPPersonnelSaga()
  ]);
}
