import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  RMNetworkList: [],
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
};

export const RMNetworkSlice = createSlice({
  name: "RMNetwork",
  initialState: initialState,
  reducers: {
    getAllRMNetworkSlice: (state, action) => {
      state.RMNetworkList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
   
    RMNetworkCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    RMNetworkCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.csvUploadResponseData = action.payload;
      state.loader = false
      return state;
    },
    RMNetworkCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.loader = false
      return state;
    },
  },
});

export const {
  getAllRMNetworkSlice,
  RMNetworkCSVUploadInitiate,
  RMNetworkCSVUploadComplete,
  RMNetworkCSVUploadError,
} = RMNetworkSlice.actions;

export default RMNetworkSlice.reducer;
