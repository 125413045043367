import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  mappingList: {},
  csvUpload: false,
  csvUploadError: null,
  csvDownload: false,
  csvUploadResponseData: null,
  addEditTableColumnMapping:null,
  mappingAddError:null,
  mappingEditError:null
};

export const MappingSlice = createSlice({
  name: "mapping",
  initialState: initialState,
  reducers: {
    getAllMappingSlice: (state, action) => {
      state.mappingList = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    getEditAllMappingSlice: (state, action) => {
      
      state.addEditTableColumnMapping = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    postAddAllMappingSlice: (state, action) => {
      state.addEditTableColumnMapping = action?.payload?.data ?? {};
      state.loader = action?.payload?.loader ?? false;
      return state;
    },
    mappingCSVUploadInitiate: (state, action) => {
      state.csvUpload = action?.payload?.csvUpload ?? false;
      state.loader = action?.payload?.loader ?? false
      return state;
    },
    mappingCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.loader = false
      state.csvUploadResponseData = action.payload;
      return state;
    },
    mappingAddError: (state, action) => {
      state.mappingAddError = action.payload;
      state.loader = false
      return state;
    },
    mappingEditError: (state, action) => {
      state.mappingEditError = action.payload;
      state.loader = false
      return state;
    },
    mappingCSVUploadError: (state, action) => {
      state.loader = false
      state.csvUploadError = action.payload;
      return state;
    }
  },
});

export const {
  getAllMappingSlice,
  mappingEditError,
  mappingCSVUploadInitiate,
  mappingCSVUploadComplete,
  mappingAddError,
  mappingCSVUploadError,
  getEditAllMappingSlice,
  postAddAllMappingSlice
} = MappingSlice.actions;

export default MappingSlice.reducer;
