import { put, takeEvery } from "redux-saga/effects";
import { GET_ALL_NETWORKS, UPLOAD_NETWORK_CSV_DATA } from "../../../types";
import { getAllNetworksAPI, uploadNetworkCSVDataAPI, getHeaderAPI } from "../../../../apis";
import { toast } from "react-toastify";
import {
  getAllNetworkSlice,
  networkCSVUploadComplete,
  networkCSVUploadError,
} from "../../../slices/entities/network";

function* getAllNetworkSaga(action) {
  try {
    yield put(getAllNetworkSlice({
      data: {},
      loader: true
    }));
    if(!sessionStorage.getItem('networkStaticHeader')){
      const response = yield getHeaderAPI("network");
    yield sessionStorage.setItem('networkStaticHeader', JSON.stringify(response.data.responseData));
    }
    const res = yield getAllNetworksAPI(action.payload);
    yield put(getAllNetworkSlice({
      data: res?.data?.responseData ?? {},
      loader: false
    }));
  } catch (error) {
    yield put(getAllNetworkSlice({
      data: {},
      loader: false
    }));
    toast.error((error && error?.response?.data?.httpStatus) ?? error.message,
    {
      autoClose: false,
      closeOnClick: false,
      draggable: false,
    });
  }
}

export function* uploadCSVSaga(action) {
  try {
    const res = yield uploadNetworkCSVDataAPI(action.payload);
    yield put(networkCSVUploadComplete(res?.data?.responseData));
  } catch (error) {
    yield put(networkCSVUploadError(error.response?.data?.responseData));
  }
}
export function* watchNetworkSaga() {
  yield takeEvery(GET_ALL_NETWORKS, getAllNetworkSaga);
  yield takeEvery(UPLOAD_NETWORK_CSV_DATA, uploadCSVSaga);
}
